import React, { Fragment, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { useAddDiscountModal } from "./context/AddDiscountDialogueContext";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import AddContactIcon from "../partnerPage/icons/AddContactIcon";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { ReceiptIcon } from "./icons/ReceiptIcon";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import FormInput from "../../components/ui/FormContent/formInput";
import { postDiscount, postDiscountAR } from "../../services/financialService";
import MessageDialog from "../partnersList/dialog/messageDialog";
import ErrorDialog from "../../components/ui/dialog/errorDialog";
import { useTableDispatch } from "../../contexts/tableContext";
import {
  getBillingList,
  useBillingDispatch,
} from "../../contexts/billingApContext";
import { getBillingList as getBillingListAR } from "../../contexts/billingArContext";
import { useQueryClient } from "react-query";
import LoadingDialog from "../../components/ui/dialog/loadingDialog";

export function AddDiscountDialogue({
  handleTabChange,
  filterState,
  workTicketModal = false,
  dispatchBilling,
  isAR = false,
}) {
  const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleCloseSuccessDialog = async () => {
    toggleModal(false);
    setIsSuccessDialogOpen(false);
    const filterData = filterState;
    dispatchBilling({
      type: "SET_LOADING",
      billingListLoading: true,
    });
    if (isAR) {
      await getBillingListAR(dispatchBilling, filterData);
    } else {
      await getBillingList(dispatchBilling, filterData);
    }
  };
  const handleCloseSuccessDialogModal = async () => {
    toggleModal(false);
    setIsSuccessDialogOpen(false);
  };
  const handleCloseErrorDialog = () => setIsErrorDialogOpen(false);
  const classes = useStyles();
  const [discountAction, dispatchDiscountAction] = useAddDiscountModal();
  const { isOpenAddDialog, selected, discount, editing } = discountAction;
  let isEditing = editing;
  const toggleModal = (open) => {
    dispatchDiscountAction({ type: "TOGGLE_CONTACT_DIALOG", open });
  };
  const initialState = {
    discount_percentage: editing ? discount : "",
  };

  const queryClient = useQueryClient();
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  async function addDiscount() {
    //if disocunt is more than 100
    if (data.discount_percentage > 100) {
      setErrors((prevState) => [
        ...prevState,
        {
          key: "discount_percentage",
          message: "Discount percentage should not be more than 100",
        },
      ]);
      setLoading(false);
      return;
    }
    let dataSend;
    if (isAR) {
      dataSend = {
        ar_record_ids: selected,
        discount: data.discount_percentage,
      };
    } else {
      dataSend = {
        ap_record_ids: selected,
        discount: data.discount_percentage,
      };
    }

    try {
      if (isAR) {
        await postDiscountAR(dataSend);
      } else {
        await postDiscount(dataSend);
      }
      setLoading(false);
      setIsSuccessDialogOpen(true);
      await queryClient.invalidateQueries("workticket");
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.message);
      setIsErrorDialogOpen(true);
    }
  }
  const handleBlur = (event) => {
    if (event.name === "discount_percentage") {
      let value = event.value;
      value = value.toString();
      value = parseFloat(value).toFixed(1); // Convert to float and format to one decimal place
      event.value = value;
      setData({ ...data, [event.name]: value });
    }
  };
  return (
    <Fragment>
      {(!isErrorDialogOpen || !isSuccessDialogOpen) && (
        <Dialog open={isOpenAddDialog}>
          <DialogTitle
            className={classes.dialogTitleWrapper}
            id="form-dialog-title"
          >
            <Box className={classes.dialogTitleInnerWrapper}>
              <ReceiptIcon />
              <Typography className={classes.dialogTitle}>
                {isEditing ? "Edit" : "Add new"} Discount
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                toggleModal(false);
              }}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.addContactFormWrapper}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="discount_percentage"
              label="Discount Percentage*"
              value={data.discount_percentage}
              defaultValue={discount}
              handleBlur={handleBlur}
              type="number"
              error={errors}
              placeholder="Write a discount Percentage"
            />
          </DialogContent>
          <DialogActions className={classes.actionItemButton}>
            <Button
              className={classes.addDiscountCancelButton}
              disabled={loading}
              onClick={() => {
                toggleModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.secondAddDiscountButton}
              // disabled={isSubmitting}
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                await addDiscount();
                // toggleModal(false);
              }}
            >
              {/*{isEditing ? "Edit" : "Add"} Contact*/}
              {isEditing ? "Edit" : "Add"} Discount
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <MessageDialog
        open={isSuccessDialogOpen}
        title="Success!"
        message={
          "The discount has been successfully" +
          " " +
          (isEditing ? "edited" : "added") +
          "."
        }
        handleClose={
          workTicketModal
            ? handleCloseSuccessDialogModal
            : handleCloseSuccessDialog
        }
      />
      <ErrorDialog
        open={isErrorDialogOpen}
        title="Error!"
        message={errorMessage}
        handleClose={handleCloseErrorDialog}
      />
      <LoadingDialog open={loading} />
    </Fragment>
  );
}
