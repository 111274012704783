import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import ViewButton from "./rowViewButton";
import NotCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { compareArrayAddRemove } from "../../util/arrayUtil";
import { timeFormat24toAMPM } from "../../util/timeFormat";
import Tooltip from "@material-ui/core/Tooltip";
import { get } from "lodash";

import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";

const TBody = (props) => {
  const classes = useStyles();

  const dispatchTable = useTableDispatch();
  const {
    rows,
    columns,
    order,
    orderBy,
    selected,
    hasSelect,
    hideOption,
    errorOption,
    rowsUpdated,
    tableKpi,
  } = useTableState();

  function desc(a, b, orderBy) {
    const elementDeep = orderBy.split(".");
    let elemA;
    let elemB;
    if (elementDeep[1]) {
      elemA = a[elementDeep[0]] ? a[elementDeep[0]][elementDeep[1]] : null;
      elemB = b[elementDeep[0]] ? b[elementDeep[0]][elementDeep[1]] : null;
    } else {
      elemA = a[orderBy];
      elemB = b[orderBy];
    }

    if (isNaN(elemA)) {
      if (
        moment(elemA, "YYYY-MM-DD").isValid() ||
        moment(elemB, "YYYY-MM-DD").isValid()
      ) {
        if (elemA === null) return 1;
        if (elemB === null) return -1;
        elemA = moment(elemA, "YYYY-MM-DD").format("YYYYMMDD");
        elemB = moment(elemB, "YYYY-MM-DD").format("YYYYMMDD");
      }
      if (elemB < elemA) {
        return -1;
      }
      if (elemB > elemA) {
        return 1;
      }
    } else {
      elemA = Number(elemA);
      elemB = Number(elemB);
      if (elemB < elemA) {
        return -1;
      }
      if (elemB > elemA) {
        return 1;
      }
    }

    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isUpdated = (name) => rowsUpdated.indexOf(name) !== -1;

  const handleCheckboxClick = (event, name) => {
    event.stopPropagation();
    const newSelected = compareArrayAddRemove(selected, name);
    dispatchTable({ type: "SET_SELECTED", selected: newSelected });
  };

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const renderCell = (item, column) => {
    if (column.content && !column.format) return column.content(item);

    if (column.format === "date") {
      const dateField = get(item, column.id);
      return dateField && dateField !== "0000-00-00"
        ? moment(dateField).format("MM/DD/YYYY")
        : "-";
    }

    if (column.format === "id") {
      let idColumn = "";
      if (column.newId !== undefined && column.newId !== null) {
        idColumn = item[column.newId];
      } else {
        idColumn = item.id;
      }
      return (
        <Box className={classes.viewActionWrap}>
          <Box>{get(item, column.id)}</Box>
          {Boolean(column.path) && (
            <Link
              className={classes.viewAction}
              to={{
                pathname: `/${
                  column.url
                    ? column.url
                    : column.path
                    ? column.path
                    : "project"
                }/${idColumn}`,
              }}
            >
              <ViewButton />
            </Link>
          )}
        </Box>
      );
    }

    if (column.format === "action") {
      return (
        <Box className={classes.viewActionWrap}>
          <Box>{get(item, column.id)}</Box>
          <div className={classes.viewAction}>
            <ViewButton onClick={() => column.action(item)} />
          </div>
        </Box>
      );
    }

    if (column.format === "link") {
      return (
        <Link className={classes.linkFormat} to={column.path(item)}>
          {column.content ? column.content(item) : get(item, column.id)}
        </Link>
      );
    }

    if (column.format === "link_external") {
      return (
        <a
          className={classes.linkFormat}
          href={column.path(item)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {column.content ? column.content(item) : get(item, column.id)}
        </a>
      );
    }

    if (column.format === "time") {
      return (
        <Typography variant="body2" color="inherit" noWrap>
          {timeFormat24toAMPM(get(item, column.id))}
        </Typography>
      );
    }

    if (column.format === "main") {
      return (
        <Typography variant="body1" color="inherit" noWrap>
          {get(item, column.id)}
        </Typography>
      );
    }

    if (column.format === "number") {
      return (
        <Typography
          variant="body2"
          className={classes.money}
          color="inherit"
          noWrap
        >
          {column.nDecimal
            ? parseFloat(get(item, column.id)).toFixed(column.nDecimal)
            : get(item, column.id)}
        </Typography>
      );
    }

    if (column.format === "number_comma") {
      return (
        <Typography
          variant="body2"
          className={column.important ? classes.moneyImportant : classes.money}
          color="inherit"
          noWrap
        >
          {Number(get(item, column.id)).toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </Typography>
      );
    }

    if (column.format === "percentage") {
      return (
        <Typography
          variant="body2"
          className={classes.money}
          color="inherit"
          noWrap
        >
          {`${formatNumber(parseFloat(get(item, column.id)).toFixed(2))} %`}
        </Typography>
      );
    }

    if (column.format === "money") {
      const value =
        get(item, column.id) && get(item, column.id) !== ""
          ? `$ ${formatNumber(
              parseFloat(get(item, column.id)).toFixed(column.decimals ?? 2)
            )}`
          : "-";
      return (
        <Typography
          variant="body2"
          className={column.important ? classes.moneyImportant : classes.money}
          color="inherit"
          noWrap
        >
          {value}
        </Typography>
      );
    }

    if (column.format === "money_int") {
      const value =
        get(item, column.id) && get(item, column.id) !== ""
          ? `$ ${formatNumber(parseFloat(get(item, column.id)).toFixed(0))}`
          : "-";
      return (
        <Typography
          variant="body2"
          className={classes.money}
          color="inherit"
          noWrap
        >
          {value}
        </Typography>
      );
    }
    if (column.format === "normal") {
      return (
        <Typography variant="body2" color="inherit" noWrap>
          {get(item, column.id)}
        </Typography>
      );
    }
    return get(item, column.id);
  };

  const createKey = (item, column) => {
    return item.id + (column.id || column.key);
  };

  const clickRow = (event, id) => {
    if (props.handleRowClick) {
      props.handleRowClick(event, id);
    }
  };

  const dataRows = rows;
  if (dataRows && dataRows.length > 0) {
    return stableSort(
      hideOption ? dataRows.filter((row) => row[hideOption]) : dataRows,
      getSorting(order, orderBy)
    ).map((item, index) => {
      const isItemSelected = isSelected(item.id);
      const labelId = `enhanced-table-checkbox-${index}`;
      return (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={item.id}
          selected={isItemSelected}
          className={
            tableKpi && index % 2 === 0
              ? errorOption
                ? item[errorOption]
                  ? classes.tablerowError
                  : classes.tablerow
                : classes.tablerow
              : classes.tablerowWhite
          }
          onClick={(e) => clickRow(e, item.id)}
        >
          {hasSelect ? (
            <TableCell padding="checkbox" className={classes.checkCell}>
              {columns[0]?.checkbox && !columns[0].checkbox.active(item) ? (
                columns[0].checkbox.tooltip ? (
                  <Tooltip title={columns[0].checkbox.tooltip(item)}>
                    <NotCheckBoxIcon className={classes.tableNotCheckbox} />
                  </Tooltip>
                ) : (
                  <NotCheckBoxIcon className={classes.tableNotCheckbox} />
                )
              ) : (
                <Checkbox
                  disabled={props.hideHeaderCheckbox}
                  checked={isItemSelected}
                  icon={props.checkIcon}
                  inputProps={{ "aria-labelledby": labelId }}
                  onClick={(event) => handleCheckboxClick(event, item.id)}
                  className={classes.tableCheckbox}
                />
              )}
              {isUpdated(item.id) && (
                <Box className={classes.newRowIndicator}></Box>
              )}
            </TableCell>
          ) : null}
          {columns?.map((column, index) => {
            const columnStyle = column.style ? column.style : {};
            return (
              <TableCell
                className={classes.tablecell}
                // key={createKey(item, column)}
                key={index}
                style={
                  column.important && index % 2 === 0
                    ? { ...columnStyle, backgroundColor: "#EEEEEE" }
                    : column.important
                    ? { ...columnStyle, backgroundColor: "#F8F8F8" }
                    : columnStyle
                }
              >
                {renderCell(
                  { ...item, itemIndexOnTableInternalUse: index + 1 },
                  column
                )}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  } else {
    return (
      <TableRow>
        <TableCell colSpan={columns.length + 1}>No Records Found</TableCell>
      </TableRow>
    );
  }
};

export default TBody;
