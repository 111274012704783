import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import useStyles from "components/common/Filters/styles";

import { getJobs } from "services/jobService";

import { useFilterState, useFilterDispatch } from "contexts/filterContext";

const AccountPayablesSubWorkFilters = ({ showDiscountFilter, filterCount }) => {
  const classes = useStyles();
  const [jobFilters, setJobFilters] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [jobs, setJobs] = React.useState([]);
  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const loadJob = async () => {
      try {
        const resultJob = await getJobs();
        const resultForRender = resultJob.data.data.jobs.map((job) => {
          return {
            id: job.id,
            job_description: job.job_description,
            job_number: job.job_number,
          };
        });
        setJobs(resultForRender);
      } catch (e) {
        console.log("Cannot load jobs data");
      }
    };
    if (anchorEl && !jobs.length) {
      loadJob();
    }
  }, [anchorEl, jobs]);

  useEffect(() => {
    if (anchorEl) {
      filters.forEach((filter) => {
        if (filter?.filter === "discount_applied") {
          if (filter?.values[0]?.value === 1) {
            setApDiscountApplied(true);
            setToggleButtonGroupValue(1);
          }
          if (filter?.values[0]?.value === 0) {
            setToggleButtonGroupValue(0);
          }
        }
      });
      if (jobs.length) {
        const selectedJobFilter = filters.find(
          (filter) => filter.filter === "job"
        );
        if (selectedJobFilter) {
          const selectedValue = selectedJobFilter.values.map(
            (job) => job.value
          );
          const findJob = jobs.filter((job) =>
            selectedValue.includes(job.job_number)
          );
          setJobFilters(findJob);
        } else {
          setJobFilters([]);
        }
      }
    }
  }, [anchorEl, filters, jobs]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];

    if (jobFilters.length) {
      // Map label and value with job data
      const jobFilterConvert = jobFilters.map((job) => {
        return {
          label: `${job.job_number} - ${job.job_description}`,
          value: job.job_number,
        };
      });
      filterData.push({
        filter: "job",
        group: "Job",
        values: [...jobFilterConvert],
      });
    }
    if (ap_discount_applied) {
      filterData.push({
        filter: "discount_applied",
        group: "Discounts Applied",
        values: [{ label: "Yes", value: 1 }],
      });
      dispatchFilters({
        type: "SET_AP_DISCOUNT_APPLIED",
        ap_discount_applied: 1,
      });
    } else {
      if (ap_discount_applied === 0) {
        console.log("got here", ap_discount_applied);
        filterData.push({
          filter: "discount_applied",
          group: "Discounts Applied",
          values: [{ label: "No", value: 0 }],
        });
        dispatchFilters({
          type: "SET_AP_DISCOUNT_APPLIED",
          ap_discount_applied: 0,
        });
      } else if (ap_discount_applied === null) {
        filterData.forEach((filter) => {
          if (filter.filter === "discount_applied") {
            filter.values = [];
            filter.filter = null;
            filter.group = null;
          }
        });
        dispatchFilters({
          type: "SET_AP_DISCOUNT_APPLIED",
          ap_discount_applied: null,
        });
      }
    }
    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });
    localStorage.removeItem(`filter_${window.location.pathname.split("/")[1]}`);
    localStorage.setItem(
      `filter_${window.location.pathname.split("/")[1]}`,
      JSON.stringify(filterData)
    );
    handleClose();
  };

  const handleChangeJob = (event, value) => {
    setJobFilters(value);
  };

  const handleClearFilter = () => {
    setJobFilters([]);
  };

  const [ap_discount_applied, setApDiscountApplied] = useState(null);
  const [toggleButtonGroupValue, setToggleButtonGroupValue] = useState(null);
  const handleButtonClick = (value) => {
    if (value === toggleButtonGroupValue) {
      setToggleButtonGroupValue(null);
      setApDiscountApplied(null);
    } else {
      setApDiscountApplied(value);
      setToggleButtonGroupValue(value);
    }
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.filterBtnAP}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter
        <span className={classes.badgeFilters}>{filterCount || 0}</span>
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Box>
          <Box className={classes.APfilterHeader}>
            <Typography variant="h5" className={classes.headerTitle}>
              Filters
            </Typography>
            <Box>
              <Button onClick={handleCancel} className={classes.buttonCancel}>
                Cancel
              </Button>
              <Button
                onClick={handleClearFilter}
                className={classes.buttonCancel}
              >
                Clear
              </Button>
              <Button onClick={handleApply} className={classes.buttonApply}>
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
        <Paper className={classes.filterPopperContainer}>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Job
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} className={classes.autoContainerFilter}>
              <FormSelectJobsAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={jobs}
                name="job_number"
                internal={true}
                handleChange={handleChangeJob}
                multiple={true}
                value={jobFilters}
              />
            </Grid>
          </Grid>
          {showDiscountFilter && (
            <>
              <Typography className={classes.titleText}>
                Discount Applied
              </Typography>
              <Box className={classes.filterButtonContainer}>
                <Button
                  onClick={() => handleButtonClick(0)}
                  className={`${classes.toggleButton} ${
                    toggleButtonGroupValue === 0
                      ? classes.activeButton
                      : classes.inactiveButton
                  }`}
                >
                  No
                </Button>
                <Button
                  onClick={() => handleButtonClick(1)}
                  className={`${classes.toggleButton} ${
                    toggleButtonGroupValue === 1
                      ? classes.activeButton
                      : classes.inactiveButton
                  }`}
                >
                  Yes
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Popover>
    </Box>
  );
};

export default AccountPayablesSubWorkFilters;
