import React, { useState, useEffect, useMemo } from "react";
import * as classNames from "classnames";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DeleteIcon from "assets/icons/deleteIcon";
import FormInput from "components/ui/FormContent/formInput";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TicketIcon from "@material-ui/icons/Receipt";
import {
  getRatesCorrigo,
  submitQuoteCorrigo,
  getQuoteCorrigo,
} from "services/workticketService";
import { logException } from "components/util/logUtil";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "./styles";

const initialRow = {
  rateType: null,
  description: "",
  unitCost: 0,
  quantity: 0,
  total: 0,
  error: [],
  selectedItem: null,
  isLocked: false,
  rateTypes: [],
};

const WorkticketQuoteCorrigo = (props) => {
  const classes = useStyles();
  const {
    workticketNumber,
    workticketId,
    woNumber,
    woStatus,
    dne,
    handleClose,
    open,
    handleUpdate,
  } = props;
  const [isLoadingProcess, setIsLoadingProcess] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [rows, setRows] = useState([{ ...initialRow }]);
  const [isFistLoad, setIsFistLoad] = useState(true);
  const [allRates, setAllRates] = useState([]);
  const [description, setDescription] = useState("");
  const [totalDollars, setTotalDollars] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [submitDate, setSubmitDate] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [quoteId, setQuoteId] = useState(null);

  const disabledSubmit = useMemo(
    () =>
      !rows.length ||
      quoteId ||
      parseFloat(parseFloat(totalDollars).toFixed(2)) -
        parseFloat(parseFloat(discount).toFixed(2)) <=
        0
        ? true
        : false,
    [rows, totalDollars, discount, quoteId]
  );

  useEffect(() => {
    const loadQuote = async () => {
      setIsLoadingProcess(true);
      const [resultRates, resultQuote] = await Promise.all([
        getRatesCorrigo(workticketId),
        getQuoteCorrigo(workticketId),
      ]);
      const dataRates = resultRates.data.data.PriceList;
      const dataQuote = resultQuote.data.data;
      const dataRateTypes = Object.values(
        dataRates.reduce((acc, { Type, Name }) => {
          const key = `${Type}-${Name}`;
          if (!acc[key]) {
            acc[key] = { value: Type, label: Name };
          }
          return acc;
        }, {})
      );
      setAllRates(dataRates);
      if (dataQuote) {
        setSubmitDate(dataQuote.submitted_date);
        setQuoteId(dataQuote.id);
        const quoteDefinition = JSON.parse(dataQuote.data);
        setDescription(quoteDefinition.comment);
        setDiscount(parseFloat(quoteDefinition.discount).toFixed(2));
        const dataLineItems = quoteDefinition.quoteLineItems;
        const dataRows = dataLineItems.map((item) => {
          const selItem = dataRates.find((i) => i.RateType === item.Category);
          return {
            rateType: item.Category,
            description: item.Description,
            unitCost: parseFloat(item.Rate).toFixed(2),
            quantity: parseFloat(item.Quantity).toFixed(2),
            total: parseFloat(item.Rate * item.Quantity).toFixed(2),
            error: [],
            selectedItem: selItem,
            isLocked: selItem?.IsRateReadOnly,
            rateTypes: dataRateTypes,
          };
        });
        setRows(dataRows);
        const sumDollars = dataRows.reduce(
          (partialSum, a) => partialSum + parseFloat(a.total),
          0
        );
        setTotalDollars(sumDollars);
      } else {
        rows[0].rateTypes = dataRateTypes;
        setQuoteId(null);
        setRows([...rows]);
      }
      setIsLoadingProcess(false);
    };
    if (workticketId && rows.length === 1 && isFistLoad && open) {
      loadQuote();
      setIsFistLoad(false);
    }
  }, [workticketId, rows, isFistLoad, open]);

  useEffect(() => {
    if (!open) {
      setRows([{ ...initialRow }]);
      setTotalDollars(0);
      setDiscount(0);
      setDescription("");
      setIsFistLoad(true);
    }
  }, [open]);

  const handleChangeRateType = (n, value) => {
    if (value) {
      const selItem = allRates.find((i) => i.Type === value.value);
      rows[n].rateType = value.value;
      rows[n].unitCost = parseFloat(selItem.Rate).toFixed(2);
      rows[n].isLocked = selItem.IsRateReadOnly;
      rows[n].total = rows[n].unitCost * rows[n].quantity;
    } else {
      rows[n].rateType = null;
      rows[n].selectedItem = null;
      rows[n].unitCost = 0;
      rows[n].quantity = 0;
      rows[n].total = 0;
    }
    rows[n].error = [];
    const sumDollars = rows.reduce(
      (partialSum, a) => partialSum + parseFloat(a.total),
      0
    );
    setTotalDollars(sumDollars);
    setRows([...rows]);
  };

  const handleSendQuote = async () => {
    let isGood = true;
    for (let i = 0; i < rows.length; i++) {
      const error = [];
      if (!rows[i].rateType) {
        error.push({
          key: "rateType",
          message: "Required",
        });
        isGood = false;
      }
      if (!rows[i].description) {
        error.push({
          key: "description",
          message: "Required",
        });
        isGood = false;
      }
      if (!rows[i].unitCost) {
        error.push({
          key: "unitCost",
          message: "Required",
        });
        isGood = false;
      }
      if (!rows[i].quantity) {
        error.push({
          key: "quantity",
          message: "Required",
        });
        isGood = false;
      }
      rows[i].error = error;
    }
    if (!isGood) {
      setRows([...rows]);
      return;
    }
    try {
      let data = {};
      setIsLoadingProcess(true);
      data = {
        amount: parseFloat(totalDollars).toFixed(2),
        discount: parseFloat(discount).toFixed(2),
        comment: description,
      };
      const quoteLineItems = [];
      for (let i = 0; i < rows.length; i++) {
        quoteLineItems.push({
          Category: rows[i].rateType,
          Description: rows[i].description,
          Quantity: parseFloat(parseFloat(rows[i].quantity).toFixed(2)),
          Rate: parseFloat(parseFloat(rows[i].unitCost).toFixed(2)),
        });
      }
      data.quoteLineItems = quoteLineItems;
      await submitQuoteCorrigo(workticketId, {
        quoteData: data,
      });
      setIsLoadingProcess(false);
      handleClose();
      handleUpdate();
      setSuccessMessage("Quote has been submitted");
      setOpenSuccess(true);
    } catch (e) {
      logException(e, "Cannot submit quote");
    }
  };

  const delay = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const handleCloseSuccess = async () => {
    setOpenSuccess(false);
  };

  const handleBlur = async (event) => {
    if (event.name === "general_description") {
      setDescription(event.value);
    } else if (event.name === "discount" && !isNaN(event.value)) {
      setDiscount(event.value);
    } else {
      setDiscount(event.value);
      await delay(50);
      setDiscount(0);
    }
  };

  const handleRowBlur = (n, event) => {
    if (["unitCost", "quantity"].includes(event.name) && !isNaN(event.value)) {
      rows[n][event.name] = event.value;
      rows[n].total = rows[n].unitCost * rows[n].quantity;
      rows[n].error = [];
      const sumDollars = rows.reduce(
        (partialSum, a) => partialSum + parseFloat(a.total),
        0
      );
      setTotalDollars(sumDollars);
    } else if (event.name === "description") {
      if (!event.value) {
        rows[n].error = [
          {
            key: event.name,
            message: "Required",
          },
        ];
      } else {
        rows[n][event.name] = event.value;
        rows[n].error = [];
      }
    } else {
      rows[n][event.name] = 0;
      rows[n].error = [
        {
          key: event.name,
          message: "Required",
        },
      ];
      rows[n].total = 0;
      const sumDollars = rows.reduce(
        (partialSum, a) => partialSum + parseFloat(a.total),
        0
      );
      setTotalDollars(sumDollars);
    }
    setRows([...rows]);
  };

  const handleAddClick = () => {
    const newRow = { ...initialRow };
    newRow.rateTypes = [...rows[0].rateTypes];
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (n) => {
    rows.splice(n, 1);
    const sumDollars = rows.reduce(
      (partialSum, a) => partialSum + parseFloat(a.total),
      0
    );
    setTotalDollars(sumDollars);
    setRows([...rows]);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {isLoadingProcess ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent>
              <IconButton
                aria-label="close"
                onClick={props.handleClose}
                className={classes.wrapperClose}
              >
                <CloseIcon className={classes.iconClose} />
              </IconButton>
              {!isLoadingProcess && (
                <>
                  <Grid container spacing={2} className={classes.dialogHeader}>
                    <Grid item sm={12}>
                      <Box className={classes.formHeaderContainer}>
                        <Box className={classes.formIconDialog}>
                          <TicketIcon className={classes.iconDialog} />
                        </Box>
                        <Typography
                          variant="h4"
                          className={classes.formTitleDialogInvoice}
                          gutterBottom
                        >
                          {workticketNumber}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={classes.formContainerDialogMultiple}>
                    <Grid container spacing={0}>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            WO Number:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {woNumber}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            WO Status:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {woStatus}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            Quote Status:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {submitDate ? "Submitted" : "Pending"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            Submitted Date:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {submitDate
                              ? moment(submitDate).format("MM/DD/YYYY")
                              : moment().format("MM/DD/YYYY")}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            DNE:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {`$${parseFloat(dne).toFixed(2)} USD`}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={12} sm={12}>
                        <Box className={classes.descriptionFieldContainer}>
                          <FormInput
                            name={"general_description"}
                            gridSizes={[{ size: "md", val: 12 }]}
                            label="Description of work performed*"
                            value={description}
                            sameLine={true}
                            handleBlur={handleBlur}
                            rows={3}
                            multiline
                            noMargin
                          />
                        </Box>
                      </Grid>
                      {rows.map((row, n) => (
                        <React.Fragment key={n}>
                          <Grid item md={5} sm={6}>
                            <FormSelectAuto
                              gridSizes={[{ size: "md", val: 12 }]}
                              options={row.rateTypes}
                              name="rateType"
                              label="Item"
                              handleChange={(event, value) =>
                                handleChangeRateType(n, value)
                              }
                              value={row.rateTypes.find(
                                (item) => item.value === row.rateType
                              )}
                              error={row.error}
                              noMargin
                            />
                          </Grid>
                          <Grid item md={7} sm={6}>
                            <FormInput
                              name={"description"}
                              gridSizes={[{ size: "md", val: 12 }]}
                              label="Description"
                              value={row.description}
                              sameLine={true}
                              handleBlur={(event) => handleRowBlur(n, event)}
                              error={row.error}
                              noMargin
                            />
                          </Grid>
                          <Grid item md={3} sm={6}>
                            <FormInput
                              name={"unitCost"}
                              gridSizes={[{ size: "md", val: 12 }]}
                              label="Rate"
                              value={row.unitCost}
                              readonly={row.isLocked}
                              sameLine={true}
                              handleBlur={(event) => handleRowBlur(n, event)}
                              error={row.error}
                              noMargin
                            />
                          </Grid>
                          <Grid item md={3} sm={6}>
                            <FormInput
                              name={"quantity"}
                              gridSizes={[{ size: "md", val: 12 }]}
                              label="Qty"
                              value={row.quantity}
                              sameLine={true}
                              handleBlur={(event) => handleRowBlur(n, event)}
                              error={row.error}
                              noMargin
                            />
                          </Grid>
                          <Grid item md={5} sm={6}>
                            <FormInput
                              name={"total"}
                              gridSizes={[{ size: "md", val: 12 }]}
                              label="Total"
                              value={row.total}
                              sameLine={true}
                              readonly={true}
                              noMargin
                            />
                          </Grid>
                          <Grid item md={1} sm={6}>
                            <Box className={classes.iconContainer}>
                              <IconButton
                                aria-label="delete"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={() => handleDeleteRow(n)}
                                disabled={rows.length === 1}
                                className={classes.iconButtonAction}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                    <Box
                      className={classes.addContainerInvoice}
                      onClick={handleAddClick}
                    >
                      <AddIcon className={classes.iconAddLineInvoice} /> Add
                      Item
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item md={6} sm={6}></Grid>
                      <Grid item md={3} sm={6}>
                        <Box className={classes.fieldComposedInvoice}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice2}
                            gutterBottom
                          >
                            Discount
                          </Typography>
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="discount"
                            withoutLabel
                            value={discount}
                            handleBlur={handleBlur}
                            noMargin
                          />
                        </Box>
                      </Grid>
                      <Grid item md={3} sm={6}>
                        <Box className={classes.resumeInvoiceFieldsContainer}>
                          <Box className={classes.headerFieldInvoiceContainer}>
                            <Typography
                              variant="h4"
                              className={classes.labelHeaderInvoice}
                              gutterBottom
                            >
                              Subtotal:
                            </Typography>
                            <Typography
                              variant="h4"
                              className={classes.labelHeaderValueInvoice2}
                              gutterBottom
                            >
                              {parseFloat(totalDollars).toFixed(2)}
                            </Typography>
                          </Box>
                          <Box className={classes.headerFieldInvoiceContainer}>
                            <Typography
                              variant="h4"
                              className={classes.labelHeaderInvoiceResume2}
                            >
                              Total Cost:
                            </Typography>
                            <Typography
                              variant="h4"
                              className={classes.labelHeaderInvoiceResume}
                            >
                              {parseFloat(
                                parseFloat(
                                  parseFloat(totalDollars).toFixed(2)
                                ) - parseFloat(parseFloat(discount).toFixed(2))
                              ).toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </DialogContent>
            <DialogActions className={classes.actionsDialogWrapperInvoice}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                className={classNames(classes.button, classes.buttonOutlined)}
                onClick={props.handleClose}
              >
                Cancel
              </Button>
              <Button
                variant={disabledSubmit ? "outlined" : "contained"}
                color="primary"
                size="large"
                disableElevation
                className={
                  disabledSubmit
                    ? classNames(classes.button, classes.buttonOutlined)
                    : classNames(classes.button, classes.buttonPrimary)
                }
                onClick={() => handleSendQuote(true)}
                disabled={disabledSubmit}
              >
                Submit Quote
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title="Success"
        message={successMessage}
      />
    </>
  );
};

export default WorkticketQuoteCorrigo;
