import React, { useState, useEffect, useContext, useMemo } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import { uniq } from "lodash";
import useStyles from "components/common/Filters/styles";

import {
  getFilterJobs,
  getFilterCustomers,
  getFilterProjects,
} from "components/util/filterUtil";
import { logException } from "components/util/logUtil";

import GlobalUiContext from "contexts/globalUiContext";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";
import { permissionProject, hasPermission } from "lib/permissions";

const WorkticketFiltersPreventive = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { users, permissions } = globalUi;
  const [jobFilters, setJobFilters] = useState([]);
  const [customerFilters, setCustomerFilters] = useState([]);
  const [projectFilters, setProjectFilters] = useState([]);
  const [assignedFilters, setAssignedFilters] = useState([]);
  const [managedFilters, setManagedFilters] = useState([]);
  const [servicesFilters, setServicesFilters] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [services, setServices] = useState([]);
  const [customers, setCustomers] = useState(null);
  const [projects, setProjects] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { preventiveProject, removeJobs, removeCustomers, removeProjects } =
    props;

  const usersList = useMemo(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    return [{ id: user.id, first_name: "Me", last_name: "" }, ...users];
  }, [users]);

  useEffect(() => {
    const loadJob = async () => {
      try {
        if (preventiveProject?.preventative_services.length) {
          const servicesList = preventiveProject?.preventative_services.map(
            (service) => service.name
          );
          const uniqueServiceList = uniq(servicesList);
          const renderService = uniqueServiceList.map((service) => {
            return { value: service, label: service };
          });

          setServices(renderService);
        }
        if (preventiveProject?.preventative_jobs.length) {
          setJobs(preventiveProject.preventative_jobs);
        } else {
          const resultForRender = await getFilterJobs();
          setJobs(resultForRender);
        }
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (anchorEl && !jobs.length && !removeJobs) {
      loadJob();
    }
  }, [anchorEl, jobs, preventiveProject, removeJobs]);

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const resultForRender = await getFilterCustomers();
        setCustomers(resultForRender);
      } catch (e) {
        logException(e, "Cannot load customer data");
      }
    };
    if (anchorEl && !customers && !removeCustomers) {
      loadCustomer();
    }
  }, [anchorEl, customers, removeCustomers]);

  useEffect(() => {
    const loadProject = async () => {
      try {
        const resultForRender = await getFilterProjects();
        setProjects(resultForRender);
      } catch (e) {
        logException(e, "Cannot load project data");
      }
    };
    if (anchorEl && !removeProjects) {
      loadProject();
    }
  }, [anchorEl, removeProjects]);

  useEffect(() => {
    if (anchorEl) {
      if (jobs.length) {
        const selectedJobFilter = filters.find(
          (filter) => filter.filter === "job"
        );
        if (selectedJobFilter) {
          const selectedValue = selectedJobFilter.values.map(
            (job) => job.value
          );
          const findJob = jobs.filter((job) => selectedValue.includes(job.id));
          setJobFilters(findJob);
        } else {
          setJobFilters([]);
        }
      }
      if (projects?.length) {
        const selectedProjectsFilter = filters.find(
          (filter) => filter.filter === "project"
        );
        if (selectedProjectsFilter) {
          setProjectFilters(selectedProjectsFilter.values);
        } else {
          setProjectFilters([]);
        }
      }
      if (customers?.length) {
        const selectedCustomersFilter = filters.find(
          (filter) => filter.filter === "customer"
        );
        if (selectedCustomersFilter) {
          setCustomerFilters(selectedCustomersFilter.values);
        } else {
          setCustomerFilters([]);
        }
      }

      if (services?.length) {
        const selectedServicesFilter = filters.find(
          (filter) => filter.filter === "service"
        );
        if (selectedServicesFilter) {
          setServicesFilters(selectedServicesFilter.values);
        } else {
          setServicesFilters([]);
        }
      }

      if (users.length) {
        const selectedManagedFilter = filters.find(
          (filter) => filter.filter === "managed"
        );
        if (selectedManagedFilter) {
          const selectedValue = selectedManagedFilter.values.map(
            (managed) => managed.value
          );
          const findUsers = users.filter((user) =>
            selectedValue.includes(user.id)
          );
          setManagedFilters(findUsers);
        } else {
          setManagedFilters([]);
        }
        const selectedAssignedFilter = filters.find(
          (filter) => filter.filter === "assigned"
        );
        if (selectedAssignedFilter) {
          const selectedValue = selectedAssignedFilter.values.map(
            (assigned) => assigned.value
          );
          const findUsers = users.filter((user) =>
            selectedValue.includes(user.id)
          );
          setAssignedFilters(findUsers);
        } else {
          setAssignedFilters([]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl, filters, jobs, customers, projects, users]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];

    if (customerFilters.length) {
      filterData.push({
        filter: "customer",
        group: "Customer",
        values: [...customerFilters],
      });
    }

    if (servicesFilters.length) {
      filterData.push({
        filter: "service",
        group: "Service",
        values: [...servicesFilters],
      });
    }

    if (projectFilters.length) {
      filterData.push({
        filter: "project",
        group: "Project",
        values: [...projectFilters],
      });
    }

    if (managedFilters.length) {
      const managedFilterConvert = managedFilters.map((managed) => {
        return {
          label: `${managed.first_name} ${managed.last_name}`,
          value: managed.id,
        };
      });
      filterData.push({
        filter: "managed",
        group: "Managed By",
        values: [...managedFilterConvert],
      });
    }

    if (assignedFilters.length) {
      const assignedFilterConvert = assignedFilters.map((assigned) => {
        return {
          label: `${assigned.first_name} ${assigned.last_name}`,
          value: assigned.id,
        };
      });
      filterData.push({
        filter: "assigned",
        group: "Assigned To",
        values: [...assignedFilterConvert],
      });
    }

    if (jobFilters.length) {
      const jobFilterConvert = jobFilters.map((job) => {
        return {
          label: `${job.job_number} - ${job.job_description}`,
          value: job.id,
        };
      });
      filterData.push({
        filter: "job",
        group: "Job",
        values: [...jobFilterConvert],
      });
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });

    localStorage.setItem(
      `filter_${window.location.pathname.split("/")[1]}`,
      JSON.stringify(filterData)
    );
    handleClose();
  };

  const handleChangeJob = (event, value) => {
    setJobFilters(value);
  };

  const handleChangeCustomer = (event, value) => {
    setCustomerFilters(value);
  };

  const handleChangeProject = (event, value) => {
    setProjectFilters(value);
  };

  const handleChangeManaged = (event, value) => {
    setManagedFilters(value ?? []);
  };

  const handleChangeServices = (event, value) => {
    setServicesFilters(value ?? []);
  };

  const handleChangeAssigned = (event, value) => {
    setAssignedFilters(value ?? []);
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Managed By
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.wrapperTeamMember}
                >
                  <FormSelectChipsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={users}
                    name="managed"
                    label=""
                    internal={true}
                    handleChange={handleChangeManaged}
                    multiple={true}
                    value={managedFilters}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Assigned To
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid item md={12} className={classes.wrapperTeamMember}>
                  <FormSelectChipsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={usersList}
                    name="assigned"
                    label=""
                    internal={true}
                    handleChange={handleChangeAssigned}
                    multiple={true}
                    value={assignedFilters}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!removeCustomers && !Boolean(jobFilters.length) ? (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Customer
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} className={classes.autoContainerFilter}>
                {customers && Boolean(customers.length) ? (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={customers}
                    name="customer"
                    internal={true}
                    handleChange={handleChangeCustomer}
                    multiple={true}
                    value={customerFilters}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </Grid>
            </Grid>
          ) : null}
          {!removeProjects
            ? hasPermission(permissionProject.MODULE, permissions) && (
                <Grid container spacing={1} className={classes.filterGroup}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      variant="h4"
                      className={classes.titleFilter}
                      gutterBottom
                    >
                      Project
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    className={classes.autoContainerFilter}
                  >
                    {projects !== null ? (
                      <FormSelectAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={projects}
                        name="project"
                        handleChange={handleChangeProject}
                        multiple={true}
                        value={projectFilters}
                      />
                    ) : (
                      <LinearProgress color="secondary" />
                    )}
                  </Grid>
                </Grid>
              )
            : null}
          {!removeJobs && !Boolean(customerFilters.length) && (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Job
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} className={classes.autoContainerFilter}>
                {Boolean(jobs) ? (
                  <FormSelectJobsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={jobs}
                    name="job_number"
                    handleChange={handleChangeJob}
                    multiple={true}
                    value={jobFilters}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Service
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.wrapperTeamMember}
                >
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={services}
                    name="service"
                    label=""
                    handleChange={handleChangeServices}
                    multiple={true}
                    value={servicesFilters}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default WorkticketFiltersPreventive;
