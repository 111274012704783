import Dialog from "@material-ui/core/Dialog";
import React, { Fragment } from "react";
import Slide from "@material-ui/core/Slide";
import useStyles from "./styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Box } from "@material-ui/core";
import { ReceiptIcon } from "./icons/ReceiptIcon";
import Typography from "@material-ui/core/Typography";
import { WorkticketIcon } from "./icons/WorkticketIcon";
import Table from "../../components/common/Table/table";
import { useWorkticketModal } from "./context/WorkticketModalContext";
import { useWorkticketActions } from "./useWorkticketAction";
import LoadingIndicator from "../../components/common/LoadingData/loadingData";
import { AddWorkticketCommentDialogue } from "./AddCommentDialogue";
import { AddDiscountDialogue } from "./AddDiscountDialogue";
import { useAddDiscountModal } from "./context/AddDiscountDialogueContext";
import { WTIcon } from "./icons/WTIcon";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export function WorkticketModal({
  columns,
  handleRowClick,
  handleScrollClick,
  rowId,
  subcontractor,
  takeSingleId,
  fromWTPage,
  isAR,
}) {
  const [workticketModalAction, dispatchWorkticketModal] = useWorkticketModal();
  const { isWorkticketModalOpen } = workticketModalAction;
  const { data, isLoading, columnsReady, columnsSubcontractor } =
    useWorkticketActions({
      subcontractor: subcontractor,
      userId: fromWTPage ? rowId : rowId?.split("-")[1],
      isAR: isAR,
    });

  const classes = useStyles();
  function toggleWorkticketModal(open) {
    dispatchWorkticketModal({
      type: "TOGGLE_WORKTICKET_MODAL",
      open: open,
      activeRowId: null,
    });
  }
  const [discountAction, dispatchDiscountAction] = useAddDiscountModal();
  const { isOpenAddDialog } = discountAction;
  return (
    <Fragment key={rowId}>
      <Dialog open={true} fullScreen={true} TransitionComponent={Transition}>
        <Box className={classes.workticketModalMainContainer}>
          <Box className={classes.appBarWorkticketModalContainer}>
            <Box className={classes.workticketPreviewButtonContainer}>
              <Box className={classes.workticketPreviewTitle}>
                <WorkticketIcon />
                <Typography className={classes.workticketPreviewTitleText}>
                  Worktickets
                </Typography>
              </Box>
            </Box>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                toggleWorkticketModal(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {isLoading && <LoadingIndicator />}
          {!isLoading && (
            <Table
              handleRowClick={handleRowClick}
              hideHeaderCheckbox={true}
              checkIcon={<WTIcon />}
              // newColumns={subcontractor ? columnsSubcontractor : columnsReady}
              handleScrollClick={handleScrollClick}
              activeMobile={true}
            >
              {/*{filterState.filters && <FiltersAppliedList hideDate={true} />}*/}
            </Table>
          )}
        </Box>
        {isOpenAddDialog && (
          <AddDiscountDialogue
            columnsReady={columnsReady}
            workTicketModal={true}
            filterState={null}
          />
        )}
        <AddWorkticketCommentDialogue />
      </Dialog>
    </Fragment>
  );
}
