import Dialog from "@material-ui/core/Dialog";
import React, { Fragment } from "react";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Box } from "@material-ui/core";
import { ReceiptIcon } from "./icons/ReceiptIcon";
import Typography from "@material-ui/core/Typography";
import { OutlineReceiptIcon } from "./icons/OutlineReceiptIcon";
import { WorkticketIcon } from "./icons/WorkticketIcon";
import Table from "../../components/common/Table/table";
import { useWorkticketModal } from "./context/WorkticketModalContext";
import { TableProvider } from "../../contexts/tableContext";
import { useWorkticketActions } from "./useWorkticketAction";
import LoadingIndicator from "../../components/common/LoadingData/loadingData";
import { AddWorkticketCommentDialogue } from "./AddCommentDialogue";
import { AddDiscountDialogue } from "./AddDiscountDialogue";
import { useAddDiscountModal } from "./context/AddDiscountDialogueContext";
import { useFilterState } from "../../contexts/filterContext";
import { useHistory } from "react-router-dom";
import { hi } from "date-fns/locale";
import { useWorkticketActionsNoContext } from "./useWorkticketActionNoContext";
import { WTIcon } from "./icons/WTIcon";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export function WorkticketModalNoContext({
  columns,
  handleRowClick,
  handleScrollClick,
  rowId,
  subcontractor,
  takeSingleId,
  fromWTPage,
  isAR,
}) {
  const [workticketModalAction, dispatchWorkticketModal] = useWorkticketModal();
  let history = useHistory();
  const { isWorkticketModalOpen } = workticketModalAction;
  const { data, isLoading, columnsReady, columnsSubcontractor } =
    useWorkticketActionsNoContext({
      subcontractor: subcontractor,
      userId: fromWTPage ? rowId : rowId?.split("-")[1],
      isAR: isAR,
    });

  const classes = useStyles();
  function toggleWorkticketModal(open) {
    dispatchWorkticketModal({
      type: "TOGGLE_WORKTICKET_MODAL",
      open: open,
      activeRowId: null,
    });
  }
  return (
    <Fragment key={rowId}>
      <Dialog open={true} fullScreen={true} TransitionComponent={Transition}>
        <Box className={classes.workticketModalMainContainer}>
          <Box className={classes.appBarWorkticketModalContainer}>
            <Box className={classes.workticketPreviewButtonContainer}>
              <Box className={classes.workticketPreviewTitle}>
                <WorkticketIcon />
                <Typography className={classes.workticketPreviewTitleText}>
                  Workticket
                </Typography>
              </Box>
            </Box>

            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                toggleWorkticketModal(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {isLoading && <LoadingIndicator />}
          {!isLoading && (
            <Table
              handleRowClick={handleRowClick}
              // newColumns={subcontractor ? columnsSubcontractor : columnsReady}
              handleScrollClick={handleScrollClick}
              activeMobile={true}
              hideHeaderCheckbox={true}
              checkIcon={<WTIcon />}
            >
              {/*{filterState.filters && <FiltersAppliedList hideDate={true} />}*/}
            </Table>
          )}
        </Box>
      </Dialog>
    </Fragment>
  );
}
