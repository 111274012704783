import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ViewIcon from "@material-ui/icons/Visibility";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CommentIcon from "@material-ui/icons/Comment";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ClearIcon from "@material-ui/icons/Clear";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketAction } from "contexts/workticketActionContext";
import {
  useWorkticketState,
  useWorkticketDispatch,
} from "contexts/workticketContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import useStyles from "./styles";

const options = [
  {
    id: 1,
    label: "View Details",
    value: "VIEW",
    available: [0, 1, 3, 6],
    portfolio: 1,
    permission: permissionWorkticket.DETAIL,
    icon: <ViewIcon fontSize="inherit" />,
  },
  {
    id: 2,
    label: "Assign",
    value: "ASSIGN",
    available: [0, 1, 6],
    portfolio: 1,
    permission: permissionWorkticket.EDIT,
    icon: <AccountCircleIcon fontSize="inherit" />,
  },
  {
    id: 3,
    label: "Add a Comment",
    value: "COMMENT",
    available: [0, 1, 6],
    portfolio: 1,
    permission: permissionWorkticket.EDIT,
    icon: <CommentIcon fontSize="inherit" />,
  },
  {
    id: 4,
    label: "Change AR",
    value: "CHANGE_AR",
    available: [0, 1, 6],
    portfolio: 1,
    permission: permissionWorkticket.VIEW_AR,
    icon: <AttachMoneyIcon fontSize="inherit" />,
  },
  {
    id: 5,
    label: "Change AP",
    value: "CHANGE_AP",
    available: [0, 1, 6],
    portfolio: 1,
    permission: permissionWorkticket.VIEW_AP,
    icon: <AttachMoneyIcon fontSize="inherit" />,
  },
  {
    id: 6,
    label: "Decline",
    value: "DECLINE",
    available: [6],
    portfolio: 1,
    permission: permissionWorkticket.EDIT,
    icon: <ClearIcon fontSize="inherit" />,
  },
];

const WorkticketTableRequestActions = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const workticketAction = useWorkticketAction();
  const dispatchWorkticketAction = workticketAction[1];
  const dispatchWorktickets = useWorkticketDispatch();
  const { scheduleMode } = useWorkticketState();
  const open = Boolean(anchorEl);
  const { row, mobile } = props;

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    let type = "";

    if (action === "VIEW") {
      history.push(`/workticket/${row.id}`);
    } else {
      switch (action) {
        case "ASSIGN":
          type = "TOGGLE_ASSIGN_PARTNER";
          break;
        case "CHANGE_AR":
          type = "TOGGLE_CHANGE_AR";
          break;
        case "CHANGE_AP":
          type = "TOGGLE_CHANGE_AP";
          break;
        case "DECLINE":
          type = "TOGGLE_DECLINE";
          break;
        case "DELETE":
          type = "TOGGLE_DELETE";
          break;
        case "COMMENT":
          type = "TOGGLE_COMMENT";
          break;
        default:
          break;
      }
      dispatchWorkticketAction({
        type: type,
        open: true,
      });
      dispatchWorktickets({
        type: "SET_WORKTICKET_SELECTED",
        workticketSelected: row,
      });
      setAnchorEl(null);
    }
  };

  const handleClickSchedule = () => {
    dispatchWorkticketAction({
      type: "TOGGLE_SCHEDULE_SINGLE",
      open: true,
    });
    dispatchWorktickets({
      type: "SET_WORKTICKET_SELECTED",
      workticketSelected: row,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (
    !hasPermission(permissionWorkticket.DETAIL, permissions) &&
    !hasPermission(permissionWorkticket.EDIT, permissions) &&
    !hasPermission(permissionWorkticket.VIEW_AP, permissions) &&
    !hasPermission(permissionWorkticket.VIEW_AR, permissions)
  ) {
    return null;
  }

  const amountItems = options.reduce(
    (total, option) => {
      if (
        option.available.includes(parseInt(row.status)) &&
        (hasPermission(option.permission, permissions) ||
          (option.otherPermission
            ? hasPermission(option.otherPermission, permissions)
            : false))
      ) {
        total += 1;
      }
      return total;
    },
    [0]
  );

  if (amountItems === 0) {
    return null;
  }

  return !scheduleMode ? (
    <div>
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        // PaperProps={{
        //   style: {
        //     width: 200,
        //   },
        // }}
      >
        {options.map((option) => {
          return option.available.includes(parseInt(row.status)) &&
            (hasPermission(option.permission, permissions) ||
              (option.otherPermission
                ? hasPermission(option.otherPermission, permissions)
                : false)) ? (
            <MenuItem
              key={option.id}
              onClick={handleSelect}
              data={option.value}
            >
              <Box className={classes.menuIcon}>{option.icon}</Box>
              <Typography variant="caption" className={classes.menuText}>
                {option.label}
              </Typography>
            </MenuItem>
          ) : null;
        })}
      </Menu>
    </div>
  ) : (
    <Button
      className={classes.buttonSchedule}
      variant="contained"
      color="secondary"
      onClick={handleClickSchedule}
    >
      Schedule
    </Button>
  );
};

export default withRouter(WorkticketTableRequestActions);
