export const initialStepValidation = {
  step1: false,
  step2: false,
  step3: false,
  step4: false,
  step5: true,
};

export const statusLoadingTitles = {
  1: "Creating service, this process can take up to 10 minutes to complete. Please do not refresh your browser.",
  2: "Updating service, this process can take up to 10 minutes to complete. Please do not refresh your browser.",
  3: "Loading service",
  4: "Deleting service, this process can take up to 10 minutes to complete. Please do not refresh your browser.",
};

export const formHeaderTitles = {
  1: {
    title: "Input Locations and Service Costs",
    subtitle: "Input details on what you will change for the service.",
  },
  2: {
    title: "Input Service Details",
    subtitle: "This will be added into the associated worktickets.",
  },
  3: {
    title: "Input costs for the services",
    subtitle: "Add costs and determine who will perform service",
  },
  4: {
    title: "Determine Service Frequency and TimeFrames",
    subtitle:
      "Set up how often you are performing the service, the timeframes, and expiration",
  },
  5: { title: "View Service", subtitle: "Summary of the service" },
};

export const visualInspectionTitles = {
  1: {
    title: "Input Locations and Service Costs",
    subtitle: "Input Details on what you will charge for the service.",
  },
  2: {
    title: "Input Inspection Details",
    subtitle: "This will be added into the associated worktickets.",
  },
  3: {
    title: "Input Inspection Details",
    subtitle: "This will be added into the associated worktickets.",
  },
  4: {
    title: "Determine Inspection Frequency",
    subtitle: "Set up how often you are performing this service",
  },
  5: {
    title: "View Inspection",
    subtitle: "This will be added into the associated worktickets.",
  },
};

export const billingOptions = [
  { value: 0, label: "Upon Work Completion" },
  { value: 3, label: "Hourly Rate" },
];
export const billingOptionsMonthlyService = [
  { value: 0, label: "Per Service" },
  { value: 1, label: "Per Month" },
];
export const billingTimelineOptions = [
  { value: 1, label: "First Day of the Month" },
  { value: 2, label: "Last Day of the Month" },
  { value: 3, label: "Specific Day of the Month" },
];

export const performService = [
  { value: 1, label: "In-House" },
  { value: 2, label: "Sub-Contractor" },
];

export const paymentInHouse = [
  { value: 1, label: "Timekeeping Actuals - Auto" },
  { value: 5, label: "No Payments" },
];

export const paymentSubcontractor = [
  { value: 0, label: "Flat fee - Upon Work Completion" },
  { value: 3, label: "Hourly Rate" },
  { value: 6, label: "Per Month" },
  { value: 5, label: "No Payment" },
];

export const frequencyOptions = [
  { value: 1, label: "One-Time" },
  { value: 2, label: "Recurring" },
];

export const serviceGLTypeOptions = [
  { value: 1, label: "Add-On" },
  { value: 2, label: "Contract" },
];

export const intervalOptions = [
  { value: 1, label: "Day", labelPlural: "Daily", id: "days" },
  { value: 2, label: "Week", labelPlural: "Weekly", id: "weeks" },
  { value: 3, label: "Month", labelPlural: "Monthly", id: "months" },
];

export const intervalDisplay = {
  days: "Daily",
  weeks: "Weekly",
  months: "Monthly",
};

export const optionsDays = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 7, label: "Sunday" },
];
export const optionsDaysAbbreviation = [
  { value: 1, label: "Monday", abbreviation: "Mon" },
  { value: 2, label: "Tuesday", abbreviation: "Tue" },
  { value: 3, label: "Wednesday", abbreviation: "Wed" },
  { value: 4, label: "Thursday", abbreviation: "Thu" },
  { value: 5, label: "Friday", abbreviation: "Fri" },
  { value: 6, label: "Saturday", abbreviation: "Sat" },
  { value: 7, label: "Sunday", abbreviation: "Sun" },
];
export const optionsDaysMonth = [...Array(31).keys()].map((item) => {
  return { value: item + 1, label: String(item + 1) };
});

export const optionsInspectionType = [
  { value: 1, label: "Ground Inspections (2D)" },
  // { value: 2, label: "Ground Inspections (360)" },
  { value: 3, label: "Aerial Inspection" },
];
