import PageHeader from "../../components/common/PageHeader/pageHeader";
import React from "react";
import AccountPayablesSubWorkList from "../reportAccountPayableSubWorkList/accountPayablesSubWorkList";
import { WorkticketModalProvider } from "../reportAccountPayableWorkticketsInternal/context/WorkticketModalContext";

export function accountPayableSubWorkListWrapper() {
  return (
    <>
      <PageHeader
        pageTitle="Payments"
        link="/financial/report/account-payable-work-sub"
      ></PageHeader>
      <WorkticketModalProvider>
        <AccountPayablesSubWorkList />
      </WorkticketModalProvider>
    </>
  );
}
