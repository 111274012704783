import React, { useState, useEffect, useCallback } from "react";
import { useLocation, withRouter } from "react-router-dom";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Counter from "components/common/TableList/counter";
import WorkticketSoloStatusChip from "components/ui/Worktickets/WorkticketSoloStatusChip";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import AccountPayablesFilters from "./accountPayablesSubWorkFilters";
import CalendarFilters from "components/common/Filters/calendarFilters";
import { dateFormatField } from "components/util/timeFormat";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useBillingState,
  useBillingDispatch,
  getBillingList,
  searchBilling,
  getBillingListDataSet,
} from "contexts/billingApSubContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import { VariousTextComponent } from "../reportAccountPayableWorkticketsInternal/VariousTextComponent";
import WorkticketField from "components/ui/Worktickets/WorkticketField";
import WorkticketComplianceChip from "../../components/ui/Worktickets/WorkticketComplianceChip";

const columns = [
  // {
  //   id: "workticket_number",
  //   label: "Workticket",
  //   format: "id",
  //   checkbox: {
  //     active: (row) => false,
  //   },
  // },
  {
    id: "id",
    label: "ID",
    checkbox: {
      active: (row) => false,
    },

    content: (row) => `${row.id} `,
    style: { minWidth: 100 },
  },
  // {
  //   id: "number",
  //   label: "Number",
  //   format: "id",
  //   path: "workticket",
  // },
  {
    id: "workticket_number",
    label: "Workticket",
    format: "link",
    content: (row) => (
      <>
        {row.number_worktickets <= 1 &&
          `${row.workticket_number}${row.partial_suffix ?? ``}`}
        {row.number_worktickets === null && <>-</>}
        {row.number_worktickets > 1 && (
          <VariousTextComponent id={row.id} subcontractor={true} />
        )}
      </>
    ),
    path: (row) =>
      row.workticket_id ? `/workticket/${row.workticket_id}` : ``,
  },
  {
    id: "workticket_summary",
    label: "WT Summary",
    content: (row) => (
      <>
        {row.number_worktickets > 1 && (
          <VariousTextComponent id={row.id} subcontractor={true} />
        )}
        {row.number_worktickets <= 1 && (
          <WorkticketField field={row.workticket_summary} fieldLength={35} />
        )}
      </>
    ),
    style: { minWidth: 160 },
  },
  {
    id: "job",
    label: "Job",
    content: (row) => `${row.job_number} - ${row.job_description}`,
    style: { minWidth: 160 },
  },
  {
    id: "compliance",
    label: "Compliance",
    content: (row) => (
      <>
        {row.number_worktickets > 1 && (
          <VariousTextComponent id={row.id} subcontractor={true} />
        )}
        {row.number_worktickets <= 1 && (
          <WorkticketComplianceChip
            onSite={row.compliance_onsite}
            onTime={row.compliance_ontime}
            hasDocuments={row.compliance_document}
          />
        )}
      </>
    ),
    disableSort: true,
  },
  {
    id: "status",
    label: "WT Status",
    content: (row, index) => (
      <>
        {row.number_worktickets > 1 && (
          <VariousTextComponent id={row.id} subcontractor={true} />
        )}
        {row.number_worktickets <= 1 && (
          <WorkticketSoloStatusChip
            status={row?.workticket_status ?? ""}
            archived={0}
          />
        )}
      </>
    ),
    disableSort: true,
  },
  {
    id: "workticket_start_date",
    label: "Due Date",
    content: (row) => (
      <>
        {row.number_worktickets > 1 && (
          <VariousTextComponent id={row.id} subcontractor={true} />
        )}
        {row.number_worktickets <= 1 &&
          (row.workticket_start_date
            ? dateFormatField(row.workticket_start_date)
            : `-`)}
      </>
    ),
  },
  {
    id: "workticket_sub_completed_date",
    label: "Completed Date",
    // format: (row) => (row.number_worktickets < 1 ? "date" : null),
    content: (row) => (
      <>
        {row.number_worktickets > 1 && (
          <VariousTextComponent id={row.id} subcontractor={true} />
        )}
        {row.number_worktickets <= 1 &&
          (row.workticket_sub_completed_date
            ? dateFormatField(row.workticket_sub_completed_date)
            : `-`)}
      </>
    ),
  },
  {
    id: "workticket_completed_date",
    label: "Verified Date",
    content: (row) => (
      <>
        {row.number_worktickets > 1 && (
          <VariousTextComponent id={row.id} subcontractor={true} />
        )}
        {row.number_worktickets <= 1 &&
          (row.workticket_completed_date
            ? dateFormatField(row.workticket_completed_date)
            : `-`)}
      </>
    ),
  },
  {
    id: "total_amount",
    label: "Payment",
    type: "money",
  },
  {
    id: "discount",
    label: "Discount",
    content: (row) => (
      <>
        {/*{row.number_worktickets > 1`-`}*/}
        {row.discount > 0 ? `${row.discount}%` : `-`}
      </>
    ),
  },
  {
    id: "after_discount",
    label: "After Discount",
    content: (row) =>
      Number(row.discount) > 0
        ? (
            Number(row.total_amount) -
            Number(row.total_amount) * (Number(row.discount) / 100)
          ).toFixed(2)
        : row.total_amount,
  },
];

const AccountPayablesSubWorkTable = (props) => {
  const classes = useStyles();
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const { billingListLoading, billingList, billingCount, currentTab } =
    useBillingState();

  const { hasRowsClickable, selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchBilling = useBillingDispatch();
  const dispatchFilters = useFilterDispatch();
  const location = useLocation();
  const [tabValue, setTabValue] = useState(location.state?.newTab ?? 2);

  useEffect(() => {
    dispatchBilling({
      type: "SET_LOADING",
      billingListLoading: true,
    });
    const fetchData = async () => {
      setIsTableLoading(true);
      const filterData = getFilterData(filterState);

      await getBillingList(dispatchBilling, filterData);

      setIsTableLoading(false);
      dispatchBilling({
        type: "SET_LOADING",
        billingListLoading: false,
      });
    };

    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchBilling, filterState]);

  useEffect(() => {
    dispatchBilling({
      type: "SET_LOADING",
      billingListLoading: true,
    });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    const billingDataUpdate = billingList[currentTab] ?? [];
    dispatchTable({ type: "UPDATE_ROWS", rows: billingDataUpdate });
    dispatchBilling({
      type: "SET_LOADING",
      billingListLoading: false,
    });
  }, [dispatchTable, billingList]);

  useEffect(() => {
    const local_tab = localStorage.getItem("sub_tab");
    if (!local_tab) {
      dispatchBilling({ type: "SET_CURRENT_TAB", currentTab: "2" });
      localStorage.setItem("sub_tab", "2");
      return;
    }
    setTabValue(local_tab);
    dispatchBilling({ type: "SET_CURRENT_TAB", currentTab: local_tab });
    const billingDataUpdate = billingList[currentTab] ?? [];
    dispatchTable({ type: "UPDATE_ROWS", rows: billingDataUpdate });
    dispatchBilling({
      type: "SET_LOADING",
      billingListLoading: false,
    });
  }, [currentTab]);

  const handleScrollClick = useCallback(async () => {
    if (
      billingCount[currentTab] <= billingList[currentTab].length ||
      loadingMore
    ) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);

      await getBillingListDataSet(
        billingList,
        billingList[currentTab].length,
        dispatchBilling,
        filterData,
        filterState.searchString,
        currentTab
      );

      const billingDataUpdate = billingList[currentTab] ?? [];
      dispatchTable({ type: "UPDATE_ROWS", rows: billingDataUpdate });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    billingList,
    billingCount,
    filterState,
    dispatchBilling,
    dispatchTable,
    loadingMore,
    currentTab,
  ]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          let results = await searchBilling(query, filterData);

          // Send result to search tab
          dispatchBilling({
            type: "SET_BILLING_LIST",
            billingList: results.list,
          });

          dispatchBilling({
            type: "SET_BILLING_COUNT",
            billingCount: results.count,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  const handleModuleChange = (event, newTab) => {
    const { history } = props;
    if (newTab === "0") {
      history.push(`/financial/report/account-payable-sub`);
    } else {
      setTabValue(newTab);
      localStorage.setItem("sub_tab", newTab.toString());
      dispatchBilling({
        type: "SET_LOADING",
        billingListLoading: true,
      });
      setTimeout(() => {
        dispatchBilling({
          type: "SET_CURRENT_TAB",
          currentTab: newTab,
        });
      }, 800);
    }
  };

  return (
    <Box className={classes.root}>
      {billingListLoading || loadingSearch || isTableLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <TableTabs handleTabChange={handleModuleChange} value={tabValue}>
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Pending Payment</Box>}
                key="2"
                value="2"
              />
              <StyledTab
                disableRipple={true}
                label={
                  <Box className={classes.tabItem}>Payment Processing</Box>
                }
                key="3"
                value="3"
              />
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Checks</Box>}
                key="0"
                value="0"
              />
            </TableTabs>
            <Box className={classes.contentCounter}>
              <Counter>{`${billingList[currentTab]?.length} / ${billingCount[currentTab]}`}</Counter>
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected?.length > 0 ? (
                        <ToolbarSelected></ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          handleSearchClear={handleSearchClear}
                        >
                          <CalendarFilters />
                          <AccountPayablesFilters
                            showDiscountFilter={true}
                            filterCount={
                              filterState.filters
                                ? filterState.filters.length
                                : 0
                            }
                          />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(AccountPayablesSubWorkTable);
