import React, { useContext, useMemo, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withRouter } from "react-router-dom";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import { useAddDiscountModal } from "../reportAccountPayableWorkticketsInternal/context/AddDiscountDialogueContext";
import { AddDiscountDialogue } from "../reportAccountPayableWorkticketsInternal/AddDiscountDialogue";
import { ExclamationMarkIcon } from "./icon/ExclamationMarkIcon";
import {
  hasPermission,
  permissionWorkticket,
  permissionQuote,
} from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";
import { AccountPayableDiscountItem } from "./AccountPayableDiscountItem";

export function EditIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_164_6895)">
        <path
          d="M3.33301 14.0448V16.2964C3.33301 16.5038 3.49595 16.6667 3.70333 16.6667H5.95487C6.05115 16.6667 6.14743 16.6297 6.21409 16.5556L14.3019 8.47525L11.5245 5.69786L3.4441 13.7782C3.37004 13.8523 3.33301 13.9412 3.33301 14.0448ZM16.4497 6.3274C16.7386 6.03855 16.7386 5.57195 16.4497 5.2831L14.7166 3.55001C14.4278 3.26116 13.9612 3.26116 13.6723 3.55001L12.3169 4.90538L15.0943 7.68277L16.4497 6.3274Z"
          fill="#747474"
        />
      </g>
      <defs>
        <clipPath id="clip0_164_6895">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
    textAlign: "center",
    alignItems: "center",
    marginBottom: 4,
  },
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 132,
      width: "100%",
      backgroundColor: "#4F98BC",
    },
  },
})((props) => (
  <Tabs
    orientation={"horizontal"}
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
));

const StyledTab = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(0),
    fontWeight: theme.typography.fontWeightRegular,
    minWidth: "90px",
    textAlign: "center",
    alignItems: "center",
    "& > span:first-child": {
      textAlign: "center",
      display: "block",
    },
    "&. MuiTab-root": {
      minWidth: "90px",
    },
    "&:hover": {
      color: "#4F98BC",
      opacity: 1,
    },
    "&$selected": {
      color: "#4F98BC",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#4F98BC",
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
      fontWeight: 600,
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

const AccountReceivablePayable = (props) => {
  const workticket = props.workticket;
  const classes = useStyles();
  const [isAccountReceivable, setIsAccountReceivable] = useState(false);
  const [value, setValue] = useState(0);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const permOnlyAP = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_ONLY_AP, permissions),
    [permissions]
  );
  const permAP = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_AP, permissions),
    [permissions]
  );
  const permAR = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_AR, permissions),
    [permissions]
  );

  const permViewQuote = useMemo(
    () => hasPermission(permissionQuote.DETAIL, permissions),
    [permissions]
  );

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenQuote = () => {
    const { history } = props;
    history.push(`/quote/${workticket.quote_id}`);
  };

  const [discountAction, dispatchDiscountAction] = useAddDiscountModal();
  const { isOpenAddDialog } = discountAction;

  const readOnlyWorkticket =
    workticket?.recurrence_instance_id && !workticket?.instance_override;

  const canChangeBilling =
    !readOnlyWorkticket &&
    Number(workticket?.bill?.amount_billed) === 0 &&
    [0, 1].includes(workticket?.bill?.status);

  const canChangePayment =
    !readOnlyWorkticket &&
    Number(workticket?.payment?.amount_paid) === 0 &&
    [0, 1].includes(workticket?.payment.status) &&
    !permOnlyAP;

  const labelPayable = !(Number(workticket?.external?.perform_by) === 1)
    ? "Account Payable"
    : "Costs";

  return (
    <>
      {!workticket?.preventative_service && !permViewQuote ? (
        (permAP || permOnlyAP) && (
          <>
            <Box className={classes.payableReceivableMainContainer}>
              <StyledTabs
                value={value}
                onChange={handleChangeTab}
                aria-label="styled tabs"
              >
                <StyledTab label={labelPayable} {...a11yProps(0)} />
              </StyledTabs>

              {workticket?.ap_records?.length === 0 ? (
                <Typography
                  variant="subtitle2"
                  className={classes.emptyBilling}
                >
                  No Payment
                </Typography>
              ) : (
                <TabPanel value={value} index={0}>
                  <Box className={classes.discountsHolderContainer}>
                    <AccountPayableDiscountItem
                      classes={classes}
                      userName={"Subcontractor"}
                      customerName={workticket?.ap_records[0]?.user_name}
                      paymentWTid={workticket?.ap_records[0]?.id}
                      workticket={workticket?.ap_records[0]}
                      workticketMainId={workticket?.id}
                      paymentId={workticket?.ap_records[0]?.job_id}
                      mode={"Payment"}
                      ogWT={workticket}
                      totalAmount={workticket?.ap_total}
                      isPayment={true}
                      permAR={permAR}
                      permAP={permAP}
                      canChangePayment={false}
                      setAccountReceivable={() => {}}
                    />
                  </Box>
                </TabPanel>
              )}
            </Box>
          </>
        )
      ) : (
        <Box className={classes.payableReceivableMainContainer}>
          <StyledTabs
            value={value}
            onChange={handleChangeTab}
            aria-label="styled tabs"
          >
            {permAR && (
              <StyledTab label="Account Receivable" {...a11yProps(0)} />
            )}
            {(permAP || permOnlyAP) && (
              <StyledTab label={labelPayable} {...a11yProps(1)} />
            )}
          </StyledTabs>
          <TabPanel value={value} index={permAR && permAP ? 0 : permAR ? 0 : 1}>
            <Box className={classes.discountsHolderContainer}>
              {(workticket?.bill?.id || workticket?.monthly_bill?.id) && (
                <>
                  {[0, 1].includes(workticket.status) &&
                  (workticket?.bill?.status === 4 ||
                    workticket?.monthly_bill?.status === 4) &&
                  !readOnlyWorkticket ? (
                    <Alert severity="error" className={classes.infoAlert}>
                      Contact support/financial to update amounts.
                    </Alert>
                  ) : null}
                  {workticket?.bill ? (
                    <AccountPayableDiscountItem
                      userName={"Customer"}
                      permAR={permAR}
                      permAP={permAP}
                      mode={"Billing"}
                      ogWT={workticket}
                      paymentWTid={workticket?.payment?.id}
                      isAR={true}
                      workticketMainId={workticket?.id}
                      classes={classes}
                      customerName={workticket?.bill?.customer_name}
                      workticket={workticket.bill}
                      isRecurrent={
                        workticket?.preventative_service[0]?.billing_type === 6
                      }
                      paymentId={workticket?.bill?.job_id}
                      totalAmount={workticket?.bill?.net_total}
                      amountBilled={workticket?.bill?.amount_billed}
                      isPayment={true}
                      canChangeBilling={canChangeBilling}
                      setAccountReceivable={setIsAccountReceivable}
                    />
                  ) : workticket?.monthly_bill ? (
                    <AccountPayableDiscountItem
                      userName={"Customer"}
                      permAR={permAR}
                      permAP={permAP}
                      mode={"Billing"}
                      ogWT={workticket}
                      paymentWTid={workticket?.monthly_bill?.id}
                      isAR={true}
                      workticketMainId={workticket?.id}
                      classes={classes}
                      customerName={workticket?.monthly_bill?.customer_name}
                      workticket={workticket.monthly_bill}
                      isRecurrent={
                        workticket?.preventative_service[0]?.billing_type === 6
                      }
                      paymentId={workticket?.monthly_bill?.job_id}
                      totalAmount={workticket.ar_total}
                      amountBilled={workticket?.monthly_bill?.amount_billed}
                      isPayment={true}
                      canChangeBilling={canChangeBilling}
                      setAccountReceivable={setIsAccountReceivable}
                    />
                  ) : null}
                </>
              )}
              {!workticket?.preventative_service ? (
                <>
                  <Box className={classes.noticeContainer}>
                    <Box className={classes.noticeExclamationIcon}>
                      <ExclamationMarkIcon />
                    </Box>
                    <Typography className={classes.noticeText}>
                      To update the billing for this workticket, please update
                      the{" "}
                      <span
                        onClick={handleOpenQuote}
                        className={classes.hereLinkText}
                      >
                        quote
                      </span>
                      .
                    </Typography>
                  </Box>
                </>
              ) : !workticket?.bill && !workticket?.monthly_bill ? (
                <Typography
                  variant="subtitle2"
                  className={classes.emptyBilling}
                >
                  No Billing
                </Typography>
              ) : null}
            </Box>
          </TabPanel>
          <TabPanel value={value} index={permAR && permAP ? 1 : permAR ? 1 : 0}>
            <Box className={classes.discountsHolderContainer}>
              {workticket?.payment?.id || workticket?.monthly_payment?.id ? (
                <>
                  {[0, 1].includes(workticket.status) &&
                  (workticket?.payment?.status === 4 ||
                    workticket?.monthly_payment?.id === 4) &&
                  !permOnlyAP &&
                  !(Number(workticket?.external?.perform_by) === 1) ? (
                    <Alert severity="error" className={classes.infoAlert}>
                      Contact support/financial to update amounts.
                    </Alert>
                  ) : null}
                  {workticket.payment &&
                  !(Number(workticket?.external?.perform_by) === 1) ? (
                    <AccountPayableDiscountItem
                      classes={classes}
                      userName={"Subcontractor"}
                      customerName={workticket?.payment?.user_name}
                      paymentWTid={workticket?.payment?.id}
                      workticket={workticket.payment}
                      workticketMainId={workticket?.id}
                      paymentId={workticket?.payment?.job_id}
                      mode={"Payment"}
                      ogWT={workticket}
                      totalAmount={workticket?.ap_total}
                      isPayment={true}
                      permAR={permAR}
                      permAP={permAP}
                      canChangePayment={canChangePayment}
                      setAccountReceivable={setIsAccountReceivable}
                      isRecurrent={
                        workticket?.preventative_service[0]?.payment_type === 6
                      }
                    />
                  ) : workticket.payment &&
                    Number(workticket?.external?.perform_by) === 1 ? (
                    <Box className={classes.costsContainer}>
                      <Typography
                        variant="body1"
                        className={classes.costsContainerTitle}
                      >
                        Costs:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.costsContainerLabel}
                      >
                        ${parseFloat(workticket?.ap_total).toFixed(2)}
                      </Typography>
                    </Box>
                  ) : workticket?.monthly_payment ? (
                    <AccountPayableDiscountItem
                      classes={classes}
                      userName={"Subcontractor"}
                      customerName={workticket?.monthly_payment?.user_name}
                      paymentWTid={workticket?.monthly_payment?.id}
                      workticket={workticket.monthly_payment}
                      workticketMainId={workticket?.id}
                      paymentId={workticket?.monthly_payment?.job_id}
                      mode={"Payment"}
                      ogWT={workticket}
                      totalAmount={workticket?.ap_total}
                      isPayment={true}
                      permAR={permAR}
                      permAP={permAP}
                      canChangePayment={canChangePayment}
                      setAccountReceivable={setIsAccountReceivable}
                      isRecurrent={
                        workticket?.preventative_service[0]?.payment_type === 6
                      }
                    />
                  ) : (
                    <Typography
                      variant="subtitle2"
                      className={classes.emptyBilling}
                    >
                      No Payment
                    </Typography>
                  )}
                </>
              ) : (
                <Typography
                  variant="subtitle2"
                  className={classes.emptyBilling}
                >
                  No Payment
                </Typography>
              )}
              {!workticket?.preventative_service ? (
                <>
                  <Box className={classes.noticeContainer}>
                    <Box className={classes.noticeExclamationIcon}>
                      <ExclamationMarkIcon />
                    </Box>
                    <Typography className={classes.noticeText}>
                      To update the payment for this workticket, please update
                      the{" "}
                      <span
                        onClick={handleOpenQuote}
                        className={classes.hereLinkText}
                      >
                        quote
                      </span>
                      .
                    </Typography>
                  </Box>
                </>
              ) : (
                !workticket?.payment &&
                !workticket?.monthly_payment &&
                workticket?.ap_records?.length === 0 && (
                  <Typography
                    variant="subtitle2"
                    className={classes.emptyBilling}
                  >
                    No Payment
                  </Typography>
                )
              )}
            </Box>
          </TabPanel>
        </Box>
      )}
      {isOpenAddDialog && (
        <AddDiscountDialogue
          workTicketModal={true}
          filterState={null}
          isAR={isAccountReceivable}
        />
      )}
    </>
  );
};

export default withRouter(AccountReceivablePayable);
