import React, { useState, useEffect, useMemo, useContext } from "react";
import withTableContext from "components/hoc/withTableContext";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormInput from "components/ui/FormContent/formInput";
import FormTotal from "components/ui/FormContent/formTotal";
import LinearProgress from "@material-ui/core/LinearProgress";
import BackIcon from "@material-ui/icons/ArrowBack";
import { getTeamMembers } from "services/projectService";
import Joi from "joi-browser";
import { logException } from "components/util/logUtil";
import { getCategories } from "services/categoryService";
import { getSubcontractors } from "services/subcontractorService";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { ButtonBase } from "components/ui/core/button";
import QuoteItemTask from "./quoteItemTask";
import QuoteUserItem from "./quoteUserItem";
import AddIcon from "@material-ui/icons/AddCircle";
import WarningIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WorkticketIcon from "@material-ui/icons/Receipt";
import {
  useQuoteItemUserState,
  useQuoteItemUserDispatch,
} from "contexts/quoteItemUserContext";
import {
  billingOptions,
  userAssignmentType,
  inHousePaymentTypes,
  subcontractorPaymentTypes,
  userUOMTypes,
} from "constants.js";
import { validate, validateProperty } from "components/util/validationUtil";
import {
  useQuoteState,
  useQuoteDispatch,
  addQuoteItems,
  updateQuoteItems,
} from "contexts/quoteContext";
import FileUpload from "components/common/FileUpload/fileUpload";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import MessageDialog from "./dialog/messageDialog";
import {
  useQuoteItemDrawerDispatch,
  useQuoteItemDrawerState,
} from "contexts/quoteItemDrawerContext";
import FilesDisplayOnlyUploaded from "components/ui/FilesDisplayUploaded/filesDisplayOnlyUploaded";
import {
  addQuoteItemPreview,
  validateUpdateQuoteItem,
} from "services/quoteService";
import PreviewDialog from "./dialog/previewDialog";
import { useTableDispatch } from "contexts/tableContext";
import { dateFormatField } from "components/util/timeFormat";
import FieldWTSummary from "./fieldWTSummary";
import useStyles from "./styles";
import Tooltip from "@material-ui/core/Tooltip";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionQuote, hasPermission } from "lib/permissions";

const quoteItemUserSchema = {
  user_type: Joi.object()
    .required()
    .label("User Type")
    .error(() => {
      return {
        message: "User Type is required.",
      };
    }),
  payment: Joi.object()
    .required()
    .label("Payment Type")
    .error(() => {
      return {
        message: "Payment Type is required.",
      };
    }),
  quantity: Joi.number().required().min(0.1).label("Quantity"),
  unit_cost: Joi.number().required().min(0.1).label("Cost"),
  user_id: Joi.number()
    .required()
    .label("Responsible")
    .error(() => {
      return {
        message: "Responsible is required.",
      };
    }),
  user: Joi.object(),
};

const quoteItemStep0Schema = {
  category: Joi.object()
    .required()
    .label("Category")
    .error(() => {
      return {
        message: "Category is required.",
      };
    }),
  item_subject: Joi.string()
    .min(3)
    .max(400)
    .required()
    .label("Item Subject")
    .error((errors) => {
      return errors.map((err) => {
        switch (err.type) {
          case "string.max":
            return {
              message: "Subject must not exceed 400 characters.",
            };
          case "any.empty":
          case "string.min":
            return { message: "Subject is required." };
          default:
            return { message: "Invalid Subject." };
        }
      });
    }),
  quantity: Joi.number().required().min(0).label("Quantity"),
  price: Joi.number().required().min(0).label("Price"),
  total: Joi.number().required().min(0).label("Total"),
  billing: Joi.object()
    .required()
    .label("Billing")
    .error(() => {
      return {
        message: "Billing Type is required.",
      };
    }),
};

const quoteItemStep1Schema = {
  summary: Joi.string()
    .min(1)
    .max(255)
    .required()
    .label("Service Summary")
    .error((errors) => {
      return errors.map((err) => {
        switch (err.type) {
          case "string.max":
            return {
              message: "Service Summary must not exceed 255 characters.",
            };
          case "any.empty":
          case "string.min":
            return { message: "Service Summary is required." };
          default:
            return { message: "Invalid Service Summary." };
        }
      });
    }),
  description: Joi.string()
    .min(1)
    .required()
    .label("Service Description")
    .error(() => {
      return {
        message: "Service Description is required.",
      };
    }),
};

const initialDataUser = {
  position: 0,
  isNew: true,
  user: null,
  user_id: -1,
  hours: 0,
  error: [],
  unit_cost: 0,
  total_cost: 0,
  quantity: 0,
  payment_type: null,
  payment: null,
  type: null,
  user_type: null,
  uomObject: userUOMTypes[1],
  uom: userUOMTypes[1].value,
};

const InitialData = {
  item_type: "unit",
  quantity: 0,
  uom: "unit",
  price: 0,
  hours: 0,
  group: 1,
  total: 0,
  productivity: null,
  task_list: [],
  categoryId: null,
  category: null,
  item_subject: "",
  billing_type: null,
  billing: null,
  summary: "",
  description: "",
  total_cost: 0,
  files: [],
  filesDisplay: [],
};

const QuoteItemCustom = (props) => {
  const columns = useMemo(
    () => [
      {
        id: "summary",
        label: "WT Summary",
        content: (row) => <FieldWTSummary field={row.summary} />,
      },
      {
        id: "job",
        label: "Job",
      },
      {
        id: "job_number",
        label: "Job Number",
      },
      {
        id: "assigned",
        label: "Assigned To",
      },
      {
        id: "payments",
        label: "Payments",
        format: "money",
      },
      {
        id: "creation_date",
        label: "Creation Date",
        content: (row) => dateFormatField(row.creation_date),
        style: { width: 110 },
      },
      {
        id: "due_date",
        label: "Due Date",
        content: (row) => dateFormatField(row.due_date),
        style: { width: 110 },
      },
    ],
    []
  );

  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [schema, setSchema] = useState(quoteItemStep0Schema);
  const [data, setData] = useState(InitialData);
  const [updatedData, setUpdatedData] = useState({});
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState(null);
  const [isLoadingCategories, setIsloadingCategories] = useState(true);
  const [error, setError] = useState([]);
  const { quoteDetails, selectedQuoteItem } = useQuoteState();
  const [update, setUpdate] = useState(0);
  const [userType, setUserType] = useState(null);
  const [subs, setSubs] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [updateCost, setUpdateCost] = useState(false);
  const [deletedTasks, setDeletedTasks] = useState([]);
  const [newTasks, setNewTasks] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [isloadingMessage, setIsloadingMessage] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isLoadingPreview, setIsloadingPreview] = useState(false);
  const { quoteItemUsers, deletedQuoteItemUsers, changedQuoteItemUsers } =
    useQuoteItemUserState();
  const dispatchQuoteItemUser = useQuoteItemUserDispatch();
  const dispatchQuote = useQuoteDispatch();
  const dispatchQuoteItemDrawer = useQuoteItemDrawerDispatch();
  const { fromMainQuote } = useQuoteItemDrawerState();
  const dispatchTable = useTableDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const hasEdit = hasPermission(permissionQuote.EDIT, permissions);
  const readOnlyGlobal =
    [20].includes(quoteDetails.status) ||
    quoteDetails.is_old_structure ||
    !hasEdit;

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  useEffect(() => {
    if (updatedData.total) {
      // console.log(error);
      validate(schema, data, setError);
    }
  }, [updatedData?.total]);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const dataCat = await getCategories();
        const resultForRender = dataCat.data.data.categories.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        if (quoteDetails.type === 1) {
          const value = billingOptions[1];
          const newData = {
            ...data,
            billing: value,
            billing_type: value ? value.value : null,
          };
          setData(newData);
        }
        setCategories(resultForRender);
        const dataSubs = await getSubcontractors();
        const resultForRenderSubs = dataSubs.data.data.subs.map((user) => {
          return {
            value: Number(user.id),
            label: `${user.first_name} ${user.last_name}`,
          };
        });
        setSubs(resultForRenderSubs);
        const resultUsers = await getTeamMembers(
          quoteDetails.job ? quoteDetails.job.id : 0
        );
        const resultForRenderUsers = resultUsers.data.data.users.map((user) => {
          return {
            value: user.id,
            label: `${user.first_name} ${user.last_name}`,
            hour_rate: user.hour_rate,
          };
        });
        setUsers(resultForRenderUsers);
        if (selectedQuoteItem) {
          // console.log(selectedQuoteItem);
          const selectedBilling = billingOptions.filter(
            (item) => item.value === selectedQuoteItem.billing_type
          );
          const selectedCategory = resultForRender.filter(
            (item) => item.value === selectedQuoteItem.categoryId
          );
          setData({
            category:
              selectedCategory?.length === 1 ? selectedCategory[0] : null,
            categoryId: selectedQuoteItem.categoryId,
            item_subject: selectedQuoteItem.item_subject,
            item_type: selectedQuoteItem.item_type,
            quantity: parseFloat(selectedQuoteItem.quantity),
            uom: selectedQuoteItem.uom,
            price: parseFloat(selectedQuoteItem.price),
            hours: parseFloat(selectedQuoteItem.hours),
            total: parseFloat(selectedQuoteItem.total),
            productivity: selectedQuoteItem.productivity,
            priceType: selectedQuoteItem.priceType,
            task_list: selectedQuoteItem.tasks,
            billing_type: selectedQuoteItem.billing_type,
            billing:
              selectedBilling?.length === 1
                ? selectedBilling[0]
                : quoteDetails.is_old_structure
                ? billingOptions[1]
                : null,
            summary: selectedQuoteItem.summary,
            description: selectedQuoteItem.description,
            total_cost: parseFloat(selectedQuoteItem.total_cost),
            files: [],
            filesDisplay: selectedQuoteItem.files,
          });
          const quoteItemsUsersTemp = [];
          for (let i = 0; i < selectedQuoteItem.quote_items_user.length; i++) {
            const selectedPayment =
              selectedQuoteItem.quote_items_user[i].type === 0
                ? inHousePaymentTypes.filter(
                    (item) =>
                      item.value ===
                      selectedQuoteItem.quote_items_user[i].payment_type
                  )
                : subcontractorPaymentTypes.filter(
                    (item) =>
                      item.value ===
                      selectedQuoteItem.quote_items_user[i].payment_type
                  );
            const selectedUserType = userAssignmentType.filter(
              (item) =>
                item.value === selectedQuoteItem.quote_items_user[i].type
            );
            const selectedUOM = userUOMTypes.filter(
              (item) => item.value === selectedQuoteItem.quote_items_user[i].uom
            );
            const selectedUser =
              selectedQuoteItem.quote_items_user[i].type === 0
                ? resultForRenderUsers.filter(
                    (item) =>
                      item.value ===
                      selectedQuoteItem.quote_items_user[i].user_id
                  )
                : resultForRenderSubs.filter(
                    (item) =>
                      item.value ===
                      selectedQuoteItem.quote_items_user[i].user_id
                  );
            quoteItemsUsersTemp.push({
              ...selectedQuoteItem.quote_items_user[i],
              total_cost: parseFloat(
                selectedQuoteItem.quote_items_user[i].total_cost
              ),
              payment: selectedPayment[0],
              user_type: selectedUserType[0],
              user: selectedUser[0],
              uomObject: selectedUOM[0],
              error: [],
            });
          }
          dispatchQuoteItemUser({
            type: "SET_QUOTE_ITEM_USERS",
            quoteItemUsers: quoteItemsUsersTemp,
          });
          setUpdateCost(true);
        }

        setIsloadingCategories(false);
      } catch (e) {
        logException(e, "Cannot load categories data");
      }
    };
    if (!categories) {
      loadCategories();
    }
  }, [categories]);

  useEffect(
    () => {
      let total = 0;
      for (let i = 0; i < quoteItemUsers.length; i++) {
        if (quoteItemUsers[i].payment_type !== 3)
          total += quoteItemUsers[i].total_cost;
      }
      const newData = {
        ...data,
        total_cost: Math.round(total * 100) / 100,
      };
      setData(newData);
      if (
        selectedQuoteItem &&
        parseFloat(selectedQuoteItem.total_cost) !== total &&
        updateCost
      ) {
        setUpdatedData({ ...updatedData, total_cost: total });
      }
    },
    [JSON.stringify(quoteItemUsers)],
    data
  );

  const changeStep = async (n) => {
    if (n === 0) {
      setSchema(quoteItemStep0Schema);
      setStep(n);
    } else if (n === 3) {
      let hasErrors = false;
      for (let i = 0; i < quoteItemUsers.length; i++) {
        const errorsUser = validate(
          quoteItemUserSchema,
          quoteItemUsers[i],
          setErrorUsers
        );
        if (Boolean(errorsUser)) {
          hasErrors = true;
        } else if (
          quoteItemUsers[i].payment_type === 0 &&
          quoteItemUsers[i].total_cost === 0
        ) {
          quoteItemUsers[i].error = [
            {
              key: "payment",
              message: "Up Front Payment should be greater than 0.",
            },
          ];
          dispatchQuoteItemUser({
            type: "SET_QUOTE_ITEM_USERS",
            quoteItemUsers: quoteItemUsers,
          });
          hasErrors = true;
        }
      }
      if (!hasErrors) {
        setStep(n);
      }
    } else {
      const errors = validate(schema, data, setError);
      if (!Boolean(errors) || n < step || readOnlyGlobal) {
        if (n === 1) {
          let nologicErrors = true;
          if (quoteDetails.status === 5 && step === 0 && selectedQuoteItem) {
            const validationObject = {};
            if (
              updatedData.total &&
              updatedData.total !== selectedQuoteItem.total
            ) {
              validationObject.total = updatedData.total;
            }
            if (
              updatedData.billing_type &&
              updatedData.billing_type !== selectedQuoteItem.billing_type
            ) {
              validationObject.billing_type = updatedData.billing_type;
            }
            validationObject.id = selectedQuoteItem.id;
            const result = await validateUpdateQuoteItem(quoteDetails.id, {
              quote_item: validationObject,
            });
            if (result?.data?.data?.errors) {
              const { errors: valErrors } = result.data.data;
              const errorsToShow = [];
              Object.keys(valErrors).map((key, index) => {
                nologicErrors = false;
                errorsToShow.push({ key, message: valErrors[key] });
              });
              setError(errorsToShow);
            }
          }
          if (nologicErrors) {
            setSchema(quoteItemStep1Schema);
            setStep(n);
          }
        }
        if (n === 2) {
          setSchema({});
          setStep(n);
        }
      }
    }
  };

  const handleRemoveDisplay = (id) => {
    const filesDisplayRemove = data.filesDisplay.filter(
      (file) => file.id !== id
    );
    const deletedFile = data.filesDisplay.filter((file) => file.id === id);
    const newData = {
      ...data,
      filesDisplay: filesDisplayRemove,
    };
    setData(newData);
    setDeletedFiles([...deletedFiles, deletedFile[0].id]);
  };

  const handleChangeCategory = (event, value) => {
    const newData = {
      ...data,
      category: value,
      categoryId: value.value,
    };
    setData(newData);
    if (selectedQuoteItem) {
      setUpdatedData({
        ...updatedData,
        categoryId: value ? value.value : null,
      });
    }
    validateProperty("category", value, schema, error, setError);
  };

  const handleChangeBilling = (event, value) => {
    const newData = {
      ...data,
      billing: value,
      billing_type: value ? value.value : null,
    };
    setData(newData);
    if (selectedQuoteItem) {
      setUpdatedData({
        ...updatedData,
        billing_type: value ? value.value : null,
      });
    }
    validateProperty("billing", value, schema, error, setError);
  };

  const handleBlur = (event, value) => {
    let newData = {};
    let updateData = {};
    let total = 0;
    switch (event.name) {
      case "quantity":
        total =
          quoteDetails.type !== 1
            ? Math.round(
                parseFloat(data.price) * parseFloat(event.value) * 100
              ) / 100
            : 0;
        const hours = parseFloat(event.value);

        newData = {
          ...data,
          quantity: parseFloat(event.value),
          total,
          hours,
        };
        if (selectedQuoteItem) {
          updateData = {
            ...updatedData,
            quantity: event.value,
            total,
            hours,
          };
        }
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      case "price":
        total =
          quoteDetails.type !== 1
            ? Math.round(
                parseFloat(data.quantity) * parseFloat(event.value) * 100
              ) / 100
            : 0;
        newData = {
          ...data,
          price: event.value,
          total,
        };
        if (selectedQuoteItem) {
          updateData = {
            ...updatedData,
            price: event.value,
            total,
          };
        }
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      case "description":
        newData = {
          ...data,
          description: event.value,
        };
        if (selectedQuoteItem) {
          updateData = {
            ...updatedData,
            description: event.value,
          };
        }
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      case "item_subject":
        newData = {
          ...data,
          item_subject: event.value,
        };
        if (selectedQuoteItem) {
          updateData = {
            ...updatedData,
            item_subject: event.value,
          };
        }
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      case "summary":
        newData = {
          ...data,
          summary: event.value,
        };
        if (selectedQuoteItem) {
          updateData = {
            ...updatedData,
            summary: event.value,
          };
        }
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      default:
        newData = {
          ...data,
        };
        if (selectedQuoteItem) {
          updateData = {
            ...updatedData,
          };
        }
        break;
    }
    setData(newData);
    if (selectedQuoteItem) {
      setUpdatedData(updateData);
    }
  };

  const handleSaveTask = (item) => {
    const newTask = {
      ...item,
      id: data.task_list.length
        ? data.task_list[data.task_list.length - 1].id + 1
        : 1,
      isNew: true,
    };
    const newData = {
      ...data,
      task_list: [...data.task_list, newTask],
    };
    if (selectedQuoteItem) {
      setNewTasks([...newTasks, newTask]);
    }
    setData(newData);
  };

  const handleDeleteTask = (taskId) => {
    const selTask = data.task_list.filter((task) => task.id === taskId);
    const newData = {
      ...data,
      task_list: data.task_list.filter((task) => task.id !== taskId),
    };
    if (selectedQuoteItem) {
      if (selTask[0].isNew) {
        setNewTasks(newTasks.filter((task) => task.id !== taskId));
      } else {
        setDeletedTasks([...deletedTasks, taskId]);
      }
    }
    setData(newData);
  };

  const handleRemove = (indexInput) => {
    const newFileList = data.files.filter(
      (item, index) => index !== indexInput
    );
    setFiles(newFileList);
  };

  const setFiles = (files) => {
    const newData = {
      ...data,
      files,
    };
    setData(newData);
  };

  const handleChangeUserType = (event, value) => {
    if (value && !userType) {
      dispatchQuoteItemUser({
        type: "SET_QUOTE_ITEM_USERS",
        quoteItemUsers: [
          { ...initialDataUser, type: value.value, user_type: value },
          ...quoteItemUsers,
        ],
      });
    } else if (!value) {
      dispatchQuoteItemUser({
        type: "SET_QUOTE_ITEM_USERS",
        quoteItemUsers: quoteItemUsers.slice(1, quoteItemUsers.length),
      });
    } else {
      quoteItemUsers[0].type = value.value;
      quoteItemUsers[0].user_type = value;
      quoteItemUsers[0].user = null;
      quoteItemUsers[0].user_id = -1;
      quoteItemUsers[0].unit_cost = 0;
      quoteItemUsers[0].total_cost = 0;
      dispatchQuoteItemUser({
        type: "SET_QUOTE_ITEM_USERS",
        quoteItemUsers: quoteItemUsers,
      });
    }
    setUserType(value);
  };

  const addCost = () => {
    if (quoteItemUsers.length > 0) {
      const errors = validate(
        quoteItemUserSchema,
        quoteItemUsers[0],
        setErrorUsers
      );
      if (!Boolean(errors)) {
        if (userType) {
          dispatchQuoteItemUser({
            type: "SET_QUOTE_ITEM_USERS",
            quoteItemUsers: [
              { ...initialDataUser, type: userType.value, user_type: userType },
              ...quoteItemUsers,
            ],
          });
        } else {
          dispatchQuoteItemUser({
            type: "SET_QUOTE_ITEM_USERS",
            quoteItemUsers: [
              { ...initialDataUser, type: null, user_type: null },
              ...quoteItemUsers,
            ],
          });
        }
      }
    }
  };

  const setErrorUsers = (error) => {
    quoteItemUsers[0].error = error;
    dispatchQuoteItemUser({
      type: "SET_QUOTE_ITEM_USERS",
      quoteItemUsers: quoteItemUsers,
    });
  };

  const handleComplete = async () => {
    try {
      if (selectedQuoteItem) {
        const dataUpdate = {
          ...updatedData,
          id: selectedQuoteItem.id,
          deletedTasks,
          newTasks,
          deletedFiles,
          newFiles: data.files,
          deletedQuoteItemUsers,
          newQuoteItemUsers: quoteItemUsers.filter((item) => item.isNew),
          changedQuoteItemUsers,
        };
        setOpenMessage(true);
        setIsloadingMessage(true);
        await updateQuoteItems(
          dispatchQuote,
          quoteDetails.id,
          dataUpdate,
          fromMainQuote
        );
        setIsloadingMessage(false);
        setMessage("This item has been updated.");
      } else {
        const dataInsert = {
          ...data,
          is_editable: true,
          is_deletable: true,
          customer_id: quoteDetails.customer_id,
          quote_items_user: quoteItemUsers,
        };
        setOpenMessage(true);
        setIsloadingMessage(true);
        await addQuoteItems(dispatchQuote, quoteDetails.id, dataInsert, true);
        setIsloadingMessage(false);
        setMessage("This item has been added.");
      }
    } catch (e) {
      logException(e, "Cannot create quote service item");
    }
  };

  const closeMessage = () => {
    setOpenMessage(false);
    dispatchQuoteItemDrawer({
      type: "SET_IS_OPEN",
      isOpen: false,
    });
  };

  const previewWorktickets = async () => {
    try {
      const dataInsert = {
        ...data,
        is_editable: true,
        is_deletable: true,
        customer_id: quoteDetails.customer_id,
        hasBeenEdited: selectedQuoteItem ? true : false,
        quote_items_user: selectedQuoteItem
          ? quoteItemUsers.filter((item) => item.isNew)
          : quoteItemUsers,
      };
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
      dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
      setIsloadingPreview(true);
      setOpenPreview(true);
      const response = await addQuoteItemPreview(quoteDetails.id, {
        quote_items: [dataInsert],
      });
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: response.data.data.preview,
      });
      setIsloadingPreview(false);
    } catch (e) {
      logException(e, "Cannot generate preview");
    }
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  return (
    <>
      <PreviewDialog
        open={openPreview}
        handleClose={handleClosePreview}
        isLoading={isLoadingPreview}
      ></PreviewDialog>
      <MessageDialog
        title={"Success!"}
        open={openMessage}
        isLoading={isloadingMessage}
        handleClose={closeMessage}
        message={message}
      />
      <Box className={classes.currentStepContainer}>
        <Typography className={classes.currentStep}>
          Step {step + 1}/4
        </Typography>
      </Box>
      {step === 0 && (
        <>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              {`${selectedQuoteItem ? "Update" : "Add New"} Custom Item`}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              {quoteDetails?.type !== 1
                ? "Input details on what you will charge to the service."
                : "Input details on the service you are providing."}
            </Typography>
          </Box>
          <Box className={classes.optionWrapperQuoteItem}>
            <Box className={classes.containerInformationCreate}>
              {isLoadingCategories ? (
                <LinearProgress color="secondary" />
              ) : (
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={categories}
                  name="category"
                  internal={false}
                  error={error}
                  handleChange={handleChangeCategory}
                  label="Service Category"
                  value={data.category}
                  readonly={readOnlyGlobal}
                />
              )}
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="item_subject"
                label="Subject"
                error={error}
                internal={false}
                value={data.item_subject !== null ? data.item_subject : ""}
                handleBlur={handleBlur}
                readonly={readOnlyGlobal}
              />
              {quoteDetails.type !== 1 ? (
                <FormInput
                  gridSizes={[{ size: "md", val: 6 }]}
                  name="quantity"
                  label="Quantity"
                  error={error}
                  internal={false}
                  value={data.quantity !== null ? data.quantity : ""}
                  handleBlur={handleBlur}
                  endText={data.uom}
                  readonly={readOnlyGlobal}
                />
              ) : null}
              {quoteDetails.type !== 1 ? (
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="price"
                  label="Price"
                  startAdornment="$"
                  alignText="right"
                  error={error}
                  internal={false}
                  value={data.price !== null ? data.price : ""}
                  handleBlur={handleBlur}
                  readonly={readOnlyGlobal}
                />
              ) : (
                ""
              )}
              <FormTotal
                name="total"
                error={error}
                label="Total Price"
                subLabel="(Quantity X Price)"
                value={`$ ${formatNumber(parseFloat(data.total).toFixed(2))}`}
                icon={
                  <MonetizationOnIcon
                    className={
                      quoteDetails.type === 1
                        ? classes.informationIconContract
                        : classes.informationIcon
                    }
                  />
                }
                text={
                  quoteDetails.type === 1
                    ? "This is a project with no billing"
                    : "What you are charging for the service"
                }
              />
              {quoteDetails.type !== 1 ? (
                quoteDetails.status === 5 && selectedQuoteItem ? (
                  <Tooltip title="This field can't be changed after quote has been approved.">
                    <Box>
                      <FormSelectAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={billingOptions}
                        name="billing"
                        internal={false}
                        error={error}
                        readonly={true}
                        label="Billing Options"
                        value={data.billing}
                      />
                    </Box>
                  </Tooltip>
                ) : (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={billingOptions}
                    name="billing"
                    internal={false}
                    error={error}
                    handleChange={handleChangeBilling}
                    label="Billing Options"
                    value={data.billing}
                    readonly={readOnlyGlobal}
                  />
                )
              ) : (
                ""
              )}
            </Box>
            <Box className={classes.actionBoxQuoteItem}>
              <ButtonBase color="secondary" onClick={() => changeStep(1)}>
                Next
              </ButtonBase>
            </Box>
          </Box>
        </>
      )}
      {step === 1 && (
        <>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              {`${selectedQuoteItem ? "Update" : "Input"} Custom Item Details`}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              This will be added into the associated worktickets.
            </Typography>
          </Box>
          <Box className={classes.optionWrapperQuoteItem}>
            <Box className={classes.containerInformationCreate}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="summary"
                label="Service Summary"
                error={error}
                internal={false}
                value={data.summary}
                handleBlur={handleBlur}
                multiline={true}
                rows={2}
                readonly={
                  readOnlyGlobal ||
                  (quoteDetails.status === 5 && selectedQuoteItem)
                }
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="description"
                label="Service Description"
                error={error}
                internal={false}
                value={data.description}
                handleBlur={handleBlur}
                multiline={true}
                rows={4}
                readonly={
                  readOnlyGlobal ||
                  (quoteDetails.status === 5 && selectedQuoteItem)
                }
              />
              <QuoteItemTask
                taskList={data.task_list}
                handleSaveTask={handleSaveTask}
                handleDelete={handleDeleteTask}
                readonly={
                  readOnlyGlobal ||
                  (quoteDetails.status === 5 && selectedQuoteItem)
                }
              />
              {!readOnlyGlobal &&
              (quoteDetails.status !== 5 || !selectedQuoteItem) ? (
                <Box className={classes.containerFile}>
                  {!Boolean(data.files.length) && (
                    <Typography
                      className={classes.titleSectionFile}
                      gutterBottom
                    >
                      Documents
                    </Typography>
                  )}
                  <FileUpload
                    value={data.files}
                    handleChange={setFiles}
                    handleUpdate={setUpdate}
                    filesDisplay={
                      Boolean(update) && (
                        <FilesDisplay
                          files={data.files}
                          handleRemove={handleRemove}
                        />
                      )
                    }
                  />
                </Box>
              ) : (
                <Typography className={classes.titleSectionFile} gutterBottom>
                  Documents
                </Typography>
              )}
              <Box className={classes.containerDisplayFilesQS}>
                <FilesDisplayOnlyUploaded
                  files={data.filesDisplay}
                  handleRemoveDisplay={
                    !readOnlyGlobal &&
                    (quoteDetails.status !== 5 || !selectedQuoteItem)
                      ? handleRemoveDisplay
                      : () => {}
                  }
                />
              </Box>
              <Box className={classes.actionBoxQuoteItem2}>
                <ButtonBase
                  color="secondary"
                  startIcon={<BackIcon />}
                  variant="text"
                  onClick={() => changeStep(0)}
                  className={classes.buttonBackDrawer}
                >
                  Back
                </ButtonBase>
                <ButtonBase color="secondary" onClick={() => changeStep(2)}>
                  Next
                </ButtonBase>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {step === 2 && (
        <>
          <div className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              {`${selectedQuoteItem ? "Update" : "Add New"} Custom Item`}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              Estimate your costs for this service
            </Typography>
          </div>
          <Box className={classes.optionWrapperQuoteItem}>
            <Box className={classes.containerInformationCreate}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={userAssignmentType}
                name="userType"
                internal={false}
                handleChange={handleChangeUserType}
                label="Who is performing the service"
                value={userType}
                readonly={readOnlyGlobal}
              />
              {quoteItemUsers.length > 0 && userType ? (
                <>
                  <QuoteUserItem
                    hideUserType={true}
                    quoteItemUserSchema={quoteItemUserSchema}
                    data={quoteItemUsers[0]}
                    quoteApproved={quoteDetails.status === 5 ? true : false}
                    index={0}
                    options={quoteItemUsers[0].type === 0 ? users : subs}
                    hours={data.hours}
                    readonly={readOnlyGlobal}
                    fromCustom={true}
                  />
                </>
              ) : (
                ""
              )}
              {!readOnlyGlobal ? (
                <Typography
                  gutterBottom
                  className={classes.titleAddCost}
                  onClick={addCost}
                >
                  <AddIcon className={classes.iconMultiCost} /> Add Cost
                </Typography>
              ) : (
                ""
              )}
              {quoteItemUsers.map((quoteItemUser, index) => {
                return index > 0 || !userType ? (
                  <QuoteUserItem
                    key={`quoteItemUser-${index}`}
                    quoteItemUserSchema={quoteItemUserSchema}
                    data={quoteItemUser}
                    quoteApproved={
                      parseInt(quoteDetails.status) === 5 ? true : false
                    }
                    index={index}
                    options={parseInt(quoteItemUser.type) === 0 ? users : subs}
                    hours={data.hours}
                    isDetail={true}
                    readonly={readOnlyGlobal}
                    fromCustom={true}
                  />
                ) : (
                  ""
                );
              })}
              <FormTotal
                label="Total Cost"
                subLabel="(Quantity X Cost)"
                value={`$ ${formatNumber(
                  parseFloat(data.total_cost).toFixed(2)
                )}`}
                icon={<WarningIcon className={classes.warningIcon} />}
                text={"Your estimated costs for the service."}
              />
              <Box className={classes.actionBoxQuoteItem2}>
                <ButtonBase
                  color="secondary"
                  startIcon={<BackIcon />}
                  variant="text"
                  onClick={() => changeStep(1)}
                  className={classes.buttonBackDrawer}
                >
                  Back
                </ButtonBase>
                {!quoteDetails.is_old_structure ? (
                  <ButtonBase color="secondary" onClick={() => changeStep(3)}>
                    View Summary
                  </ButtonBase>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
      {step === 3 && (
        <>
          <div className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              {`${selectedQuoteItem ? "Update" : "Add New"} Custom Item`}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              Insert information about the service you are adding
            </Typography>
          </div>
          <Box className={classes.optionWrapperQuoteItem}>
            <Box className={classes.containerInformationCreate}>
              {quoteDetails.type !== 1 ? (
                <FormTotal
                  label="Total Price"
                  subLabel="(Quantity X Price)"
                  variant={2}
                  value={`$ ${formatNumber(parseFloat(data.total).toFixed(2))}`}
                  icon={
                    <MonetizationOnIcon className={classes.informationIcon} />
                  }
                  text={"What you are charging for the service"}
                />
              ) : (
                ""
              )}
              <FormTotal
                label="Total Cost"
                subLabel="(Quantity X Cost)"
                variant={2}
                value={`$ ${formatNumber(
                  parseFloat(data.total_cost).toFixed(2)
                )}`}
                icon={<WarningIcon className={classes.warningIcon} />}
                text={"Estimated costs for the service"}
              />
              {quoteDetails.type !== 1 ? (
                <FormTotal
                  label="Profit"
                  subLabel="(Price - Cost)"
                  value={`$ ${formatNumber(
                    parseFloat(data.total - data.total_cost).toFixed(2)
                  )}`}
                  icon={<CheckCircleIcon className={classes.ckeckIcon} />}
                  text={"Estimated profit for the service"}
                />
              ) : (
                ""
              )}
              <Box className={classes.previewActionBoxQuoteItem}>
                <ButtonBase
                  startIcon={<WorkticketIcon />}
                  variant="outlined"
                  onClick={() => {
                    previewWorktickets();
                  }}
                  className={classes.buttonPreviewDrawer}
                  disabled={readOnlyGlobal}
                >
                  Preview Worktickets
                </ButtonBase>
              </Box>
              <Box className={classes.actionBoxQuoteItem2}>
                <ButtonBase
                  color="secondary"
                  startIcon={<BackIcon />}
                  variant="text"
                  onClick={() => changeStep(2)}
                  className={classes.buttonBackDrawer}
                >
                  Back
                </ButtonBase>
                <ButtonBase
                  color="secondary"
                  onClick={handleComplete}
                  disabled={readOnlyGlobal}
                >
                  {selectedQuoteItem ? "Update Item" : "Add Item"}
                </ButtonBase>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default withTableContext(QuoteItemCustom);
