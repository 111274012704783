import React, { useState, useEffect } from "react";
import * as classNames from "classnames";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { default as useStyles } from "./styles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LISTBOX_PADDING = 8;

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
      lineHeight: "120%",
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="div"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const SelectChipsAuto = (props) => {
  const classes = useStyles();
  const {
    name,
    label,
    value,
    defaultValue,
    options,
    multiple,
    error,
    internal,
    readonly,
    gridSizes,
    handleChange,
    limitTags,
    onClose,
    noMargin,
  } = props;
  const [gridSizeMd, setGridSizeMd] = useState(8);
  const [gridSizeXs] = useState(12);
  let errorInput = undefined;
  if (error && error.length > 0) {
    errorInput = error.filter((item) => item.key === name);
  }

  useEffect(() => {
    if (gridSizes) {
      gridSizes.map((sizes) => {
        switch (sizes.size) {
          case "md":
            return setGridSizeMd(sizes.val);
          default:
            return 12;
        }
      });
    }
  }, [gridSizes]);

  return (
    <div className={noMargin ? classes.noMargin : classes.inputContainer}>
      <Grid container spacing={1}>
        {label ? (
          <Grid item xs={12} md={12}>
            <InputLabel
              htmlFor={name}
              className={
                internal ? classes.inputInternalLabel : classes.inputLabel
              }
            >
              {label}
            </InputLabel>
          </Grid>
        ) : null}
        <Grid item xs={gridSizeXs} md={gridSizeMd}>
          {multiple ? (
            <Autocomplete
              multiple={multiple ? true : false}
              id={name}
              options={options}
              defaultValue={defaultValue ? defaultValue : ""}
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name} ${
                  option.classification_id === 15 ? `(sub)` : ``
                }`
              }
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 3 }}
                    checked={selected}
                  />
                  {`${option.first_name} ${option.last_name} ${
                    option.classification_id === 15 ? `(sub)` : ``
                  }`}
                </React.Fragment>
              )}
              className={classNames(classes.inputAutoField)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  id={name}
                  fullWidth={true}
                  className={classNames(classes.inputField)}
                />
              )}
              renderTags={(value, getTagProps) => {
                const returnData = value.map((option, index) => {
                  const propsChip = getTagProps.onDelete
                    ? getTagProps
                    : getTagProps({ index: index });
                  return (
                    <Chip
                      disabled={false}
                      key={index}
                      data-tag-index={index}
                      tabIndex={-1}
                      {...propsChip}
                      avatar={<Avatar>{option.first_name[0]}</Avatar>}
                      label={`${option.first_name} ${option.last_name}`}
                      className={classes.chipAuto}
                    />
                  );
                });

                return returnData;
              }}
              onChange={handleChange}
              disabled={readonly ? readonly : false}
              value={value ? value : null}
              limitTags={limitTags ?? 3}
              disableCloseOnSelect
              onClose={onClose}
              ListboxComponent={ListboxComponent}
              renderGroup={renderGroup}
            />
          ) : (
            <Autocomplete
              multiple={multiple ? true : false}
              id={name}
              options={options}
              defaultValue={defaultValue ? defaultValue : ""}
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name} ${
                  option.classification_id === 15 ? `(sub)` : ``
                }`
              }
              className={classNames(classes.inputAutoField)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  id={name}
                  fullWidth={true}
                  className={classNames(classes.inputField)}
                />
              )}
              renderTags={(value, getTagProps) => {
                const returnData = value.map((option, index) => {
                  const propsChip = getTagProps.onDelete
                    ? getTagProps
                    : getTagProps({ index: index });
                  return (
                    <Chip
                      disabled={false}
                      key={index}
                      data-tag-index={index}
                      tabIndex={-1}
                      {...propsChip}
                      avatar={<Avatar>{option.first_name[0]}</Avatar>}
                      label={`${option.first_name} ${option.last_name}`}
                      className={classes.chipAuto}
                    />
                  );
                });

                return returnData;
              }}
              onChange={handleChange}
              disabled={readonly ? readonly : false}
              value={value ? value : null}
              onClose={onClose}
              ListboxComponent={ListboxComponent}
              renderGroup={renderGroup}
            />
          )}
          {errorInput && Boolean(errorInput.length) && (
            <FormHelperText error>{errorInput[0].message}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectChipsAuto;
