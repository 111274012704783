import React, { useState, useEffect, useCallback } from "react";
import { withRouter, Link } from "react-router-dom";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import Counter from "components/common/TableList/counter";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import AccountReceivableDownload from "./accountReceivableDownload";
import LinearProgress from "@material-ui/core/LinearProgress";
import Workticket from "components/ui/Financial/Workticket";
import Note from "components/ui/Financial/Note";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import AccountReceivableFilters from "./accountReceivableFilters";
import AccountReceivableBulkActions from "./accountReceivableBulkActions";
import CalendarFilters from "components/common/Filters/calendarFilters";
import WorkticketSoloStatusChip from "components/ui/Worktickets/WorkticketSoloStatusChip";
import AccountReceivableListActions from "./accountReceivableListActions";
import { cloneDeep } from "lodash";
import { billingAPTabOptions } from "constants.js";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useBillingState,
  useBillingDispatch,
  getBillingList,
  getBillingExportList,
  searchBilling,
  getBillingListDataSet,
  getBillingExportListDataSet,
} from "contexts/billingArContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import { VariousTextComponent } from "../reportAccountPayableWorkticketsInternal/VariousTextComponent";
import { dateFormatField } from "../../components/util/timeFormat";
import { ChatBubbleSolid } from "../reportAccountPayableWorkticketsInternal/icons/ChatBubbleSolid";
import { CommentOutline } from "../reportAccountPayableWorkticketsInternal/icons/CommentOutline";
import { WorkticketMenuActions } from "../reportAccountPayableWorkticketsInternal/WorkticketMenuActions";
import { useAddCommentModal } from "../reportAccountPayableWorkticketsInternal/context/AddCommentDialogueContext";
import { AddDiscountDialogue } from "../reportAccountPayableWorkticketsInternal/AddDiscountDialogue";
import { AddWorkticketCommentDialogue } from "../reportAccountPayableWorkticketsInternal/AddCommentDialogue";
import { useWorkticketModal } from "../reportAccountPayableWorkticketsInternal/context/WorkticketModalContext";
import { useAddDiscountModal } from "../reportAccountPayableWorkticketsInternal/context/AddDiscountDialogueContext";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import { DialogMessage } from "components/ui/core/dialogMessage";
import AlertIcon from "@material-ui/icons/NewReleases";
import { financialBillExport } from "services/financialService";
import { ButtonBase } from "components/ui/core/button";
import { logException } from "components/util/logUtil";

const columnsExport = [
  {
    id: "id",
    label: "ID",
    format: "id",
    checkbox: {
      active: (row) => false,
    },
  },
  {
    id: "file_name",
    label: "File Name",
  },
  {
    id: "created_at",
    label: "Date",
    format: "date",
  },
  {
    id: "created_by",
    label: "Created By",
    content: (row) =>
      `${row.export_file[0].user.first_name} ${row.export_file[0].user.last_name}`,
    disableSort: true,
  },
  {
    id: "file",
    label: "",
    content: (row) => <AccountReceivableDownload fileId={row.id} />,
    style: { width: 150 },
    disableSort: true,
  },
];

const SEARCH_INDEX = "9999";
const FILE_INDEX = "8888";

const AccountReceivableTable = (props) => {
  const [commentAction, dispatchCommentAction] = useAddCommentModal();
  const [workticketModalAction, dispatchWorkticketModal] = useWorkticketModal();
  const { isWorkticketModalOpen } = workticketModalAction;
  const [discountAction, dispatchDiscountAction] = useAddDiscountModal();
  const { isOpenAddDialog } = discountAction;
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { billingListLoading, billingList, billingCount } = useBillingState();
  const { hasRowsClickable, selected, currentTab } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchBilling = useBillingDispatch();
  const dispatchFilters = useFilterDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classesDialog = useStyles();

  const {
    filters,
    startDateActive,
    endDateActive,
    startDate,
    endDate,
    breakdown,
  } = useFilterState();

  useEffect(() => {
    const moduleName = window.location.pathname.split("/")[1];
    if (localStorage.getItem(`filter_${moduleName}`)) {
      dispatchFilters({
        type: "SET_FILTERS_CORE",
        filters: JSON.parse(
          localStorage.getItem(
            `filter_${window.location.pathname.split("/")[1]}`
          )
        ),
      });
    }
    if (localStorage.getItem(`filter_date_${moduleName}`)) {
      const dataDate = JSON.parse(
        localStorage.getItem(`filter_date_${moduleName}`)
      );
      dispatchFilters({
        type: "SET_DATE",
        ...dataDate,
      });
    }
    dispatchFilters({
      type: "SET_LOADING",
      isLoadingFilters: false,
    });
  }, [dispatchFilters]);

  useEffect(() => {
    const fetchData = async () => {
      dispatchBilling({
        type: "SET_LOADING",
        billingListLoading: true,
      });
      const filterData = getFilterData(filterState);
      const local_tab = localStorage.getItem("local_billing_tab");

      if (filterData.dateFilters.startDateActive && local_tab === "0") {
        const filterDataAddon = cloneDeep(filterData);
        filterDataAddon.filters.push({
          filter: "date_column",
          group: "Date Filter",
          values: [{ label: "Due Date", value: "quote_due_date" }],
        });
        await getBillingList(dispatchBilling, filterDataAddon);
      } else if (local_tab === FILE_INDEX) {
        await getBillingExportList(dispatchBilling, []);
      } else {
        filterData.filters = filterData.filters.filter(
          (item) => item.filter !== "date_column"
        );
        await getBillingList(dispatchBilling, filterData);
      }
    };
    if (filterState.searchString === "" && !filterState.isLoadingFilters) {
      fetchData();
    }
  }, [dispatchBilling, filterState]);

  useEffect(() => {
    if (currentTab !== FILE_INDEX) {
      dispatchTable({ type: "SET_SEARCH", hasSearch: true });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    } else {
      dispatchTable({ type: "SET_SEARCH", hasSearch: false });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columnsExport });
    }
    const billingData = billingList[currentTab] ?? [];
    dispatchTable({ type: "UPDATE_ROWS", rows: billingData });
  }, [dispatchTable, billingList, currentTab]);

  useEffect(() => {
    if (!billingListLoading) {
      const local_tab = localStorage.getItem("local_billing_tab");
      if (!local_tab) {
        dispatchTable({ type: "SET_CURRENT_TAB", currentTab: "1" });
        localStorage.setItem("local_billing_tab", "1");
        return;
      }
      dispatchTable({ type: "SET_CURRENT_TAB", currentTab: local_tab });
    }
  }, [dispatchTable, billingListLoading]);

  const toggleModalComment = (open, workticketId) => {
    dispatchCommentAction({
      type: "TOGGLE_COMMENT_DIALOG",
      open,
      workticketId,
    });
  };

  const handleTabChange = (event, newTab) => {
    const fetchData = async () => {
      if (currentTab === SEARCH_INDEX) {
        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: "",
        });
      }
      if (newTab !== FILE_INDEX) {
        dispatchTable({ type: "SET_SEARCH", hasSearch: true });
        dispatchBilling({
          type: "SET_LOADING",
          billingListLoading: true,
        });
        const filterData = getFilterData(filterState);

        if (filterData.dateFilters.startDateActive && newTab === "0") {
          const filterDataAddon = cloneDeep(filterData);
          filterDataAddon.filters.push({
            filter: "date_column",
            group: "Date Filter",
            values: [{ label: "Due Date", value: "quote_due_date" }],
          });
          await getBillingList(dispatchBilling, filterDataAddon);
        } else {
          await getBillingList(dispatchBilling, filterData);
        }
      } else if (newTab === FILE_INDEX) {
        dispatchTable({ type: "SET_SEARCH", hasSearch: false });
        await getBillingExportList(dispatchBilling, billingList);
      }
      localStorage.setItem("local_billing_tab", newTab);
      dispatchTable({ type: "SET_CURRENT_TAB", currentTab: newTab });
    };
    fetchData();
    setTimeout(() => {
      dispatchTable({
        type: "SET_TAB_UPDATED",
        tabUpdated: null,
      });
    }, 3000);
  };

  const handleModuleChange = (event, newTab) => {
    const { history } = props;
    if (newTab === "0") {
      history.push(`/financial/report/account-payable`);
    }
    if (newTab === "2") {
      history.push(`/financial/report/account-payable-internal-workticket`);
    }
  };

  const handleScrollClick = useCallback(async () => {
    const billingData = billingList[currentTab] ?? [];

    if (billingCount[currentTab] <= billingData.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);

      if (FILE_INDEX !== currentTab) {
        const filterData = getFilterData(filterState);
        if (filterData.dateFilters.startDateActive && currentTab === "0") {
          const filterDataAddon = cloneDeep(filterData);
          filterDataAddon.filters.push({
            filter: "date_column",
            group: "Date Filter",
            values: [{ label: "Due Date", value: "quote_due_date" }],
          });
          await getBillingListDataSet(
            billingList,
            billingData.length,
            dispatchBilling,
            currentTab,
            filterDataAddon
          );
        } else {
          await getBillingListDataSet(
            billingList,
            billingData.length,
            dispatchBilling,
            currentTab,
            filterData
          );
        }
      } else {
        await getBillingExportListDataSet(
          billingList,
          billingData.length,
          dispatchBilling,
          currentTab
        );
      }
      const billingDataUpdate = billingList[currentTab] ?? [];
      dispatchTable({ type: "UPDATE_ROWS", rows: billingDataUpdate });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    currentTab,
    billingList,
    billingCount,
    filterState,
    dispatchBilling,
    dispatchTable,
    loadingMore,
  ]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    const searchStatus = SEARCH_INDEX;
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          const results = await searchBilling(query, filterData);
          const totalResults = [...results[0].list, ...results[1].list];
          // Send result to search tab
          billingList[searchStatus] = totalResults;
          dispatchBilling({
            type: "SET_BILLING_LIST",
            billingList: { ...billingList },
          });

          billingCount[searchStatus] = results[0].count + results[1].count;
          dispatchBilling({
            type: "SET_BILLING_COUNT",
            billingCount: { ...billingCount },
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          dispatchTable({ type: "SET_CURRENT_TAB", currentTab: searchStatus });
          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
    const local_tab = localStorage.getItem("local_billing_tab");
    if (!local_tab) {
      dispatchTable({ type: "SET_CURRENT_TAB", currentTab: "1" });
      localStorage.setItem("local_billing_tab", "1");
      return;
    }
    dispatchTable({ type: "SET_CURRENT_TAB", currentTab: local_tab });
  };
  const columns = [
    {
      id: "id",
      label: "ID",
      format: "id",
      newId: "workticket_id",
      checkbox: {
        active: (row) => row.amount_due > 0,
        tooltip: (row) =>
          row.amount_due > 0 ? null : "Bill amount should be more than zero",
      },
    },
    {
      id: "source",
      label: "Source",
      format: "link",
      path: (row) =>
        row?.source?.startsWith("Q")
          ? `/quote/${row.source_id}`
          : row?.source?.startsWith("P")
          ? `/project/${row.source_id}`
          : "#",
      content: (row) => row?.source ?? "-",
      disableSort: true,
    },
    {
      id: "customer_name",
      label: "Customer",
    },
    {
      id: "job",
      label: "Job",
      content: (row) => `${row.job_number} - ${row.job_description}`,
      disableSort: true,
    },
    {
      id: "manager",
      label: "Manager",
      content: (row) => `${row.primary_manager ?? "-"}`,
      disableSort: true,
    },
    {
      id: "verified_date",
      label: "Verified Date",
      disableSort: true,
      content: (row) => (
        <>
          {row.number_worktickets > 1 && (
            <VariousTextComponent isAR={true} id={row.id} />
          )}
          {row.number_worktickets <= 1 &&
            (row.completed_date ? dateFormatField(row.completed_date) : `-`)}
        </>
      ),
    },
    {
      id: "workticket_number",
      label: "Workticket",
      content: (row) => {
        const wtArray = row.workticket_number?.split(",");
        // return (
        //   row.number_worktickets > 1 && (
        //     <VariousTextComponent isAR={true} id={row.id} />
        //   )
        // );
        const componentToShow =
          wtArray?.length === 1 ? (
            <Link
              to={`/workticket/${row.workticket_id}`}
              style={{ color: "#747474", textDecoration: "none" }}
            >
              {row.workticket_number}
            </Link>
          ) : (
            <Workticket
              worktickets={wtArray}
              workticketsId={row.workticket_id?.split(",")}
            />
          );
        const various = <VariousTextComponent isAR={true} id={row.id} />;
        return row.number_worktickets > 1 ? various : componentToShow;
      },
      disableSort: true,
    },
    { id: "gl_number", label: "GL" },
    {
      id: "status",
      label: "Status",
      content: (row) => (
        <>
          {row.number_worktickets > 1 && (
            <VariousTextComponent isAR={true} id={row.id} />
          )}
          {row?.number_worktickets <= 1 && row?.workticket_status !== null && (
            <WorkticketSoloStatusChip
              status={row?.workticket_status ?? ""}
              archived={0}
            />
          )}
          {row?.worticket_status === null && <Typography> </Typography>}
        </>
      ),
      disableSort: true,
    },
    {
      id: "work_due_date",
      label: "Due Date",
      content: (row) => (
        <>
          {row.number_worktickets > 1 && (
            <VariousTextComponent isAR={true} id={row.id} />
          )}
          {row.number_worktickets <= 1 &&
            (row.work_due_date ? dateFormatField(row.work_due_date) : `-`)}
        </>
      ),
    },
    {
      id: "completed_date",
      label: "Completed Date",
      // format: (row) => (row.number_worktickets < 1 ? "date" : null),
      content: (row) => (
        <>
          {row.number_worktickets > 1 && (
            <VariousTextComponent isAR={true} id={row.id} />
          )}
          {row.number_worktickets <= 1 &&
            (row.completed_date ? dateFormatField(row.completed_date) : `-`)}
        </>
      ),
    },
    {
      // TODO: Review
      id: "original_amount",
      label: "Bill",
      format: "money",
    },
    {
      id: "discount",
      label: "Discount",
      content: (row) => (
        <>
          {/*{row.number_worktickets > 1`-`}*/}
          {row.discount > 0 ? `${row.discount}%` : `-`}
        </>
      ),
    },
    {
      id: "amount_due",
      label: "After Discount",
      format: "money",
    },
    {
      id: "comments",
      label: "Comments",
      content: (row) => (
        <>
          {row.number_worktickets > 1 && <VariousTextComponent id={row.id} />}
          {row.number_worktickets <= 1 && (
            <Box
              onClick={() => {
                if (row.number_worktickets > 1) return;
                toggleModalComment(true, row.workticket_id);
              }}
              className={classes.boxChatBubble}
            >
              {row.has_comments > 0 ? <ChatBubbleSolid /> : <CommentOutline />}
            </Box>
          )}
        </>
      ),
    },
    {
      id: "notes",
      label: "Notes",
      content: (row) => (
        <>
          {row.number_worktickets > 1 && (
            <VariousTextComponent isAR={true} id={row.id} />
          )}
          {row.number_worktickets <= 1 && (
            <Note notes={row.notes} workticketId={row.workticket_id} />
          )}
        </>
      ),
      disableSort: true,
    },
    {
      label: "Actions",
      content: (row) => (
        <Box key={row.id} className={classes.actionContainer}>
          <WorkticketMenuActions
            isAR={true}
            row={row}
            selected={selected}
            discount={row.discount}
            number_worktickets={row.number_worktickets}
            isReady={true}
            filterState={getFilterData(filterState)}
            dispatchBilling={dispatchBilling}
          />
        </Box>
      ),
    },
  ];

  const handleClick = () => {
    setOpenConfirm(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
  };

  const handleExportConfirm = async () => {
    try {
      setIsLoading(true);
      const filterData = getFilterData(filterState);
      const result = await financialBillExport({ export_all: 1 }, filterData);
      window.open(result.data.data.file.presigned_url);
      dispatchTable({
        type: "SET_SELECTED",
        selected: [],
      });
      dispatchBilling({
        type: "SET_LOADING",
        billingListLoading: true,
      });

      await getBillingList(dispatchBilling, filterData);
      setOpenConfirm(false);
      setIsLoading(false);
    } catch (e) {
      logException(e, "Cannot export all file");
    }
  };

  return (
    <>
      <Box className={classes.root}>
        {billingListLoading || loadingSearch || currentTab === -1 ? (
          <LoadingStateHorizontal isVisible style={classes.centerLoading} />
        ) : (
          <>
            <Box className={classes.contentContainer}>
              <TableTabs handleTabChange={handleModuleChange} value="1">
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Account Payable</Box>}
                  key="0"
                  value="0"
                />
                <StyledTab
                  disableRipple={true}
                  label={
                    <Box className={classes.tabItem}>Account Receivable</Box>
                  }
                  key="1"
                  value="1"
                />
              </TableTabs>
              <Box className={classes.contentCounter}>
                <Counter>
                  {`${billingList[currentTab]?.length} / ${billingCount[currentTab]}`}
                </Counter>
              </Box>
              <TableTabs
                handleTabChange={handleTabChange}
                value={currentTab}
                className={classes.tabsSecondary}
              >
                {billingAPTabOptions
                  .filter((item) => item.onTabing)
                  .map((option) => {
                    return (
                      <StyledTab
                        label={
                          <Box className={classes.tabItem}>{option.text}</Box>
                        }
                        value={option.tab}
                        key={option.id}
                      />
                    );
                  })}
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Exported</Box>}
                  key={FILE_INDEX}
                  value={FILE_INDEX}
                />
                {Boolean(filterState.searchString) && (
                  <StyledTab
                    disableRipple={true}
                    label={<Box className={classes.tabItem}>Search</Box>}
                    key={SEARCH_INDEX}
                    value={SEARCH_INDEX}
                  />
                )}
              </TableTabs>
              <Box className={classes.rootContainer}>
                <Box className={classes.tableContainer}>
                  <Box className={classes.fullHeightTable}>
                    <Table
                      handleRowClick={handleRowClick}
                      newColumns={columns}
                      handleScrollClick={handleScrollClick}
                      activeMobile={true}
                    >
                      <EnhancedTableToolbar>
                        {selected.length > 0 ? (
                          <ToolbarSelected>
                            <AccountReceivableBulkActions />
                          </ToolbarSelected>
                        ) : (
                          <ToolbarDefault
                            exportAllowed={currentTab === "1"}
                            handleSearch={handleSearch}
                            handleSearchClear={handleSearchClear}
                            handleExportClick={handleClick}
                          >
                            {FILE_INDEX !== currentTab ? (
                              <>
                                <Box
                                  className={classes.filtersDateMainContainer}
                                >
                                  {/*<AccountReceivableListActions*/}
                                  {/*  tab={currentTab}*/}
                                  {/*/>*/}
                                  <Box
                                    className={
                                      classes.dateRangeAndCalendarPickerContainer
                                    }
                                  >
                                    <CalendarFilters
                                      newStyle={true}
                                      chipActive={startDateActive}
                                    />
                                    {startDateActive && (
                                      <Box
                                        className={classes.dateRangeContainer}
                                      >
                                        <Box>
                                          <Typography variant="body1">
                                            Date Range:
                                          </Typography>
                                        </Box>
                                        <Box>
                                          {moment(startDate).format("L")} -{" "}
                                          {moment(endDate).format("L")}
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                  <AccountReceivableFilters
                                    showDiscountFilter={true}
                                    filterCount={filterState?.filters?.length}
                                  />
                                </Box>
                              </>
                            ) : null}
                          </ToolbarDefault>
                        )}
                      </EnhancedTableToolbar>
                      {/*{FILE_INDEX !== currentTab && filterState.filters ? (*/}
                      {/*  <FiltersAppliedList />*/}
                      {/*) : null}*/}
                    </Table>
                  </Box>
                </Box>
              </Box>
              {loadingMore && (
                <Box className={classes.loadingTable}>
                  <LinearProgress color="secondary" />
                </Box>
              )}
            </Box>
          </>
        )}
        {isOpenAddDialog && (
          <AddDiscountDialogue
            columnsReady={columns}
            handleTabChange={handleTabChange}
            filterState={getFilterData(filterState)}
            dispatchBilling={dispatchBilling}
            isAR={true}
          />
        )}
        <AddWorkticketCommentDialogue
          columnsReady={columns}
          handleTabChange={handleTabChange}
          filterState={getFilterData(filterState)}
          dispatchBilling={dispatchBilling}
          isAR={true}
        />
      </Box>
      {openConfirm ? (
        <Dialog
          open={openConfirm}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          maxWidth={"xs"}
          fullWidth={true}
        >
          <DialogContent className={classesDialog.wrapperDialog}>
            {!isLoading ? (
              <DialogCloseIcon handleClose={handleConfirmClose} />
            ) : null}
            <DialogTitle
              title="Financial"
              icon={<AlertIcon fontSize="large" />}
            />
            {!isLoading ? (
              <>
                <DialogMessage align="center">
                  You are about to export ALL {billingCount["1"] ?? null}{" "}
                  records ready.
                </DialogMessage>
                <DialogMessage align="center">
                  <strong>This cannot be undone!</strong>
                </DialogMessage>
              </>
            ) : (
              <>
                <DialogMessage align="center">
                  <strong>Export in progress...</strong>
                </DialogMessage>
                <DialogMessage align="center">
                  Please wait until the export is completed. Don't close or
                  refresh the page.
                </DialogMessage>
              </>
            )}
          </DialogContent>
          <DialogActions className={classesDialog.wrapperDialogAction}>
            {!isLoading ? (
              <>
                <ButtonBase
                  variant="outlined"
                  color="secondary"
                  onClick={handleConfirmClose}
                >
                  Cancel
                </ButtonBase>
                <ButtonBase
                  color="secondary"
                  variant="contained"
                  onClick={handleExportConfirm}
                >
                  Okay
                </ButtonBase>
              </>
            ) : (
              <Box className={classesDialog.containerDialogLoader}>
                <LoadingStateHorizontal
                  isVisible
                  style={classesDialog.centerLoading}
                />
              </Box>
            )}
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default withRouter(AccountReceivableTable);
