import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import DescriptionIcon from "@material-ui/icons/Description";
import PhotoIcon from "@material-ui/icons/Photo";
import { filmIcon } from "./icons";
import { getThumbnailUrl, validateMP4Link } from "components/util/youtubeUtil";
import { mimeTypes } from "constants.js";
import classnames from "classnames";
import useStyles from "./styles";
import { getVideoFrame } from "services/lunaLensService";

const Thumbnail = ({ file, onClick, pinIcon, width, height }) => {
  const { mime_type, file_url, display_name, id } = file;
  const classes = useStyles();
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  useEffect(() => {
    const fetchThumbnail = async () => {
      if (
        parseInt(mime_type) === mimeTypes.LINK ||
        parseInt(mime_type) === mimeTypes.LINK_360
      ) {
        const url = await getThumbnailUrl(file_url, false);
        setThumbnailUrl(url);
      } else if (parseInt(mime_type) === mimeTypes.VIDEO) {
        const url = await getVideoFrame({ file_id: id });
        setThumbnailUrl(url?.data?.file_frame?.file_url);
      }
    };
    fetchThumbnail();
  }, [file_url, mime_type, id]);

  const thumbnailSize = {
    width: width,
    height: height,
  };

  const renderLinkThumbnail = () => {
    return (
      <Box>
        {validateMP4Link(file_url) ? (
          <Box
            className={classes.thumbnailVideo}
            onClick={onClick}
            style={thumbnailSize}
          >
            {thumbnailUrl ? (
              <>
                <img
                  className={classes.thumbnailDocument}
                  src={thumbnailUrl}
                  alt={display_name}
                  onClick={onClick}
                  style={thumbnailSize}
                />
                <Box
                  className={classnames(
                    classes.thumbnailIconContainer,
                    classes.thumbnailIconSize,
                    classes.backgroundBlue
                  )}
                  onClick={onClick}
                >
                  {filmIcon()}
                </Box>
              </>
            ) : (
              <Box
                className={classnames(
                  classes.thumbnailIconContainer,
                  classes.thumbnailIconSize,
                  classes.backgroundBlue
                )}
                onClick={onClick}
              >
                {filmIcon()}
              </Box>
            )}
          </Box>
        ) : (
          <Box className={classes.thumbnailContainerStyle}>
            <img
              className={classes.thumbnailDocument}
              src={thumbnailUrl}
              alt={display_name}
              onClick={onClick}
              style={thumbnailSize}
            />
            <Box
              className={classnames(
                classes.thumbnailIconContainer,
                classes.thumbnailIconSize,
                classes.backgroundRed
              )}
              onClick={onClick}
            >
              <PlayArrowIcon />
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const renderDocumentThumbnail = () => (
    <Box
      className={classes.thumbnailDocument}
      onClick={onClick}
      style={thumbnailSize}
    >
      <Box
        className={classnames(
          classes.thumbnailIconContainer,
          classes.thumbnailIconSize,
          classes.backgroundOrange
        )}
        onClick={onClick}
      >
        <DescriptionIcon />
      </Box>
    </Box>
  );

  const renderImageThumbnail = () => (
    <Box>
      <img
        className={classes.thumbnailDocument}
        src={file_url}
        alt={display_name}
        onClick={onClick}
        style={thumbnailSize}
      />
      <Box
        className={classnames(
          classes.thumbnailIconContainer,
          classes.thumbnailIconSize,
          classes.backgroundYellow
        )}
        onClick={onClick}
      >
        <PhotoIcon />
      </Box>
    </Box>
  );

  return (
    <Box className={classes.thumbnailContainerStyle}>
      {parseInt(mime_type) === mimeTypes.PDF && renderDocumentThumbnail()}
      {parseInt(mime_type) === mimeTypes.IMAGE && renderImageThumbnail()}
      {parseInt(mime_type) === mimeTypes.LINK && renderLinkThumbnail()}
      {parseInt(mime_type) === mimeTypes.LINK_360 && renderLinkThumbnail()}
      {parseInt(mime_type) === mimeTypes.VIDEO && renderLinkThumbnail()}
      {pinIcon && (
        <Box
          className={classnames(
            classes.pinIconImg,
            classes.pinIconImageMiniature
          )}
        >
          {pinIcon}
        </Box>
      )}
    </Box>
  );
};

export default Thumbnail;
