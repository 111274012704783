import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import QuoteIcon from "@material-ui/icons/AttachMoney";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import useStyles from "./styles";

const ConfirmDialog = (props) => {
  const classes = useStyles();

  const { open, handleConfirm, handleClose, message } = props;
  const [isConfirming, setIsConfirming] = useState(false);

  const handleConfirmClick = () => {
    setIsConfirming(true);
    handleConfirm();
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <QuoteIcon className={classes.iconDialog} />
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleDialog}
            >
              Billing
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentDialog}
            >
              {message}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleClose}
              className={classes.buttonOutlined}
              variant="outlined"
              size="large"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmClick}
              className={classes.button}
              color="primary"
              variant="contained"
              size="large"
              disabled={isConfirming}
            >
              Okay
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};
export default ConfirmDialog;
