import React from "react";
import * as classNames from "classnames";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import EmergencyIcon from "@material-ui/icons/NewReleases";
import Grid from "@material-ui/core/Grid";
import { truncateString } from "../../util/stringFormat";
import { default as useStyles } from "./styles";
import Typography from "@material-ui/core/Typography";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LISTBOX_PADDING = 8;

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
      lineHeight: "120%",
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="div"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const SelectAuto = (props) => {
  const classes = useStyles();
  const {
    name,
    label,
    value,
    defaultValue,
    options,
    multiple,
    error,
    internal,
    readonly,
    handleChange,
    inputContainer,
    labelError,
    endText,
    endTextSize,
    inputContainerProps,
    noLabel,
    hideLabel,
    limitTags,
    onClose,
    placeholder,
    noTruncate,
  } = props;

  let errorInput = undefined;
  if (error && error.length > 0) {
    errorInput = error.filter((item) => item.key === name);
  }

  return (
    <div
      className={
        inputContainerProps
          ? inputContainerProps
          : inputContainer
          ? classes.inputContainer2
          : classes.inputContainer
      }
    >
      <Grid container spacing={1}>
        {!noLabel && label ? (
          <Grid item xs={12} md={12}>
            <Typography
              htmlFor={name}
              style={hideLabel ? { display: "none" } : {}}
              className={
                internal ? classes.inputInternalLabel : classes.inputLabel
              }
            >
              {labelError ? (
                <EmergencyIcon className={classes.errorIcon} />
              ) : null}
              {label}
            </Typography>
          </Grid>
        ) : null}
        <Grid
          item
          xs={endText ? 12 - endTextSize : 12}
          md={endText ? 12 - endTextSize : 12}
        >
          {multiple ? (
            <Autocomplete
              multiple={true}
              id={name}
              options={options}
              defaultValue={defaultValue ?? ""}
              className={classNames(classes.inputAutoField)}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option) =>
                value?.find((vl) => vl.value === option.value)
              }
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 3 }}
                    checked={selected}
                  />
                  {noTruncate ? option.label : truncateString(option.label, 40)}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  id={name}
                  fullWidth={true}
                  className={classNames(classes.inputField)}
                  placeholder={placeholder}
                />
              )}
              onChange={handleChange}
              disabled={readonly ?? false}
              value={value ?? null}
              limitTags={limitTags ?? 3}
              disableCloseOnSelect
              onClose={onClose}
              ListboxComponent={ListboxComponent}
              renderGroup={renderGroup}
            />
          ) : (
            <Autocomplete
              id={name}
              options={options}
              defaultValue={defaultValue ?? ""}
              getOptionLabel={(option) => option.label}
              className={classNames(classes.inputAutoField)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  id={name}
                  fullWidth={true}
                  className={classNames(classes.inputField)}
                  placeholder={placeholder}
                />
              )}
              onChange={handleChange}
              disabled={readonly ?? false}
              value={value ?? null}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              renderOption={(option) => truncateString(option.label, 40)}
              onClose={onClose}
              ListboxComponent={ListboxComponent}
              renderGroup={renderGroup}
            />
          )}

          {errorInput && Boolean(errorInput.length) && (
            <FormHelperText error>{errorInput[0].message}</FormHelperText>
          )}
        </Grid>
        {endText ? (
          <Grid item xs={endTextSize} md={endTextSize}>
            {endText}
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default SelectAuto;
