import React from "react";
import {
  getFinancialAPSub,
  getFinancialAPSubSearch,
  getFinancialAPSubOffset,
} from "services/financialService";
import { merge } from "lodash";

const defaultState = {
  billingListLoading: true,
  billingSelected: null,
  billingList: [],
  billingCount: [],
  billingAfter: [],
  currentTab: -1,
  tabUpdated: null,
};

const BillingStateContext = React.createContext();
const BillingDispatchContext = React.createContext();

function billingReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        billingListLoading: action.billingListLoading,
      };
    case "SET_CURRENT_TAB":
      return {
        ...state,
        currentTab: action.currentTab,
        selected: [],
      };
    case "SET_TAB_UPDATED":
      return {
        ...state,
        tabUpdated: action.tabUpdated,
      };

    case "SET_BILLING_LIST":
      return {
        ...state,
        billingList: action.billingList,
      };
    case "SET_BILLING_COUNT":
      return {
        ...state,
        billingCount: action.billingCount,
      };
    case "SET_BILLING_AFTER":
      return {
        ...state,
        billingAfter: action.billingAfter,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function BillingProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(billingReducer, mergedState);
  return (
    <BillingStateContext.Provider value={state}>
      <BillingDispatchContext.Provider value={dispatch}>
        {children}
      </BillingDispatchContext.Provider>
    </BillingStateContext.Provider>
  );
}

function useBillingState() {
  const context = React.useContext(BillingStateContext);
  if (context === undefined) {
    throw new Error("useBillingState must be used within a BillingProvider");
  }
  return context;
}

function useBillingDispatch() {
  const context = React.useContext(BillingDispatchContext);
  if (context === undefined) {
    throw new Error("useBillingDispatch must be used within a BillingProvider");
  }
  return context;
}

async function getBillingList(dispatch, filterData) {
  try {
    //load
    dispatch({
      type: "SET_LOADING",
      billingListLoading: true,
    });
    const billingListResult = await getFinancialAPSub(filterData);

    //join both lists in index 4 and 1, they have same structure

    // const billingList = billingListResult.data.data.records[4].list;
    let billingList = [];
    // billingList[1] = billingListResult.data.data.records[4].list.concat(
    //   billingListResult.data.data.records[1].list
    // );
    billingList[2] = billingListResult.data.data.records[1].list;
    billingList[3] = billingListResult.data.data.records[4].list;

    dispatch({
      type: "SET_BILLING_LIST",
      billingList: billingList,
    });

    // const billingCount = billingListResult.data.data.records[4].count;
    let billingCount = [];
    billingCount[2] = billingListResult.data.data.records[1].count;
    billingCount[3] = billingListResult.data.data.records[4].count;

    dispatch({
      type: "SET_BILLING_COUNT",
      billingCount: billingCount,
    });

    // const billingAfter = billingListResult.data.data.records[4].search_after;
    const billingAfter =
      billingListResult.data.data.records[4].search_after ||
      billingListResult.data.data.records[1].search_after;
    dispatch({
      type: "SET_BILLING_AFTER",
      billingAfter,
    });

    dispatch({
      type: "SET_LOADING",
      billingListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getBillingListDataSet(
  billingList,
  billingOffset,
  dispatch,
  filterData,
  searchString,
  currentTab
) {
  try {
    const tabMapping = {
      2: 1,
      3: 4,
    };

    const result = await getFinancialAPSubOffset(
      tabMapping[currentTab],
      billingOffset,
      filterData,
      searchString
    );
    const res = currentTab === 2 ? 1 : 4;

    billingList[currentTab] = billingList[currentTab].concat(
      result.data.data.records[res].list
    );
    if (currentTab === 2) {
      billingList[1] = result.data.data.records[1].list;
    } else {
      billingList[4] = result.data.data.records[4].list;
    }
    dispatch({
      type: "SET_BILLING_LIST",
      billingList: billingList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchBilling(search, filterData) {
  try {
    const billingListResult = await getFinancialAPSubSearch(search, filterData);
    return billingListResult.data.data.records[4];
  } catch (error) {
    console.log(error);
  }
}

export {
  BillingProvider,
  useBillingState,
  useBillingDispatch,
  getBillingList,
  searchBilling,
  getBillingListDataSet,
};
