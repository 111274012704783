import React from "react";
import AccountReceivableTable from "./accountReceivableTable";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { BillingProvider } from "contexts/billingArContext";

import useStyles from "./styles";
import { AddCommentDialogProvider } from "../reportAccountPayableWorkticketsInternal/context/AddCommentDialogueContext";
import { AddDiscountDialogProvider } from "../reportAccountPayableWorkticketsInternal/context/AddDiscountDialogueContext";

const AccountReceivableList = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.pageBodyWrapper}>
        <BillingProvider>
          <AddCommentDialogProvider>
            <AddDiscountDialogProvider>
              <AccountReceivableTable />
            </AddDiscountDialogProvider>
          </AddCommentDialogProvider>
        </BillingProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(AccountReceivableList);
