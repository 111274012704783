import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableFilterBtn: {
    textTransform: "none",
    fontWeight: "bold",
  },
  filter: {
    display: "inline-block",
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  filterRight: {
    display: "inline-block",
    marginLeft: 10,
    textAlign: "right",
  },
  filterAddon: {
    display: "inline-block",
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 5,
    },
  },
  filterTooltip: {
    zIndex: 3,
  },
  filterPopper: {
    width: "100%",
    maxWidth: 420,
    minWidth: 300,
    padding: 12,
  },
  filterPopperContainer: {
    maxWidth: 420,
    minWidth: 420,
    padding: 12,
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      padding: "16px !important",
      minWidth: 360,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
      minWidth: 300,
    },
  },
  filterBreakdownPopperContainer: {
    maxWidth: 200,
    minWidth: 200,
    padding: 12,
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      padding: "30px !important",
      minWidth: "auto",
    },
  },
  filterDivider: {
    borderTop: "solid 1px #f8f8f8",
  },
  filterActions: {
    display: "flex",
  },
  filterActionsRight: {
    flex: "1 1",
    textAlign: "right",
  },
  filterActionBtn: {
    display: "inline-block",
    marginLeft: 10,
    padding: "0px 6px",
    fontSize: "0.7rem",
    fontWeight: "bold",
  },
  filterActionBtnLeft: {
    display: "inline-block",
    padding: "0px 6px",
    fontSize: "0.7rem",
    fontWeight: "bold",
  },
  filterCheck: {
    padding: "0 5px 0 0",
  },
  filterLabel: {
    fontSize: "14px",
  },
  filterGroup: {
    borderBottom: "solid 1px #f8f8f8",
    paddingBottom: 10,
    marginBottom: 10,
    width: "100%",
  },
  APfilterGroup: {
    borderBottom: "solid 1px #f8f8f8",
    marginLeft: 4,
    paddingBottom: 10,
    marginBottom: 10,
    width: "100%",
  },
  headerTitle: {
    color: "#4D4D4D",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 700,
    margin: 0,
  },
  buttonCancel: {
    borderRadius: 4,
    border: "1px solid  #D9D9D9",
    textTransform: "capitalize",
    marginRight: 10,
    width: "100",
  },
  APfilterHeader: {
    display: "flex",
    marginTop: 8,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 15px",
  },
  filterBtn: {
    textTransform: "none",
    color: "#4F98BC",
    backgroundColor: "#EDF5F8",
    border: "1px solid #EDF5F8",
    width: 100,
    fontSize: 14,
    paddingTop: 8,
    paddingBottom: 8,
    fontWeight: 400,
    "& svg": {
      marginRight: 8,
      width: 16,
      top: -2,
      position: "relative",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginLeft: 5,
    },
  },
  filterBtnAP: {
    textTransform: "none",
    color: "#4F98BC",
    backgroundColor: "#EDF5F8",
    border: "1px solid #EDF5F8",
    height: 36,
    borderRadius: 4,
    width: 100,
    fontSize: 14,
    weight: 500,
    paddingTop: 8,
    paddingBottom: 8,
    fontWeight: 400,
    "& svg": {
      marginRight: 8,
      width: 16,
      top: -2,
      position: "relative",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginLeft: 5,
    },
  },
  buttonApply: {
    borderRadius: 4,
    border: "1px solid #4F98BC",
    color: "#ffffff",
    fontSize: 14,
    textTransform: "capitalize",
    backgroundColor: "#4f97bb",
    width: "100",
    "&:hover": {
      backgroundColor: "#4F98BC",
    },
  },
  filtersApplied: {
    display: "inline-block",
    marginBottom: 10,
    fontSize: 14,
    textAlign: "right",
    marginTop: 4,
  },
  filterChip: {
    fontSize: 12,
    marginLeft: 10,
    marginBottom: 3,
  },
  titleFilter: {
    fontSize: 14,
    paddingTop: theme.spacing(1),
  },
  filterCheckbox: {
    color: "#ececec",
    border: "none",
    padding: 0,
    paddingRight: 5,
    "& + .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
  filterCheckboxAddon: {
    color: "#ececec",
    border: "none",
    padding: 0,
    paddingRight: 5,
    "& + .MuiFormControlLabel-label": {
      fontSize: "0.875rem",
      fontWeight: "bold",
    },
  },
  autoContainerFilter: {
    "&  label": {
      display: "none",
    },
    "& > div": {
      marginBottom: 0,
    },
  },
  wrapperTeamMember: {
    "& > div": {
      marginBottom: 0,
    },
  },
  tableOptionBtn: {
    maxWidth: 60,
    paddingTop: 8,
    paddingBottom: 8,
  },
  titleOptionsKpi: {
    "& div": {
      minWidth: 40,
    },
    "& span": {
      fontSize: 14,
      fontWeight: 600,
    },
    "& svg": {
      fontSize: 24,
    },
  },
  filterBreakdownItem: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 8,
  },
  btnSettings: {
    textTransform: "none",
  },
  filterButtonContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  toggleButton: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  activeButton: {
    color: "#4F98BC",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    background: "#EDF5F8",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    textTransform: "capitalize",
  },
  inactiveButton: {
    color: "#747474",
    borderRadius: 4,
    border: "1px solid #D9D9D9",
    background: "#FFF",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    textTransform: "capitalize",
  },
  titleText: {
    color: "#747474",
    marginTop: 16,
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    minHeight: 40,
    height: 40,
  },
  badgeFilters: {
    backgroundColor: "#4F98BC",
    color: "#FFF",
    borderRadius: 10,
    padding: "0 5px",
    marginLeft: 8,
    fontSize: 11,
    width: 25,
    fontWeight: 600,
  },
}));

export default useStyles;
