import React, { useState, useEffect, useRef } from "react";
import * as classNames from "classnames";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import EnhancedTableHead from "./tableHead";
import TableMultipleHead from "./tableMultipleHead";
import TableRow from "./tableRow";
import TableMobile from "./tableMobile";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import SearchFeedback from "../LunaFeedback/index";
import { useTableState } from "contexts/tableContext";
import TableContainer from "@material-ui/core/TableContainer";

const EnhancedTable = (props) => {
  const classes = useStyles();
  const [mobile, setMobile] = useState(false);
  const { currentTab, isMultipleHeader } = useTableState();
  const { idscrollwrapper, lunaSearchData } = props;
  const idWrapper = idscrollwrapper ?? "table-wrapper";
  const idWrapperMobile = "table-wrapper-mobile";
  const tableContainerRef = useRef(null);

  useEffect(() => {
    if (props.activeMobile) {
      if (window.innerWidth < 960) {
        setMobile(true);
      }

      window.addEventListener("resize", () => {
        if (window.innerWidth < 960) {
          setMobile(true);
        } else {
          setMobile(false);
        }
      });
    }
  }, [props.activeMobile]);

  useEffect(() => {
    if (!mobile) return;
    const { handleScrollClick } = props;
    const trackScrolling = (e) => {
      const bottom =
        e.target.scrollHeight - (e.target.scrollTop + 10) <=
        e.target.clientHeight;
      if (bottom) {
        if (handleScrollClick) {
          handleScrollClick();
        }
        document
          .getElementById(idWrapperMobile)
          .removeEventListener("scroll", trackScrolling);
      }
    };
    document
      .getElementById(idWrapperMobile)
      .addEventListener("scroll", trackScrolling);
    return () => {
      if (document.getElementById(idWrapperMobile))
        document
          .getElementById(idWrapperMobile)
          .removeEventListener("scroll", trackScrolling);
    };
  }, [props, idWrapperMobile, mobile]);

  useEffect(() => {
    if (mobile) return;
    const { handleScrollClick } = props;
    const handleScroll = (event) => {
      const bottom =
        event.target.scrollHeight - (event.target.scrollTop + 10) <=
        event.target.clientHeight;
      if (bottom) {
        if (handleScrollClick) {
          handleScrollClick();
        }
        document
          .getElementById(idWrapper)
          .removeEventListener("scroll", handleScroll);
      }
    };

    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [props, idWrapper, mobile]);

  useEffect(() => {
    if (tableContainerRef.current) tableContainerRef.current.scrollTop = 0;
  }, [currentTab, idWrapper]);

  useEffect(() => {
    if (document.getElementById(idWrapperMobile))
      document.getElementById(idWrapperMobile).scrollTop = 0;
  }, [currentTab, idWrapperMobile]);

  return (
    <Box
      className={classes.root}
      id={props.idscrollwrapper ? props.idscrollwrapper : "table-wrapper"}
    >
      {props.children}
      {/* <Box
        className={
          props.isDashboard
            ? classNames(classes.tableWrapper, classes.maxHeightTableInSlider)
            : classNames(classes.tableWrapper)
        }
        id={props.idscrollwrapper ? props.idscrollwrapper : "table-wrapper"}
      > */}
      {props.showNoSearchResultAi ? (
        <SearchFeedback lunaSearchData={lunaSearchData} isNoResults={true} />
      ) : !mobile ? (
        <TableContainer
          className={
            props.isDashboard
              ? classNames(classes.container, classes.maxHeightTableInSlider)
              : classNames(classes.container)
          }
          ref={tableContainerRef}
        >
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            {isMultipleHeader ? (
              <TableMultipleHead />
            ) : (
              <EnhancedTableHead
                hideHeaderCheckbox={props.hideHeaderCheckbox}
              />
            )}
            <TableBody>
              <React.Fragment>
                <TableRow
                  hideHeaderCheckbox={props.hideHeaderCheckbox}
                  checkIcon={props.checkIcon}
                  handleRowClick={props.handleRowClick}
                />
              </React.Fragment>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          className={
            props.isDashboard
              ? classNames(classes.tableWrapper, classes.maxHeightTableInSlider)
              : classNames(classes.tableWrapper)
          }
          id="table-wrapper-mobile"
        >
          <TableMobile handleRowClick={props.handleRowClick} />
        </Box>
      )}
      {/* {!mobile ? (
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            {isMultipleHeader ? <TableMultipleHead /> : <EnhancedTableHead />}
            <TableBody>
              <React.Fragment>
                <TableRow handleRowClick={props.handleRowClick} />
              </React.Fragment>
            </TableBody>
          </Table>
        ) : (
          <TableMobile handleRowClick={props.handleRowClick} />
        )} */}
      {/* </Box> */}
    </Box>
  );
};

export default EnhancedTable;
