import React, { useContext, useEffect, useRef, useState } from "react";
import { Slide } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import AnalysisCard from "components/common/LunaLens/analysisCard";
import FormInput from "components/ui/FormContent/formInput";
import useLunaLensStore from "store/lunaLensAnalysis";
import GlobalUiContext from "contexts/globalUiContext";
import useStyles from "./styles";
import {
  addAnalysis,
  getAnalysisById,
  getAnalysisReport,
  getFrameCount,
  processAnalysis,
  saveAnalysis,
  saveFeedback,
  startAnalysis,
  updateAnalysis,
} from "services/lunaLensService";
import { useUploadFileLensState } from "contexts/uploadFileLensContext";
import UploadFileLens from "./uploadFileButtonLens";
import UploadProgressLens from "./uploadProgressLens";
import AnalysisCategoriesAccordion from "./analysisCategoriesAccordion";
import AnalysisInformationCard from "./analysisInformationCard";
import AnalysisPromptCard from "./analysisPromptCard";
import AnalysisLensProgress from "./analysisLensProgress";
import ImageLimitError from "./imageLimitError";
import { permissionSurface, hasPermission } from "lib/permissions";
import AnalysisCategoriesAccordionTab from "./analysisCategoriesAccordionTab";
import LensCommentsSurface from "./lensCommentsSurface";
import LensComments from "./lensComments";
import DialogLoadingLuna from "./dialog/dialogLoadingLuna";
import DialogFeedbackLuna from "./dialog/dialogFeedbackLuna";
import { v4 as uuidv4 } from "uuid";
import FormCategoryInput from "components/ui/FormContent/formCategoryInput";
import { ArrowUpBlue, ArrowUpGray } from "./images/images";
import { workticketFiles } from "services/workticketService";

const getNonEmptyKeys = (obj) => {
  return Object.keys(obj).filter(
    (key) => Array.isArray(obj[key]) && obj[key].length > 0
  );
};

const replaceServiceVerification = (arr) => {
  const hasBefore = arr.includes("service_verification_before");
  const hasAfter = arr.includes("service_verification_after");

  if (hasBefore && hasAfter) {
    return arr
      .filter(
        (item) =>
          item !== "service_verification_before" &&
          item !== "service_verification_after"
      )
      .concat("service_verification");
  }

  return arr;
};

const transformAnalysisResponse = (categories) => {
  if (!categories) return {};
  const transformed = {};

  categories.forEach((category) => {
    const categoryValue = category.luna_lens_category.value;
    const trades = category.luna_lens_analysis_categories_trade.map(
      (trade) => ({
        title: trade.title,
        description: trade.description,
        image_urls: trade.luna_lens_trade_media.map(
          (media) => media?.file.file_url
        ),
      })
    );

    transformed[categoryValue] = trades;
  });

  return transformed;
};

function NewAnalysisDetail({
  closeLensDialog,
  workticketId,
  workticketNumber,
  location,
  description,
  summary,
  customer,
  analysisCardSelected,
  wtType,
}) {
  const defaultAnalysisName = `${customer} / ${summary} / Insights Report`;
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const [trackMedia, setTrackMedia] = useState(false);
  const [mediaTracked, setMediaTracked] = useState([]);
  const [activeSlide, setActiveSlide] = useState(1);
  const [categoryContext, setCategoryContext] = useState([]);
  const [savedAnalysis, setSavedAnalysis] = useState();
  const [analyzing, setAnalyzing] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [categoryRemoved, setCategoryRemoved] = useState(null);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [indexCategoryThumbDown, setIndexCategoryThumbDown] = useState();
  const [currentWorkticketAnalysis, setCurrentWorkticketAnalysis] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [threadId, setThreadId] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);

  const { isUploadFile } = useUploadFileLensState();

  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [analysisName, setAnalysisName] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingAnalysis, setLoadingAnalysis] = useState(false);
  const [loadingAddAnalysis, setLoadingAddAnalysis] = useState(false);
  const [loadingUpdateAnalysis, setLoadingUpdateAnalysis] = useState(false);
  const [threadStartId, setThreadStartId] = useState();

  const media = useLunaLensStore((state) => state.media);
  const setDoneMediaFiles = useLunaLensStore(
    (state) => state.setDoneMediaFiles
  );
  const categoryList = useLunaLensStore((state) => state.categoryList);
  const analysisComment = useLunaLensStore((state) => state.analysisComment);
  const setStartingAnalysis = useLunaLensStore(
    (state) => state.setStartingAnalysis
  );
  const setGlobalThreadId = useLunaLensStore(
    (state) => state.setGlobalThreadId
  );
  const selectedImages = useLunaLensStore((state) => state.selectedImages);
  const setSelectedImages = useLunaLensStore(
    (state) => state.setSelectedImages
  );
  const resetSelectedImages = useLunaLensStore(
    (state) => state.resetSelectedImages
  );
  const globalSelectedMediaCount = useLunaLensStore(
    (state) => state.globalSelectedMediaCount
  );
  const setAnalysisInformation = useLunaLensStore(
    (state) => state.setAnalysisInformation
  );
  const setAnalysisThread = useLunaLensStore(
    (state) => state.setAnalysisThread
  );
  const analysisThread = useLunaLensStore((state) => state.analysisThread);
  const setAnalysisResponse = useLunaLensStore(
    (state) => state.setAnalysisResponse
  );
  const resetAnalysisDetail = useLunaLensStore(
    (state) => state.resetAnalysisDetail
  );
  const setSavedLensAnalysis = useLunaLensStore(
    (state) => state.setSavedLensAnalysis
  );
  const setAnalysisFailed = useLunaLensStore(
    (state) => state.setAnalysisFailed
  );
  const updateAnalysisTrade = useLunaLensStore(
    (state) => state.updateAnalysisTrade
  );
  const mediaLimitation = useLunaLensStore((state) => state.mediaLimitation);
  const addWorkticketAnalysisList = useLunaLensStore(
    (state) => state.addWorkticketAnalysisList
  );
  const workticketAnalysisList = useLunaLensStore(
    (state) => state.workticketAnalysisList
  );
  const setStartAnalysisFlag = useLunaLensStore(
    (state) => state.setStartAnalysisFlag
  );

  const setAnalysisStarted = useLunaLensStore(
    (state) => state.setAnalysisStarted
  );

  const setWorkticketAnalysisFeedback = useLunaLensStore(
    (state) => state.setWorkticketAnalysisFeedback
  );
  const workticketAnalysisListRef = useRef(workticketAnalysisList);

  useEffect(() => {
    const pendingVideoFrames = media?.filter((item) => item.track);
    if (pendingVideoFrames.length > 0) {
      setTrackMedia(true);
      setMediaTracked(pendingVideoFrames);
    }
  }, [media]);

  useEffect(() => {
    const pingEndpoint = async () => {
      if (trackMedia) {
        const response = await workticketFiles(workticketId);
        const mediaProgress = response.data.data.all.filter(
          (item) => item.mime_type === "3" && item.frames === "1"
        );
        const matching = mediaTracked.filter((trackedItem) =>
          mediaProgress.find(
            (progressItem) => progressItem.id === trackedItem.id
          )
        );
        if (matching.length > 0) {
          setDoneMediaFiles(matching);
          if (matching.length === mediaTracked.length) {
            setTrackMedia(false);
            setMediaTracked([]);
          }
        }
      }
    };

    pingEndpoint();
    const intervalId = setInterval(pingEndpoint, 30000); // Ping every 30 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackMedia, workticketId, mediaTracked, setDoneMediaFiles]);

  useEffect(() => {
    const executeAnalysis = async () => {
      if (analysisComment) {
        setActiveSlide(2);
        setLoadingAnalysis(true);
        setLoadingReport(true);
        const response = await getAnalysisReport({
          id: analysisCardSelected.analysisId,
        });
        setAnalysisThread(response.data.analysis);
        setLoadingAnalysis(false);
        setLoadingReport(false);
      }
    };
    executeAnalysis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisComment]);

  useEffect(() => {
    workticketAnalysisListRef.current = workticketAnalysisList;
  }, [workticketAnalysisList]);

  useEffect(() => {
    const executeAnalysis = async () => {
      if (analysisComment) return;
      if (activeSlide === 2) {
        setLoadingAnalysis(true);
        setStartAnalysisFlag(true);
        resetAnalysisDetail();
        const user = JSON.parse(localStorage.getItem("user"));
        const nonEmptyKeys = getNonEmptyKeys(selectedImages);
        const updatedCategories = replaceServiceVerification(nonEmptyKeys);
        setAnalysisInformation({
          images: selectedImages,
          categories: categoryList.filter((cat) =>
            updatedCategories.includes(cat.value)
          ),
        });
        const totalMediaCount = Object.keys(selectedImages).reduce(
          (acc, category) => {
            return acc + selectedImages[category].length;
          },
          0
        );
        const uniqueUrls = new Set();
        Object.keys(selectedImages).forEach((category) => {
          selectedImages[category].forEach((image) => {
            uniqueUrls.add(image.url);
          });
        });
        const allItems = Object.values(selectedImages).flat();
        const videos = allItems.filter((item) => item.frames === "1");
        const uniqueVideos = Array.from(
          new Set(videos.map((video) => video.id))
        );
        let TotalFrames = 0;
        if (uniqueVideos.length > 0) {
          for (const videoId of uniqueVideos) {
            const response = await getFrameCount({ file_id: videoId });
            TotalFrames += response.data.file_frame_count;
          }
        }
        const newUuid = uuidv4();
        setThreadId(newUuid);
        setGlobalThreadId(newUuid);
        const data = {
          location: location,
          summary: summary,
          description: description,
          name: analysisName,
          workticket_id: workticketId,
          workticket_number: workticketNumber,
          workticket_type: wtType,
          user_id: user.id,
          total_media: totalMediaCount,
          prompt: value,
          start: new Date().toISOString(),
          selected_images: selectedImages,
          total_unique_media:
            uniqueUrls.size + TotalFrames - uniqueVideos.length,
          email: user.email,
          thread_id: newUuid,
        };
        const response = await startAnalysis(data);
        setSavedAnalysis(
          response.data.analysis.analysis.luna_lens_workticket_analysis[0]?.id
        );
        setAnalysisThread(response.data.analysis.analysis);
        setThreadStartId(response.data.analysis.analysis.id);
        const foundAnalysis = workticketAnalysisListRef.current.find(
          (analysis) => analysis.id === response.data.analysis.analysis.id
        );
        if (!foundAnalysis) {
          addWorkticketAnalysisList(response.data.analysis.analysis);
        }
        setValue("");
        setLoadingAnalysis(false);
        setLoadingAddAnalysis(false);
        setStartAnalysisFlag(false);
        setAnalysisStarted(true);
      }
    };
    if (
      location &&
      description &&
      summary &&
      workticketId &&
      workticketNumber &&
      analysisName &&
      value
    ) {
      executeAnalysis();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    analysisName,
    description,
    location,
    summary,
    workticketId,
    workticketNumber,
    value,
    activeSlide,
    selectedImages,
    categoryList,
    setAnalysisInformation,
    setAnalysisThread,
    resetAnalysisDetail,
    addWorkticketAnalysisList,
    setStartAnalysisFlag,
    setAnalysisStarted,
    setGlobalThreadId,
  ]);

  useEffect(() => {
    const checkAnalysis = async () => {
      if (threadStartId && threadId) {
        const response = await getAnalysisById({
          id: threadStartId,
          thread_id: threadId,
        });
        const lunaResponse = response.data.analysis;
        if (lunaResponse?.status === 1) {
          if (
            lunaResponse?.luna_lens_workticket_analysis[0]
              ?.luna_lens_analysis_categories?.length > 0
          ) {
            setAnalysisResponse(
              lunaResponse.luna_lens_workticket_analysis[0]?.id,
              lunaResponse.luna_lens_workticket_analysis[0]
                ?.luna_lens_analysis_categories,
              lunaResponse.luna_lens_workticket_analysis[0].response_text,
              lunaResponse.luna_lens_workticket_analysis[0]?.json_response,
              lunaResponse.luna_lens_workticket_analysis[0].run_id,
              lunaResponse.luna_lens_workticket_analysis[0]?.error
            );
            setCurrentWorkticketAnalysis(
              lunaResponse.luna_lens_workticket_analysis[0]?.id
            );
            setLoading(false);
            setAnalyzing(false);
            setStartingAnalysis(false);
            processAnalysis({
              id: lunaResponse.luna_lens_workticket_analysis[0]?.id,
            });
          }
        } else if (lunaResponse?.status === 2) {
          if (lunaResponse.luna_lens_workticket_analysis.length > 0) {
            setAnalysisFailed(
              lunaResponse.luna_lens_workticket_analysis[0]?.id
            );
            setLoading(false);
            setAnalyzing(false);
            setStartingAnalysis(false);
            processAnalysis({
              id: lunaResponse.luna_lens_workticket_analysis[0]?.id,
            });
          }
        }
      }
    };
    checkAnalysis();
    const intervalId = setInterval(checkAnalysis, 15000);

    return () => clearInterval(intervalId);
  }, [
    threadStartId,
    setAnalysisResponse,
    setAnalysisFailed,
    threadId,
    setStartingAnalysis,
  ]);

  const showSlide = (number) => {
    setActiveSlide(null);
    setTimeout(() => {
      setActiveSlide(number);
    }, 500);
  };

  const removeCategoryInput = (category) => {
    setCategoryRemoved(category);
    setCategoryContext((prevContext) =>
      prevContext.filter((cat) => cat.value !== category.value)
    );
  };

  const handleMoreContext = async () => {
    setStartingAnalysis(true);
    setAnalyzing(true);
    setLoadingAddAnalysis(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const lunaCurrentAnalysis =
      analysisThread?.luna_lens_workticket_analysis?.find(
        (analysis) => analysis.id === currentWorkticketAnalysis
      )?.luna_lens_analysis_categories;
    const lunaFormattedResponse =
      transformAnalysisResponse(lunaCurrentAnalysis);
    const data = {
      analysis_id: analysisThread.id,
      workticket_id: workticketId,
      prompt:
        value2.length > 0
          ? value2
          : analysisThread?.luna_lens_workticket_analysis[0].prompt,
      prompt_categories: categoryContext,
      start: new Date().toISOString(),
      selected_images: selectedImages,
      reference_analysis:
        categoryContext.length > 0 ? lunaFormattedResponse : null,
      current_id: categoryContext.length > 0 ? currentWorkticketAnalysis : null,
      user_id: user.id,
      email: user.email,
      thread_id: threadId,
      saved_id: null,
    };
    const response = await addAnalysis(data);
    setAnalysisThread(response.data.analysis.analysis);
    setLoadingAddAnalysis(false);

    setValue2("");
    setCategoryContext([]);
    setAnalysisStarted(true);
  };

  const newAnalysisLens = async (prompt, promptCategories) => {
    resetSelectedImages();
    setSelectedImages();

    if (!promptCategories) {
      if (
        Object.values(globalSelectedMediaCount).reduce(
          (acc, count) => acc + count,
          0
        ) === 0
      ) {
        setErrorMessage("Please select at least one Media File.");
        return;
      }
      if (value.length === 0) {
        setErrorMessage("Please add a prompt to Deepen Insights.");
        return;
      }
      if (analysisName.length === 0) {
        setAnalysisName(defaultAnalysisName);
      }
      setStartingAnalysis(true);
      showSlide(2);
    }
  };

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false);
  };

  const handleSaveAnalysis = async (thread) => {
    await saveAnalysis({
      unSaveId: savedAnalysis,
      saveId: thread.id,
    });
    setSavedLensAnalysis(savedAnalysis, "0");
    setSavedLensAnalysis(thread.id, "1");
    setSavedAnalysis(thread.id);
  };

  const handleThumbUp = async (id, prompt, runId) => {
    setOpenLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    await saveFeedback({
      type: "positive",
      reason: null,
      comment: null,
      run_id: runId,
      session_id: null,
      workticket_analysis_id: id,
      category: null,
      feedback_date: new Date().toISOString(),
      feedback_score: 1,
      feedback_reasons: ["Luna response met or exceeded my expectations."],
      prompt: prompt,
      user_id: user.id,
      user_email: user.email,
    });
    setWorkticketAnalysisFeedback(id, {
      type: "positive",
      reason: null,
      comment: null,
    });
    setOpenLoading(false);
  };

  const handleThumbDown = async (id, prompt, runId) => {
    setOpenFeedbackDialog(true);
    setIndexCategoryThumbDown({ id, category: null, prompt, runId });
  };

  const addCategoryContext = (category) => {
    setCategoryContext((prevContext) => {
      const categoryExists = prevContext.some((cat) => cat.value === category);
      if (categoryExists) {
        return prevContext.filter((cat) => cat.value !== category);
      } else {
        const newCategory = categoryList.find((cat) => cat.value === category);
        return [...prevContext, newCategory];
      }
    });
  };

  const updateAnalysisTrades = async (analysisWorkticketId) => {
    setLoadingUpdateAnalysis(true);
    const lensWorkticketAnalysis =
      analysisThread?.luna_lens_workticket_analysis?.find(
        (analysis) => analysis.id === analysisWorkticketId
      );
    const newLensAnalysis =
      lensWorkticketAnalysis.luna_lens_analysis_categories.map((category) => {
        const updatedTrades = category.luna_lens_analysis_categories_trade.map(
          (trade) => {
            if (trade.deleted) {
              return {
                ...trade,
                title: null,
                description: "",
              };
            }
            return trade;
          }
        );

        return {
          ...category,
          luna_lens_analysis_categories_trade: updatedTrades,
        };
      });

    lensWorkticketAnalysis.luna_lens_analysis_categories = newLensAnalysis;
    const response = await updateAnalysis({
      lens_workticket_analysis: lensWorkticketAnalysis,
      analysis_id: analysisThread.id,
    });
    updateAnalysisTrade(
      response.data.analysis.luna_lens_workticket_analysis[0]
    );
    setLoadingUpdateAnalysis(false);
    setLoading(false);
  };

  return (
    <>
      <Slide direction="left" in={activeSlide === 1} mountOnEnter unmountOnExit>
        {!isUploadFile ? (
          <Box className={classes.newAnalysisContainer}>
            <ImageLimitError visible={mediaLimitation} />
            <Box className={classes.newAnalysisDetailContainer}>
              <Box style={{ marginBottom: "36px" }}>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="prompt"
                  label="Name this Insights Report"
                  placeholder={defaultAnalysisName}
                  value={analysisName}
                  onChange={(e) => {
                    if (analysisName.length > 200) return;
                    setAnalysisName(e.target.value);
                  }}
                />
              </Box>
              <AnalysisCategoriesAccordion
                newAnalysis={true}
                workticketId={workticketId}
              />
            </Box>
            <Box className={classes.historyBottomContainer}>
              <Box
                style={{
                  display: "flex",
                  margin: "12px 0px",
                  justifyContent: "flex-end",
                }}
              >
                <UploadFileLens workTicketId={workticketId} lens />
              </Box>
              <FormCategoryInput
                gridSizes={[{ size: "md", val: 12 }]}
                label="Deepen Insights"
                placeholder="Add something here to deepen insights..."
                name="prompt"
                errorMessage={errorMessage}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  setErrorMessage("");
                }}
                styleOverride={{ marginBottom: "32px" }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    newAnalysisLens(value);
                    setLoading(true);
                    setLoadingAddAnalysis(true);
                  }
                }}
                onHandleSubmit={() => {
                  newAnalysisLens(value);
                  setLoading(true);
                  setLoadingAddAnalysis(true);
                }}
                disableButton={value.trim().length === 0}
                endAdornment={
                  value.trim().length > 0 ? <ArrowUpBlue /> : <ArrowUpGray />
                }
              />
            </Box>
          </Box>
        ) : (
          <Box className={classes.progressContainer}>
            <UploadProgressLens
              workTicketId={workticketId}
              activeTab="all"
              style={{ maxWidth: "100%" }}
            />
          </Box>
        )}
      </Slide>
      <Slide direction="left" in={activeSlide === 2} mountOnEnter unmountOnExit>
        <Box className={classes.newAnalysisContainerResponse}>
          {!loadingAnalysis && (
            <Box className={classes.commentsContainerDialog}>
              {hasPermission(permissionSurface.WORKTICKET, permissions) ? (
                <LensCommentsSurface
                  workticketId={analysisThread?.workticket_id}
                  workticketNumber={analysisThread?.workticket_number}
                />
              ) : (
                <LensComments
                  workticketId={analysisThread?.workticket_id}
                  workticketNumber={analysisThread?.workticket_number}
                />
              )}
            </Box>
          )}
          {loadingAnalysis ? (
            <Skeleton
              animation="wave"
              variant="rect"
              height={140}
              style={{ marginBottom: "12px" }}
            />
          ) : (
            <AnalysisInformationCard
              analysisTitle={analysisThread?.name}
              location={analysisThread?.location}
              summary={analysisThread?.summary}
              description={analysisThread?.description}
              WTType={wtType}
              analysisId={analysisThread?.id}
            />
          )}
          {loadingAnalysis ? (
            <Skeleton
              animation="wave"
              variant="rect"
              height={"50%"}
              style={{ marginBottom: "12px" }}
            />
          ) : (
            <AnalysisCategoriesAccordionTab
              totalMedia={analysisThread?.total_media}
              analysisMedia={analysisThread?.luna_lens_analysis_media}
              workTicketId={workticketId}
              workTicketNumber={workticketNumber}
            />
          )}
          {!loadingReport && (
            <>
              {analysisThread?.luna_lens_workticket_analysis?.map(
                (thread, index) =>
                  parseInt(thread.loading) === 1 ? (
                    <React.Fragment key={index}>
                      {(thread.prompt ||
                        thread?.luna_lens_prompt_categories.length > 0) && (
                        <Box style={{ marginTop: "16px" }}>
                          <AnalysisPromptCard
                            prompt={thread.prompt}
                            categories={thread?.luna_lens_prompt_categories}
                          />
                        </Box>
                      )}
                      <Box>
                        <AnalysisLensProgress
                          startDateTime={thread.start}
                          totalFiles={analysisThread?.total_media}
                          status={parseInt(thread.status)}
                          uniqueFiles={analysisThread?.total_unique_media}
                          errorMessage={thread?.error}
                        />
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={index}>
                      {(thread.prompt ||
                        thread?.luna_lens_prompt_categories.length > 0) && (
                        <Box style={{ marginTop: "16px" }}>
                          <AnalysisPromptCard
                            key={index}
                            prompt={thread.prompt}
                            categories={thread?.luna_lens_prompt_categories}
                          />
                        </Box>
                      )}
                      <AnalysisCard
                        key={index}
                        view="new"
                        newWorkticketNumber={analysisThread?.workticket_number}
                        newAnalysisFlag
                        loading={analyzing}
                        setLoading={setLoading}
                        analysisThread={thread}
                        handleCategoryContext={addCategoryContext}
                        indexThread={index}
                        totalThreads={
                          analysisThread.luna_lens_workticket_analysis.length
                        }
                        onHandleSaveAnalysis={handleSaveAnalysis}
                        responseText={thread?.response_text}
                        onUpdateTrade={updateAnalysisTrades}
                        loadingUpdateAnalysis={loadingUpdateAnalysis}
                        onHandleThumbDown={() =>
                          handleThumbDown(
                            thread.id,
                            thread.prompt,
                            thread.run_id
                          )
                        }
                        onHandleThumbUp={() =>
                          handleThumbUp(thread.id, thread.prompt, thread.run_id)
                        }
                        fillUp={thread?.feedback?.type === "positive"}
                        fillDown={thread?.feedback?.type === "negative"}
                        workTicketId={workticketId}
                        workTicketNumber={workticketNumber}
                        clearContext={() => setCategoryContext([])}
                        categoryRemoved={categoryRemoved}
                        setCategoryRemoved={setCategoryRemoved}
                      />
                    </React.Fragment>
                  )
              )}
            </>
          )}
          {!loadingAddAnalysis && !loadingReport ? (
            <>
              <Box style={{ marginBottom: "32px" }}>
                {!analysisComment && (
                  <FormCategoryInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    placeholder="Add something here to deepen insights..."
                    categories={categoryContext}
                    removeCategoryInput={removeCategoryInput}
                    readOnly={loading || analysisComment}
                    name="prompt2"
                    value={value2}
                    onChange={(e) => setValue2(e.target.value)}
                    styleOverride={{ marginBottom: "32px" }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        if (!loading && !analysisComment) {
                          setLoading(true);
                          handleMoreContext();
                        }
                      }
                    }}
                    onHandleSubmit={() => {
                      setLoading(true);
                      handleMoreContext();
                    }}
                    disableButton={value2.trim().length === 0}
                    endAdornment={
                      value2.trim().length > 0 ? (
                        <ArrowUpBlue />
                      ) : (
                        <ArrowUpGray />
                      )
                    }
                  />
                )}
              </Box>
            </>
          ) : (
            <Box style={{ marginBottom: "32px", marginTop: "24px" }}>
              <Skeleton
                animation="wave"
                variant="rect"
                height={100}
                style={{ marginBottom: "32px" }}
              />
            </Box>
          )}
          <DialogFeedbackLuna
            open={openFeedbackDialog}
            handleCancel={handleCloseFeedbackDialog}
            thread={indexCategoryThumbDown}
            type={"lensResponse"}
          />
          <DialogLoadingLuna open={openLoading} handleCancel={setOpenLoading} />
        </Box>
      </Slide>
    </>
  );
}

export default NewAnalysisDetail;
