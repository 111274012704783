import { makeStyles } from "@material-ui/core/styles";

export const Style = () => {
  return (
    <style>
      {`
            .light-blue {
              background-color: #78C1CE;
            }
            .green {
              background-color: #9CBB65;
            }
            .orange {
              background-color: #F39A3E;
            }
            .pink {
              background-color: #F07A8C;
            }
            
            @keyframes animateDots1 {
              0% {
                transform: translateX(0);
                z-index: 1;
              }
              25% {
                transform: translateX(20px);
                z-index: 1;
              }
              50% {
                transform: translateX(40px);
                z-index: 1;
              }
              75% {
                transform: translateX(60px);
                z-index: 1;
              }
              100% {
                transform: translateX(0);
                z-index: 1;
              }
            }
            
            @keyframes animateDots2 {
              0%,
              100% {
                transform: translateX(0px);
              }
              25% {
                transform: translateX(-20px);
              }
              50% {
                transform: translateX(-20px);
              }
              75% {
                transform: translateX(-20px);
              }
            }
            
            @keyframes animateDots3 {
              0%,
              100% {
                transform: translateX(0px);
              }
              25% {
                transform: translateX(0px);
              }
              50% {
                transform: translateX(-20px);
              }
              75% {
                transform: translateX(-20px);
              }
            }
            
            @keyframes animateDots4 {
              0%,
              100% {
                transform: translateX(0px);
              }
              25% {
                transform: translateX(0px);
              }
              50% {
                transform: translateX(0px);
              }
              75% {
                transform: translateX(-20px);
              }
            }
            
            .light-blue.active {
              animation: animateDots1 2s infinite 0s;
            }
            .green.active {
              animation: animateDots2 2s infinite 0s;
            }
            .orange.active {
              animation: animateDots3 2s infinite 0s;
            }
            .pink.active {
              animation: animateDots4 2s infinite 0s;
            }
            
            /* Start the animation at load */
            .light-blue.active,
            .green.active,
            .orange.active,
            .pink.active {
              animation-play-state: running;
            }
          `}
    </style>
  );
};

const useStyles = makeStyles((theme) => ({
  addTitleField: {
    width: "100%",
    minHeight: 40,
    padding: "4px",
    border: "1px solid #ECECEC",
    borderRadius: "4px",
    fontSize: 14,
    color: "#747474",
    paddingLeft: 12,
    backgroundColor: "#FFFFFF",
    "& textarea": {
      fontSize: 14,
      color: "#747474",
      backgroundColor: "#FFFFFF",
    },
  },
  analysisCardContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "16px 16px 16px 8px",
    backgroundColor: "#F8F8F8",
    borderRadius: "4px",
    marginTop: "32px",
    position: "relative",
  },
  analysisCardContainerHistory: {
    display: "flex",
    flexDirection: "column",
    padding: "16px 16px 16px 8px",
    backgroundColor: "#F8F8F8",
    borderRadius: "4px",
    position: "relative",
    border: "1px solid #ECECEC",
  },
  analysisHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "16px",
    marginTop: "16px",
  },
  analysisHeaderContainerHistory: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "16px",
  },
  analysisHeaderTitle: {
    marginLeft: "8px",
    marginRight: "12px",
  },
  badgeIconContainer: {
    borderRadius: "4px",
    width: 22,
    height: 22,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  BodyDialogContainer: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "96%",
    },
    height: "100%",
    margin: "0 auto",
    paddingTop: "30px",
  },
  cardWTContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "16px 16px 24px 16px",
    borderRadius: "4px",
    backgroundColor: "#F8F8F8",
    position: "relative",
    marginBottom: "24px",
    marginTop: "16px",
  },
  cardWTContainerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 12,
    marginBottom: "8px",
  },
  cardWTContainerInspection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 8px",
    gap: "8px",
    borderRadius: "4px",
    backgroundColor: "#EDF5F8",
    border: "1px solid #BAE7FA",
  },
  cardWTInspectionText: {
    color: "#17457A",
  },
  cardWTContainerTitle: {
    display: "flex",
    flexShrink: 0,
    gap: "5px",
    alignItems: "center",
  },
  cardWTTitle: {
    fontSize: "14px",
    fontWeight: 600,
  },
  cardWTDescription: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  chatLensIconContainer: {
    position: "absolute",
    left: "-80px",
    top: "0px",
  },
  chatLensIconContainerView: {
    position: "absolute",
    left: "-80px",
    top: "10px",
  },
  containerTab: {
    overflowY: "auto",
  },
  commentsContainer: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    right: "50px",
    top: "129px",
    zIndex: 10,
    border: "1px solid #ECECEC",
    width: "23%",
    height: "70%",
    borderRadius: "4px",
    padding: "0px 12px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  commentsContainerDialog: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    right: "50px",
    top: "78px",
    zIndex: 10,
    border: "1px solid #ECECEC",
    width: "25%",
    height: "80%",
    borderRadius: "4px",
    padding: "0px 12px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  editCommentsContainer: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    right: "50px",
    top: "112px",
    zIndex: 10,
    border: "1px solid #ECECEC",
    width: "25%",
    height: "80%",
    borderRadius: "4px",
    padding: "0px 12px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  editCommentsContainerDialog: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    right: "50px",
    top: "60px",
    zIndex: 10,
    border: "1px solid #ECECEC",
    width: "25%",
    height: "80%",
    borderRadius: "4px",
    padding: "0px 12px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  commentsContainerSkeleton: {
    overflowY: "auto",
    position: "fixed",
    right: "50px",
    top: "88px",
    zIndex: 10,
    width: "25%",
    height: "80%",
    borderRadius: "4px",
    padding: "0px 12px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  commentsContainerSkeletonDialog: {
    overflowY: "auto",
    position: "fixed",
    right: "50px",
    top: "36px",
    zIndex: 10,
    width: "25%",
    height: "80%",
    borderRadius: "4px",
    padding: "0px 12px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  imageLimitErrorContainer: {
    position: "fixed",
    right: "250px",
    top: "60px",
    width: "230px",
    borderRadius: "4px",
    border: "1px solid #9A1010",
    padding: "8px 12px",
    backgroundColor: "#FFDFDF",
    zIndex: 9,
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      right: "186px",
      top: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      right: "auto",
      left: "25px",
      bottom: "20px",
      top: "auto",
    },
  },
  chatLensMessageContainer: {
    display: "flex",
    // alignItems: "center",
    marginBottom: "16px",
    justifyContent: "space-between",
  },
  buttonHeader: {
    border: "0.75px solid #4F98BC",
    borderRadius: "4px",
    padding: "4px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
  },
  buttonHeaderMoreContext: {
    color: "#4F98BC",
    fontSize: "12px",
    fontWeight: 400,
    textTransform: "none",
    border: "1px solid #4F98BC",
    borderRadius: "4px",
    padding: "2px 12px 2px 12px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
  },
  buttonHeaderMoreContextFilled: {
    color: "white",
    fontSize: "12px",
    fontWeight: 400,
    textTransform: "none",
    backgroundColor: "#4F98BC",
    border: "0.75px solid #4F98BC",
    borderRadius: "4px",
    padding: "2px 12px 2px 12px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#4488A3",
    },
  },
  buttonOpenReport: {
    color: "#4F98BC",
    fontWeight: "bold",
    textTransform: "none",
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    padding: "6px 16px 6px 16px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
  },
  buttonHeaderAnalysis: {
    color: "#4F98BC",
    fontSize: "14px",
    fontWeight: 400,
    textTransform: "none",
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    padding: "4px 8px 4px 8px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
  },
  buttonFeedback: {
    border: "0.75px solid #4F98BC",
    borderRadius: "4px",
    padding: "2px 4px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
  },
  buttonDeleteBullet: {
    border: "0.75px solid #4F98BC",
    borderRadius: "4px",
    padding: "2px 4px",
    margin: "11px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
  },
  buttonDeleteBulletDisabled: {
    border: "0.75px solid #F8F8F8",
    borderRadius: "4px",
    padding: "2px 4px",
    margin: "11px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
  },
  addCommentField: {
    width: "100%",
    // marginTop: 24,
    minHeight: 40,
    padding: "4px",
    border: "1px solid #ECECEC",
    borderRadius: "4px",
    fontSize: 14,
    color: "#747474",
    paddingLeft: 12,
    "& textarea": {
      fontSize: 14,
      color: "#747474",
    },
  },
  commentInputText: {
    color: "#747474",
    fontSize: 14,
    border: "none",
    background: "transparent",
    outline: "none",
    width: "100%",
    padding: 0,
    paddingTop: 8,
    paddingBottom: 8,
  },
  containerAddButton: {
    display: "flex",
    alignItems: "flex-start",
    borderRadius: 4,
    border: "1px solid #ECECEC",
    background: "#FFFFFF",
    padding: theme.spacing(1),
  },
  buttonEditTitle: {
    color: "white",
    fontSize: "14px",
    fontWeight: 400,
    textTransform: "none",
    backgroundColor: "#4F98BC",
    border: "0.75px solid #4F98BC",
    borderRadius: "4px",
    padding: "2px 8px 0px 8px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#4488A3",
    },
  },
  buttonFeedbackFilled: {
    textTransform: "none",
    backgroundColor: "#4F98BC",
    border: "0.75px solid #4F98BC",
    borderRadius: "4px",
    padding: "2px 4px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#4488A3",
    },
  },
  buttonHeaderFilled: {
    textTransform: "none",
    backgroundColor: "#4F98BC",
    border: "0.75px solid #4F98BC",
    borderRadius: "4px",
    padding: "6px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#4488A3",
    },
  },
  buttonAnalysisFilled: {
    color: "white",
    fontSize: "14px",
    fontWeight: 400,
    textTransform: "none",
    backgroundColor: "#4F98BC",
    border: "0.75px solid #4F98BC",
    borderRadius: "4px",
    padding: "4px 12px 4px 12px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#4488A3",
    },
  },
  buttonMediaFilled: {
    color: "white",
    textTransform: "none",
    backgroundColor: "#4F98BC",
    border: "0.75px solid #4F98BC",
    borderRadius: "4px",
    padding: "6px 34px 6px 34px",
    minWidth: "0",
    margin: "8px 0px",
    alignSelf: "flex-end",
    "& .MuiButton-label": {
      padding: "0",
    },
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#4488A3",
    },
  },
  buttonHeaderContainer: {
    "& > :not(:last-child)": {
      marginRight: 8,
    },
  },
  buttonVertical: {
    backgroundColor: "#D9D9D9",
    borderRadius: "4px",
    padding: "4px",
    minWidth: "0",
  },
  buttonHorizontal: {
    backgroundColor: "none",
    borderRadius: "4px",
    padding: "6px",
    minWidth: "0",
  },
  buttonConfirm: {
    padding: "8px 32px",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    color: "#ffffff",
    fontSize: 14,
    textTransform: "capitalize",
    backgroundColor: "#4f97bb",
    "&:hover": {
      background: "#4F98BC",
    },
  },
  categoryContainer: {
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 48,
    backgroundColor: "white",
    padding: "0px 12px",
    boxShadow: "0px 2px 20px 0px rgba(144, 144, 144, 0.16)",
  },
  categoryContainerTag: {
    borderRadius: "50px",
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1px 7px 1px 7px",
  },
  categoryHeaderContainer: {
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px 8px 2px",
  },
  categoryListContainer: {
    color: "#747474",
    padding: "16px",
    overflowY: "auto",
    height: "30%",
  },
  categorySelectionContainer: {
    display: "inline-flex",
    flexDirection: "row",
    gap: "4px",
    alignItems: "center",
    padding: "2px 8px",
    borderRadius: "4px",
  },
  categorySelectionListContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "6px 12px",
  },
  mediaCategoryListContainer: {
    color: "#747474",
    padding: "16px",
  },
  categorySubTitle: {
    marginLeft: "8px",
    marginTop: "4px",
    color: "#747474",
  },
  categoryText: {
    marginLeft: "8px",
    color: "#747474",
  },
  categoryTextLabel: {
    marginLeft: "8px",
    fontSize: "14px",
    fontWeight: 400,
  },
  galleryContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "90%",
  },
  galleryContainerAnalysis: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "50%",
  },
  hideOnSmallDevices: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  historyCardContainer: {
    borderRadius: "4px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "12px 20px 16px 20px",
    margin: "20px",
    gap: "16px",
  },
  historyContainerIcons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
  },
  historyHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "5px",
    },
  },
  mediaContainer: {
    backgroundColor: "#ECECEC",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px 12px 12px 12px",
    gap: "10px",
  },
  mediaIconContainer: {
    backgroundColor: "#F8F8F8",
    borderRadius: "4px",
    width: 42,
    height: 42,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  mediaTitle: {
    margin: "15px 0px",
  },
  menuIconBackground: {
    width: 24,
    height: 24,
    flexShrink: 0,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  listContainer: {
    borderRadius: "4px",
    padding: "8px 0px 8px 4px",
  },
  noInitialTransition: {
    transition: "none",
  },
  listItemContainer: {
    display: "flex",
    flexDirection: " row",
  },
  listItemIcon: {
    minWidth: "20px",
    marginTop: "12px",
  },
  listRecordIcon: {
    color: "#747474",
    fontSize: "8px",
  },
  description: {
    whiteSpace: "pre-line",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "1.0",
  },
  listSpan: {
    fontSize: "14px",
    fontWeight: 600,
    whiteSpace: "pre-line",
    lineHeight: "1.0",
  },
  bulletPoint: {
    display: "inline-block",
    marginRight: "10px",
    color: "#747474",
    verticalAlign: "top",
  },
  listSpanLineThrough: {
    textDecoration: "line-through",
    fontSize: "14px",
    fontWeight: 600,
    whiteSpace: "pre-line",
  },
  loadingContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    margin: "12px 0",
  },
  loadingContainerEdit: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    margin: "8px 0px 0px 0",
  },
  lunaIconContainer: {
    display: "inline-block",
    borderRadius: "50px",
    backgroundColor: "#17457A",
  },
  moreIcon: {
    color: "#747474",
  },
  historyButtonContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "32px",
    marginTop: "16px",
  },
  historyContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  historyDetailContainer: {
    height: "100%",
    // overflowY: "auto",
  },
  historyBottomContainer: {
    marginTop: "24px",
  },
  progressAnalysisContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "16px 0px 24px 0px",
    borderRadius: "4px",
    position: "relative",
    marginTop: "48px",
  },
  progressContainer: {
    overflowY: "auto",
  },
  promptContainer: {
    borderRadius: "4px",
    backgroundColor: "#EDF5F8",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    padding: "12px",
  },
  sendIcon: {
    color: "#4F98BC",
  },
  sendButton: {
    "&:hover": {
      backgroundColor: "#EDF5F8",
    },
    padding: "4px",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      animation: "$spin 2s linear infinite",
    },
  },
  textAreaCard: {
    borderRadius: "4px",
    width: "100%",
    padding: "12px !important",
    marginTop: "10px !important",
    marginBottom: "10px !important",
    border: "1px solid #D9D9D9 !important",
    backgroundColor: "white",
  },
  inputArea: {
    fontSize: "14px",
  },
  inputAreaLineThrough: {
    fontSize: "14px",
    textDecoration: "line-through",
  },
  newAnalysisContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    position: "relative",
  },
  newAnalysisContainerResponse: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // gap: "16px",
    position: "relative",
  },
  newAnalysisDetailContainer: {
    // height: "77%",
    // overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  newAnalysisGalleryContainer: {
    height: "90%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  zeroStatePhotosCircle: {
    borderRadius: "50%",
    border: "2px solid #000",
    width: 60,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    borderColor: "#78C1CE",
    backgroundColor: "#EDF5F8",
  },
  tagListContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "8px",
  },
  statusBadgeContainer: {
    borderRadius: "4",
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "2px 8px 2px 8px",
  },
  statusResumeContainer: {
    borderRadius: "4px",
    backgroundColor: "white",
    padding: "10px 16px 14px 16px",
  },
  statusResumeDetailContainer: {
    borderRadius: "4px",
    backgroundColor: "#F8F8F8",
    margin: "12px 0px",
    padding: "12px 12px 0px 12px",
  },
  statusResumeDetailSummaryContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "5px",
    },
  },
  statusResumeDetailResumeContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "5px",
    },
  },
  statusResumeFooterContainer: {
    display: "flex",
    flexDirection: "row",
  },
  statusResumeHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "5px",
    },
  },
  statusResumeListContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "14px 28px 34px 28px",
    backgroundColor: "#F8F8F8",
  },
  statusResumeHeaderOptionsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
  },
  zeroStateContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "450px",
    backgroundColor: "#F8F8F8",
    marginBottom: "24px",
  },
  zeroStateComments: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "350px",
    backgroundColor: "#FFFFFF",
    marginBottom: "24px",
  },
  zeroBodyContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0, 28),
    margin: "24px 0px 32px 0px",
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(0, 10),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 10),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 5),
    },
  },
  zeroBodyContainerComments: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0, 5),
    margin: "24px 0px 32px 0px",
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 5),
    },
  },
  zeroStateTitle: {
    marginTop: "16px",
    marginBottom: "8px",
    fontSize: 14,
    fontWeight: "600",
    wordWrap: "break-word",
  },
  dotContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dot: {
    width: "16px",
    height: "8px",
    borderRadius: "4px",
    transition: "transform 300ms",
    marginRight: "4px",
    zIndex: 0,
  },
  loadingStateContainer: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  visuallyHiddenInput: {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  },
  progressBarContainer: {
    width: "100%",
  },
  progressBarBackground: {
    width: "100%",
    height: "10px",
    backgroundColor: "#EDF5F8",
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",
  },
  progressErrorBackground: {
    width: "100%",
    height: "10px",
    backgroundColor: "#FFDFDF",
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",
  },
  progressBarFill: {
    height: "100%",
    background:
      "linear-gradient(to right, #17447F, #1C639E, #259BD6, #38A5DB, #6AC1EA, #74C7ED)",
    borderRadius: "10px",
    transition: "width 1s ease-in-out",
  },
  progressBarError: {
    height: "100%",
    background: "#9A1010",
    borderRadius: "10px",
    transition: "width 1s ease-in-out",
  },
  infoContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
  },
  infoContainerView: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  totalFilesLabel: {
    fontSize: 14,
    fontWeight: 400,
  },
  timeLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
  errorLabel: {
    fontSize: 14,
    color: "#9A1010",
  },
}));

export default useStyles;
