import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CommonDialog from "components/common/CommonDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import FormInput from "components/ui/FormContent/formInput";
import MessageDialog from "./dialog/messageDialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import ConfirmActionDialog from "./dialog/confirmActionDialog";
import { useListDispatch, useListState } from "contexts/listContext";
import ProjectBillingHistory from "./projectBillingHistory";

import {
  getFinancialAPDetail,
  getFinancialARDetail,
  postFinancialAP,
  postFinancialAR,
} from "services/financialService";
import moment from "moment";

import useStyles from "./styles";

const ProjectTabBillingDetail = (props) => {
  const { open, handleConfirm, handleCancel, item, type } = props;
  const dispatchList = useListDispatch();
  const classes = useStyles();
  const [openMessage, setOpenMessage] = useState(false);
  const [loading, setLoading] = useState({});
  const [error, setError] = useState("");
  const { dummyRefresh } = useListState();
  const [maxAmount, setMaxAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [notes, setNotes] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const loadDetail = async () => {
      setLoading(true);
      if (type === 0) {
        const result = await getFinancialAPDetail(item.id);
        setAmount(parseFloat(item.total_amount));
        setMaxAmount(parseFloat(item.total_amount));
        const hRecords = result.data.data.record.partial_payments;
        setHistory(hRecords);
      } else {
        const result = await getFinancialARDetail(item.id);
        setAmount(parseFloat(item.total_amount));
        setMaxAmount(parseFloat(item.total_amount));
        const hRecords = result.data.data.record.partial_bills;
        setHistory(hRecords);
      }
      setLoading(false);
    };
    loadDetail();
  }, [item]);

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const handleAction = async () => {
    if (type === 0) {
      setConfirmMessage(
        `Are you sure you want to make a payment of $ ${amount.toFixed(2)}`
      );
    } else {
      setConfirmMessage(
        `Are you sure you want to make a bill of $ ${amount.toFixed(2)}`
      );
    }
    setOpenConfirm(true);
  };

  const handleBlur = (event, type) => {
    if (parseFloat(event.value) > maxAmount) {
      setError("The value is higher than the total Amount");
    } else {
      setError("");
      setAmount(parseFloat(event.value));
    }
  };

  const handleBlurNotes = (event, type) => {
    setNotes(event.value);
  };

  const closeConfirm = () => {
    setOpenConfirm(false);
  };

  const makeAction = () => {
    const doActionAP = async () => {
      await postFinancialAP(item.id, { payment_amount: amount, notes: notes });
      handleCancel();
      setOpenMessage(true);
      dispatchList({
        type: "SET_LOADING",
        listLoading: true,
      });
      dispatchList({
        type: "SET_DUMMY_REFRESH",
        dummyRefresh: !dummyRefresh,
      });
    };
    const doActionAR = async () => {
      await postFinancialAR(item.id, { billing_amount: amount, notes: notes });
      handleCancel();
      setOpenMessage(true);
      dispatchList({
        type: "SET_LOADING",
        listLoading: true,
      });
      dispatchList({
        type: "SET_DUMMY_REFRESH",
        dummyRefresh: !dummyRefresh,
      });
    };
    if (type === 0) {
      doActionAP();
    } else {
      doActionAR();
    }
  };

  return (
    <>
      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={"Action has been done successfully."}
      />
      <ConfirmActionDialog
        open={openConfirm}
        handleConfirm={makeAction}
        handleClose={closeConfirm}
        message={confirmMessage}
      />

      <CommonDialog
        open={open}
        cancelMessage="Cancel"
        confirmMessage="Ok"
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        maxWidth="md"
        variant={true}
        noButtons={true}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} className={classes.itemDialog}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              className={classes.field}
              name="customer_name"
              label="Customer Name"
              value={item.customer_name}
              internal={true}
              readonly={true}
              alignText="left"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} className={classes.itemDialog}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              className={classes.field}
              name="job"
              label="Job"
              value={item.job_name}
              internal={true}
              readonly={true}
              alignText="left"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} className={classes.itemDialog}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              className={classes.field}
              name="job_number"
              label="Job Number"
              value={item.job_number}
              internal={true}
              readonly={true}
              alignText="left"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} className={classes.itemDialog}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              className={classes.field}
              name="summary"
              label="Summary"
              value={
                type === 0
                  ? item.workticket
                    ? item.workticket.description
                    : ""
                  : item.quote
                  ? item.quote.subject
                  : item.quote_rel
                  ? item.quote_rel.subject
                  : ""
              }
              internal={true}
              readonly={true}
              alignText="left"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} className={classes.itemDialog}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              className={classes.field}
              name="quote_number"
              label="Quote Number"
              value={item.quote ? item.quote.number : item.quote_rel.number}
              internal={true}
              readonly={true}
              alignText="left"
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} className={classes.itemDialog}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              className={classes.field}
              name="status"
              label="Status"
              value={item.status_name}
              internal={true}
              readonly={true}
              alignText="left"
            />
          </Grid>
          {type === 0 ? (
            <>
              <Grid item xs={4} sm={4} md={4} className={classes.itemDialog}>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  className={classes.field}
                  name="completed"
                  label="Completed"
                  value={
                    type === 0
                      ? item.workticket_completed_date
                        ? moment(item.workticket_completed_date).format(
                            "MM/DD/YYYY"
                          )
                        : ""
                      : moment(item.created_at).format("MM/DD/YYYY")
                  }
                  internal={true}
                  readonly={true}
                  alignText="left"
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} className={classes.itemDialog}>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  className={classes.field}
                  name="status"
                  label="Employee Name"
                  value={item.user_name}
                  internal={true}
                  readonly={true}
                  alignText="left"
                />
              </Grid>
            </>
          ) : (
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              className={classes.itemDialog}
            ></Grid>
          )}
          <Grid
            item
            xs={[1, 4].includes(item.status) ? 12 : 6}
            sm={[1, 4].includes(item.status) ? 12 : 6}
            md={[1, 4].includes(item.status) ? 12 : 6}
            className={classes.itemDialog}
          >
            {loading ? (
              <LoadingIndicator />
            ) : (
              <ProjectBillingHistory
                historyList={history}
                text={type === 0 ? "Partial Payment Made" : "Partial Bill Made"}
              />
            )}
          </Grid>
          {[1, 4].includes(item.status) ? (
            ""
          ) : (
            <Grid item xs={6} sm={6} md={6} className={classes.itemDialog}>
              {loading ? (
                <LoadingIndicator />
              ) : (
                <>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    className={classes.field}
                    name="billable"
                    label="Billable"
                    value={amount.toFixed(2)}
                    internal={false}
                    alignText="right"
                    readonly={maxAmount <= 0 ? true : false}
                    handleBlur={handleBlur}
                    error={error ? [{ key: "billable", message: error }] : []}
                    startAdornment={
                      <InputAdornment position="start">{"$"}</InputAdornment>
                    }
                  />
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    className={classes.field}
                    name="notes"
                    label="Notes"
                    multiline={true}
                    rows={3}
                    value={notes}
                    internal={false}
                    readonly={maxAmount <= 0 ? true : false}
                    handleBlur={handleBlurNotes}
                  />
                </>
              )}
              <Button
                onClick={handleAction}
                className={classes.buttonConfirm}
                variant="outlined"
                disabled={error || maxAmount <= 0 ? true : false}
                size="large"
                fullWidth
                color="primary"
                style={{ textTransform: "capitalize" }}
              >
                {type === 0 ? "Make Payment" : "Request Payment"}
              </Button>
            </Grid>
          )}
        </Grid>
      </CommonDialog>
    </>
  );
};

export default ProjectTabBillingDetail;
