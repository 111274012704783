import React from "react";
import Box from "@material-ui/core/Box";
import AccountPayablesSubsWorkTable from "./accountPayablesSubWorkTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { BillingProvider } from "contexts/billingApSubContext";

import useStyles from "./styles";

const AccountPayablesSubWorkList = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.pageBodyWrapper}>
        <BillingProvider>
          <AccountPayablesSubsWorkTable />
        </BillingProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(AccountPayablesSubWorkList);
