import React from "react";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {
  checkBadge,
  clipboardDocumentCheck,
  documentPlus,
  exclamationTriangle,
  flag,
  shieldExclamation,
} from "components/common/LunaLens/images/images";
import useStyles from "./styles";

const iconsMap = {
  checkBadge,
  clipboardDocumentCheck,
  documentPlus,
  exclamationTriangle,
  flag,
  shieldExclamation,
};

const CategorySelectionTag = ({
  category,
  removeCategoryInput,
  categoryLength,
}) => {
  const classes = useStyles();
  const renderIcon = iconsMap[category?.icon]
    ? iconsMap[category?.icon](20, 20, category?.icon_color)
    : null;

  return (
    <Box
      className={classes.categorySelectionContainer}
      style={{
        backgroundColor: category?.background_color,
        border: `1px solid ${category?.icon_color}`,
      }}
    >
      {renderIcon}
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 400,
        }}
      >
        {category?.label}
      </Typography>
      {categoryLength > 1 && (
        <IconButton
          size="small"
          style={{
            padding: "2px",
            marginLeft: "4px",
          }}
          onClick={() => removeCategoryInput(category)}
        >
          <CloseIcon style={{ fontSize: "18" }} />
        </IconButton>
      )}
    </Box>
  );
};

export default CategorySelectionTag;
