import React, { useState, useEffect, useCallback } from "react";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import BillingStatusChip from "components/ui/Billing/BillingStatusChip";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import {
  getFinancialAPOperations,
  getFinancialAROperations,
} from "services/projectService";
import { useProjectView } from "contexts/projectViewContext";
import ProjectTabBillingDetail from "./projectTabBillingDetail";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import { useListState, useListDispatch } from "contexts/listContext";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const ProjectTabBillingTable = (props) => {
  const classes = useStyles();
  const [stateContextProject] = useProjectView();
  const { project } = stateContextProject ? stateContextProject : null;
  const [tablePointer, setTablePointer] = useState(50);
  const [loadingMore, setLoadingMore] = useState(false);
  const [item, setItem] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [openDetail, setOpenDetail] = useState(false);
  const [listAP, setListAP] = useState([]);
  const [listAR, setListAR] = useState([]);
  const { listLoading, list, count, dummyRefresh } = useListState();
  const { hasRowsClickable, rows } = useTableState();
  const {
    searchString,
    filters,
    startDate,
    startDateActive,
    endDate,
    endDateActive,
  } = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchList = useListDispatch();
  const dispatchFilters = useFilterDispatch();

  const viewItem = (item) => {
    setItem(item);
    setOpenDetail(true);
  };

  const columnsAP = [
    {
      id: "id",
      label: "ID",
      format: "action",
      action: viewItem,
      checkbox: {
        active: (row) => false,
      },
    },
    {
      id: "workticket.number",
      label: "Workticket",
    },
    {
      id: "workticket.subject",
      label: "WT Summary",
    },
    { id: "user_name", label: "Subcontractor" },
    {
      id: "status",
      label: "Status",
      content: (row) => <BillingStatusChip status={row.status} archived={0} />,
      disableSort: true,
    },
    { id: "workticket_start_date", label: "Due Date", format: "date" },
    {
      id: "net_total",
      label: "Total Amount",
      format: "money",
    },
    {
      id: "discount",
      label: "Discount",
      format: "percent",
    },
    {
      id: "net_paid",
      label: "Payment",
      format: "money",
    },
  ];

  const columnsAR = [
    {
      id: "id",
      label: "ID",
      format: "action",
      action: viewItem,
      checkbox: {
        active: (row) => false,
      },
    },
    {
      id: "quote_rel.number",
      label: "Item",
      content: (row) =>
        row.quote && row.quote.number ? row.quote.number : row.quote_rel.number,
    },
    {
      id: "status",
      label: "Status",
      content: (row) => <BillingStatusChip status={row.status} archived={0} />,
      disableSort: true,
    },
    { id: "billing_due_date", label: "Due Date", format: "date" },
    {
      id: "net_total",
      label: "Total Amount",
      format: "money",
    },
    {
      id: "discount",
      label: "Discount",
      format: "percent",
    },
    {
      id: "net_billed",
      label: "Billed",
      format: "money",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const filterData = {
        filters: filters,
        dateFilters: {
          startDate,
          startDateActive,
          endDate,
          endDateActive,
        },
      };

      const resultAP = await getFinancialAPOperations(project.id, filterData);
      const resultAR = await getFinancialAROperations(project.id, filterData);
      setListAP(resultAP.data.data.ap_records);
      setListAR(resultAR.data.data.ar_records);
      dispatchList({
        type: "SET_LIST",
        list:
          activeTab === 0
            ? resultAP.data.data.ap_records
            : resultAR.data.data.ar_records,
      });
      dispatchList({
        type: "SET_COUNT",
        count:
          activeTab === 0
            ? resultAP.data.data.ap_records.length
            : resultAR.data.data.ar_records.length,
      });
      return dispatchList({
        type: "SET_LOADING",
        listLoading: false,
      });
    };
    fetchData();
  }, [
    dispatchList,
    filters,
    startDate,
    startDateActive,
    endDate,
    endDateActive,
    dummyRefresh,
  ]);

  useEffect(() => {
    if (!listLoading) {
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: list.slice(0, tablePointer),
      });
      dispatchTable({
        type: "UPDATE_COLUMNS",
        columns: activeTab === 0 ? columnsAP : columnsAR,
      });
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);
    }
  }, [dispatchTable, list, listLoading, tablePointer]);

  const handleScrollClick = useCallback(async () => {
    if (searchString) {
      // If search activated not scroll
      return;
    }
    if (list.length <= tablePointer) {
      return;
    }
    try {
      setLoadingMore(true);
      const updateTablePointer = tablePointer + 50;
      setTablePointer(updateTablePointer);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [tablePointer, list.length, searchString]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        if (activeTab === 0) {
          const resultSearch = list.filter(
            (elementItem) =>
              String(elementItem.id).includes(query) ||
              elementItem.workticket.subject.includes(query) ||
              elementItem.workticket.number.includes(query)
          );
          dispatchTable({
            type: "UPDATE_ROWS",
            rows: resultSearch,
          });
        } else {
          const resultSearch = list.filter(
            (elementItem) =>
              String(elementItem.id).includes(query) ||
              elementItem.quote_item.item_subject.includes(query)
          );
          dispatchTable({
            type: "UPDATE_ROWS",
            rows: resultSearch,
          });
        }
        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: query,
        });
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchTable({
      type: "UPDATE_ROWS",
      rows: list.slice(0, tablePointer),
    });
  };

  const handleTabChange = (event, newTab) => {
    console.log(newTab);
    setActiveTab(newTab);
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchList({
      type: "SET_LIST",
      list: newTab === 0 ? listAP : listAR,
    });
    dispatchList({
      type: "SET_COUNT",
      count: newTab === 0 ? listAP.length : listAR.length,
    });
  };
  const closeConfirm = () => {
    setOpenDetail(false);
  };

  return (
    <Box className={classes.rootTable}>
      {listLoading ? (
        <Box className={classes.centerLoadingSideBar}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <>
          <Box className={classes.contentContainer}>
            {item && (
              <ProjectTabBillingDetail
                open={openDetail}
                handleConfirm={closeConfirm}
                handleCancel={closeConfirm}
                item={item}
                type={activeTab}
              />
            )}
            <Box className={classes.contentCounter}>
              {!searchString ? (
                <Chip
                  label={`${
                    count > tablePointer ? tablePointer : count
                  } / ${count}`}
                  color="secondary"
                  variant="outlined"
                />
              ) : (
                <Chip
                  label={`${rows.length}`}
                  color="secondary"
                  variant="outlined"
                />
              )}
            </Box>
            <TableTabs handleTabChange={handleTabChange} value={activeTab}>
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Account Payable</Box>}
                key={0}
                value={0}
              />
              {project.is_billable && (
                <StyledTab
                  disableRipple={true}
                  label={
                    <Box className={classes.tabItem}>Account Receivable</Box>
                  }
                  key={1}
                  value={1}
                />
              )}
            </TableTabs>
            <Box className={classes.rootContainer}>
              <Box className={classes.contentContainer}>
                <Box className={classes.fullHeightTableProject}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={activeTab === 0 ? columnsAP : columnsAR}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      <ToolbarDefault
                        handleSearch={handleSearch}
                        handleSearchClear={handleSearchClear}
                      >
                        <CalendarFilters />
                      </ToolbarDefault>
                    </EnhancedTableToolbar>
                    {filters && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProjectTabBillingTable;
