import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import { useWorkticketModal } from "./context/WorkticketModalContext";
export function VariousTextComponent({ id, subcontractor, isAR }) {
  const [workticketModalAction, dispatchWorkticketModal] = useWorkticketModal();
  const { isWorkticketModalOpen } = workticketModalAction;

  function toggleWorkticketModal(
    event,
    open,
    id,
    subcontractor = false,
    isAR = false
  ) {
    event.preventDefault();
    event.stopPropagation();

    dispatchWorkticketModal({
      type: "TOGGLE_WORKTICKET_MODAL",
      open: open,
      activeRowId: id,
      subcontractor: subcontractor,
      isAR: isAR,
    });
  }
  const classes = useStyles();
  return (
    <>
      <Typography
        onClick={(event) => {
          toggleWorkticketModal(event, true, id, subcontractor, isAR);
        }}
        className={classes.variousText}
      >
        Various
      </Typography>
    </>
  );
}
