import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ThreeDots from "../partnerPage/icons/ThreeDots";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import { DeleteBin } from "../partnerPage/icons/DeleteBin";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { useAddCommentModal } from "./context/AddCommentDialogueContext";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useMutation, useQueryClient } from "react-query";
import {
  addWorkticketCommentW,
  updateWorkticketCommentW,
} from "../../services/workticketService";
import LoadingIndicator from "../../components/common/LoadingData/loadingData";

export const CommentItemComponent = ({
  comment,
  index,
  setIsEditing,
  classes,
  workticketId,
  tab,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [loadingState, setLoadingState] = useState(false);
  const queryClient = useQueryClient();
  const editComment = useMutation(updateWorkticketCommentW, {
    onSuccess: () => {
      queryClient.invalidateQueries("workTicketComments");
      setLoadingState(false);
      // setOpenMessage(true);
      dispatchCommentAction({
        type: "TOGGLE_EDIT_COMMENT_DIALOG",
        open: false,
        commentId: null,
        commentText: "",
      });
      dispatchCommentAction({ type: "TOGGLE_SUCCESS_EDIT", open: true });
      // setOpenMessageContent("Comment added successfully");
    },
    onError: () => {
      // setLoadingState(false);
    },
  });
  const [commentAction, dispatchCommentAction] = useAddCommentModal();
  const { isActionLoading, isEditingComment, commentId, commentText } =
    commentAction;
  const [data, setData] = useState({
    comment: isEditingComment ? commentText : "",
  });
  const menuItems = [
    {
      label: "Edit Comment",
      icon: <EditIcon fontSize="inherit" />,
      onClick: () => {
        dispatchCommentAction({
          type: "TOGGLE_EDIT_COMMENT_DIALOG",
          open: true,
          commentId: comment.id,
          commentText: comment.body,
        });
        handleMenuClose();
      },
      disabled: isActionLoading || loadingState,
    },
    {
      label: "Delete Comment",
      icon: <DeleteBin fontSize="inherit" />,
      onClick: () => {
        // handleDeleteUserDialogueOpen();
        dispatchCommentAction({
          type: "TRIGGER_DELETE_COMMENT",
          open: true,
          commentIdDelete: comment.id,
        });
        handleMenuClose();
      },
      // disabled: isSubmitting,
      disabled: isActionLoading,
    },
  ];
  const handleEditComment = () => {
    setLoadingState(true);
    editComment.mutate({
      id: workticketId,
      id_comment: comment.id,
      data: {
        body: data.comment,
        tab,
      },
    });
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {isEditingComment && commentId === comment.id && !loadingState && (
        <Box key={index} className={classes.commentContainer}>
          <Box className={classes.profilePicture}></Box>
          <Box className={classes.nameMenuActionsContainer}>
            <Typography className={classes.editCommentTitleText}>
              {comment.user_name}
            </Typography>
          </Box>
          <TextField
            className={classes.commentEditField}
            classes={{ root: classes.questionTitleEdit }}
            autoFocus={true}
            InputProps={{ disableUnderline: true }}
            // defaultValue={question.question.question}
            onChange={(e) => setData({ ...data, comment: e.target.value })}
            defaultValue={commentText}
            multiline
            minRows={4}
          />

          <Box className={classes.editCommentButtonsContainer}>
            <Button
              className={classes.addDiscountCancelButton}
              // onClick={() => handleDispatchAddContact(false)}
              onClick={() => {
                dispatchCommentAction({
                  type: "TOGGLE_EDIT_COMMENT_DIALOG",
                  open: false,
                  commentId: null,
                  commentText: "",
                });
              }}
              // disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              className={classes.secondAddDiscountButton}
              disabled={!data.comment.trim()}
              onClick={handleEditComment}
              // onClick={handleSubmit}
            >
              {/*{isEditing ? "Edit" : "Add"} Contact*/}
              Update Comment
            </Button>
          </Box>
        </Box>
      )}
      {isEditingComment && commentId === comment.id && loadingState && (
        <LoadingIndicator />
      )}
      {commentId !== comment.id && (
        <Box key={index} className={classes.commentContainer}>
          <Box className={classes.profilePicture}></Box>
          <Box className={classes.nameMenuActionsContainer}>
            <Typography className={classes.userTitleText}>
              {comment.user_name}
            </Typography>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleMenuClick(event);
              }}
              onFocus={(event) => event.stopPropagation()}
              aria-label="options"
            >
              <ThreeDots></ThreeDots>
            </IconButton>
          </Box>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {menuItems.map(({ label, icon, onClick, disabled }) => (
              <MenuItem key={label} onClick={onClick} disabled={disabled}>
                <Box className={classes.menuIcon}>{icon}</Box>
                <Typography variant="caption" className={classes.menuText}>
                  {label}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
          <Typography className={classes.bodyCommentText}>
            {comment.body}
          </Typography>
        </Box>
      )}
    </>
  );
};
