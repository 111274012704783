import PageHeader from "../../components/common/PageHeader/pageHeader";
import React from "react";
import AccountReceivableList from "./accountReceivableList";
import { WorkticketModalProvider } from "../reportAccountPayableWorkticketsInternal/context/WorkticketModalContext";

export function AccountReceivableWrapper() {
  return (
    <>
      <PageHeader
        pageTitle="Account Receivable"
        link="/financial/report/account-receivable "
      />
      <WorkticketModalProvider>
        <AccountReceivableList />
      </WorkticketModalProvider>
    </>
  );
}
